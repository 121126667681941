/*
 * Renders the ControllingBenchmarking view
 */
import { ChevronDownIcon, ChevronUpIcon, QuestionIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import BenchmarkingInputCard from '../components/BenchmarkingInputCard';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import Disable from '../components/Disable';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import Button from '../components/inputs/Button';
import Checkboxes from '../components/inputs/Checkboxes';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import MultipleChoiceOption from '../components/inputs/MultipleChoiceOption';
import SliderPropery from '../components/inputs/SliderProperty';
import {
  editBenchmarkingControlling,
  fetchBenchmarkingControlling,
  selectBenchmarkingControllingData,
  selectBenchmarkingControllingError,
  selectBenchmarkingControllingStatus,
} from '../slices/benchmarkingControllingSlice';
import { fetchCompany, selectCompanyStatus } from '../slices/companySlice';
import {
  deactivateWarning,
  selectWarningBenchmarkingControlling,
} from '../slices/warningBenchmarkingControllingSlice';
import i18n from '../translations/i18n';

/**
 * Displays cards for process and single processes with input fields for different controlling KPIs
 * @returns Cards with input fields for different controlling KPIs of the process and single processes
 */
const ControllingBenchmarking = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const companyStatus = useSelector(selectCompanyStatus);

  const benchmarkingControlling = useSelector(selectBenchmarkingControllingData);
  const benchmarkingStatus = useSelector(selectBenchmarkingControllingStatus);
  const benchmarkingError = useSelector(selectBenchmarkingControllingError);
  const warningBenchmarkingControlling = useSelector(selectWarningBenchmarkingControlling);

  const toast = useToast();

  /**
   * Converts the outsourcing types to backend compatible values
   * @param string operativePlanningType The type of planning outsourcing selected
   * @returns The backend compatible value for the selected type of type operative planning
   */
  const convertTypeOperativePlanningTypeToValue = (operativePlanningType) => {
    switch (operativePlanningType) {
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.mediumTermPlanning',
      ): {
        return 'MEDIUM_TERM_PLANNING';
      }
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanning',
      ): {
        return 'ANNUAL_PLANNING';
      }
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningSeasonalization',
      ): {
        return 'ANNUAL_PLANNING_SEASONALIZATION';
      }
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningYtef',
      ): {
        return 'ANNUAL_PLANNING_YTEF';
      }
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningRollingForecasts',
      ): {
        return 'ANNUAL_PLANNING_ROLLING_FORECASTS';
      }
      case t(
        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.rollingPlaning',
      ): {
        return 'ROLLING_PLANNING';
      }
      default: {
        return operativePlanningType;
      }
    }
  };

  /**
   * Converts the planning alignment types to backend compatible values
   * @param string outsourcingType The type of planning alignment selected
   * @returns The backend compatible value for the selected type of planning alignment
   */
  const convertPlanningAlignmentTypeToValue = (alignmentType) => {
    switch (alignmentType) {
      case t('controlling.benchmarking.operativePlanning.planningAlignment.options.bottomUp'): {
        return 'BOTTOM_UP';
      }
      case t('controlling.benchmarking.operativePlanning.planningAlignment.options.topDown'): {
        return 'TOP_DOWN';
      }
      default: {
        return null;
      }
    }
  };

  /**
   * Converts the value from the backend to a german value to be displayed
   * @param string value The value from the backend
   * @returns The type operative planning type in german
   */
  const convertValueToTypeOperativePlanningType = (value) => {
    switch (value) {
      case 'MEDIUM_TERM_PLANNING': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.mediumTermPlanning',
        );
      }
      case 'ANNUAL_PLANNING': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanning',
        );
      }
      case 'ANNUAL_PLANNING_SEASONALIZATION': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningSeasonalization',
        );
      }
      case 'ANNUAL_PLANNING_YTEF': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningYtef',
        );
      }
      case 'ANNUAL_PLANNING_ROLLING_FORECASTS': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningRollingForecasts',
        );
      }
      case 'ROLLING_PLANNING': {
        return t(
          'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.rollingPlaning',
        );
      }
      default: {
        return value;
      }
    }
  };

  /**
   * Converts the value from the backend to a german value to be displayed
   * @param string value The value from the backend
   * @returns The planning alignment type in german
   */
  const convertValueToPlanningAlignmentType = (value) => {
    switch (value) {
      case 'TOP_DOWN': {
        return t('controlling.benchmarking.operativePlanning.planningAlignment.options.topDown');
      }
      case 'BOTOM_UP': {
        return t('controlling.benchmarking.operativePlanning.planningAlignment.options.bottomUp');
      }
      case 'MIXED': {
        return t('controlling.benchmarking.operativePlanning.planningAlignment.options.mixed');
      }
      default: {
        return value;
      }
    }
  };

  // deviation projects options
  const statusProjectsOptions = [
    { name: t('controlling.benchmarking.statusProjectOptions.bad'), value: 'BAD' },
    {
      name: t('controlling.benchmarking.statusProjectOptions.standard'),
      value: 'STANDARD',
    },
    { name: t('controlling.benchmarking.statusProjectOptions.good'), value: 'GOOD' },
  ];

  // type forecast options
  const typeForecastOptions = [
    {
      name: t('controlling.benchmarking.forecast.typeForecast.options.yearEndForecast'),
      value: 'YEAR_END',
    },
    { name: t('controlling.benchmarking.forecast.typeForecast.options.rolling'), value: 'ROLLING' },
    {
      name: t('controlling.benchmarking.forecast.typeForecast.options.partRolling'),
      value: 'PART_ROLLING',
    },
  ];

  const typeRiskManagementOptions = [
    {
      name: t('controlling.benchmarking.riskManagement.typeRiskManagement.options.formalized'),
      value: 'FORMALIZED',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.typeRiskManagement.options.institutionalized',
      ),
      value: 'INSTITUTIONALIZED',
    },
  ];
  const responsibilityRiskManagementOptions = [
    {
      name: t(
        'controlling.benchmarking.riskManagement.responsibilityRiskManagement.options.controlling',
      ),
      value: 'CONTROLLING',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.responsibilityRiskManagement.options.staffUnit',
      ),
      value: 'STAFF_UNIT',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.responsibilityRiskManagement.options.corporateManagement',
      ),
      value: 'CORPORATE_MANAGEMENT',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.responsibilityRiskManagement.options.intern',
      ),
      value: 'INTERN',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.responsibilityRiskManagement.options.decentralizedEmployees',
      ),
      value: 'DECENTRALIZED_EMPLOYESS',
    },
  ];
  const frequenceRiskManagementOptions = [
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.adhoc'),
      value: 'AD_HOC',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.daily'),
      value: 'DAILY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.weekly'),
      value: 'WEEKLY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.monthly'),
      value: 'MONTHLY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.quarterly'),
      value: 'QUARTERLY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.halfYearly'),
      value: 'HALF_YEARLY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.yearly'),
      value: 'YEARLY',
    },
    {
      name: t('controlling.benchmarking.riskManagement.frequenceRiskManagement.options.never'),
      value: 'NEVER',
    },
  ];
  const controllingRelevanceOptions = [
    {
      name: t(
        'controlling.benchmarking.riskManagement.controllingRelevance.options.indepControlInstance',
      ),
      value: 'INDEP_CONTROL_INSTANCE',
    },
    {
      name: t('controlling.benchmarking.riskManagement.controllingRelevance.options.indepMediator'),
      value: 'INDEP_MEDIATOR',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.controllingRelevance.options.realBusinessPartner',
      ),
      value: 'REAL_BUSINESS_PARTNER',
    },
  ];
  const measuresOptions = [
    {
      name: t('controlling.benchmarking.riskManagement.measures.options.rotational'),
      value: 'ROTATIONAL',
    },
    {
      name: t(
        'controlling.benchmarking.riskManagement.measures.options.regularCentralRiskManagementFunc',
      ),
      value: 'REGULAR_CENTRAL_RISK_MANAGEMENT_FUNC',
    },
    {
      name: t('controlling.benchmarking.riskManagement.measures.options.regulateRevisionFunc'),
      value: 'REGULAR_REVISION_FUNC',
    },
    {
      name: t('controlling.benchmarking.riskManagement.measures.options.noActiveMonitoring'),
      value: 'NO_ACTIVE_MONITORING',
    },
  ];

  // it structure it options
  const itStructureItOptions = [
    {
      name: t('controlling.benchmarking.itControlling.structureIt.options.costCenter'),
      value: 'COST_CENTER',
    },
    {
      name: t('controlling.benchmarking.itControlling.structureIt.options.profitCenter'),
      value: 'PROFIT_CENTER',
    },
    {
      name: t('controlling.benchmarking.itControlling.structureIt.options.serviceCenter'),
      value: 'SERVICE_CENTER',
    },
    {
      name: t('controlling.benchmarking.itControlling.structureIt.options.others'),
      value: 'OTHERS',
    },
  ];

  // leadership claim options
  const leadershipClaimOptions = [
    {
      name: t('controlling.benchmarking.ctrlCorporate.leadershipClaim.options.financialLeadership'),
      value: 'FINANCIAL_LEADERSHIP',
    },
    {
      name: t('controlling.benchmarking.ctrlCorporate.leadershipClaim.options.strategicLeadership'),
      value: 'STRATEGIC_LEADERSHIP',
    },
    {
      name: t(
        'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.operationalLeadership',
      ),
      value: 'OPERATIONAL_LEADERSHIP',
    },
    {
      name: t(
        'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.controllingRelevanceKeyFigure',
      ),
      value: 'CONTROLLING_RELEVANCE_KEY_FIGURE',
    },
    {
      name: t(
        'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.keyFigureLogicAllHierarchyLevels',
      ),
      value: 'KEY_FIGURE_LOGIC_ALL_HIERARCHY_LEVELS',
    },
    {
      name: t(
        'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.keyFigureCompanyLevel',
      ),
      value: 'KEY_FIGURE_COMPANY_LEVEL',
    },
  ];

  const reportingDimensionsOptions = [
    {
      name: t('controlling.benchmarking.mgmtReporting.reportingDimensions.options.finance'),
      value: 'FINANCE',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.reportingDimensions.options.financeNot'),
      value: 'FINANCE_NOT',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.reportingDimensions.options.extern'),
      value: 'EXTERN',
    },
  ];

  const integrationAiMlOptions = [
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationAiMl.options.full'),
      value: 'FULL',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationAiMl.options.selective'),
      value: 'SELECTIVE',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationAiMl.options.exclusive'),
      value: 'EXCLUSIVE',
    },
  ];

  const integrationCommentOptions = [
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationComment.options.manualComplete'),
      value: 'MANUAL_COMPLETE',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationComment.options.manual'),
      value: 'MANUAL',
    },
    {
      name: t(
        'controlling.benchmarking.mgmtReporting.integrationComment.options.semiAutomatedFrontend',
      ),
      value: 'SEMI_AUTOMATED_FRONTEND',
    },
    {
      name: t(
        'controlling.benchmarking.mgmtReporting.integrationComment.options.semiAutomatedBackend',
      ),
      value: 'SEMI_AUTOMATED_BACKEND',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationComment.options.automatedAi'),
      value: 'AUTOMATED_AI',
    },
  ];

  const integrationSustainabilityOptions = [
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationSustainability.options.notExist'),
      value: 'NOT_EXIST',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationSustainability.options.separate'),
      value: 'SEPARATE',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationSustainability.options.oneSided'),
      value: 'ONE_SIDED',
    },
    {
      name: t('controlling.benchmarking.mgmtReporting.integrationSustainability.options.full'),
      value: 'FULL',
    },
  ];

  const reportingProcessOptions = [
    {
      name: t('controlling.benchmarking.mgmtReporting.reportingProcess.options.manual'),
      value: 'MANUAL',
    },
    {
      name: t(
        'controlling.benchmarking.mgmtReporting.reportingProcess.options.automatedDifReports',
      ),
      value: 'AUTOMATED_DIF_REPORTS',
    },
    {
      name: t(
        'controlling.benchmarking.mgmtReporting.reportingProcess.options.automatedIntegratedDatamodell',
      ),
      value: 'AUTOMATED_INTEGRATED_DATAMODEL',
    },
  ];

  /**
   * State of the corresponding input fields
   */
  const [processFTE, setProcessFTE] = React.useState(benchmarkingControlling.sumFteControlling);
  const [processCosts, setProcessCosts] = React.useState(
    benchmarkingControlling.sumCostControlling,
  );
  const [operativePlanningFTE, setOperativePlanningFTE] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.fte
      : '',
  );
  const [operativePlanningCosts, setOperativePlanningCosts] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.cost
      : '',
  );
  const [operativePlanningWeeks, setOperativePlanningWeeks] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.durationWeeks
      : '',
  );
  const [operativePlanningPlanningPreparation, setOperativePlanningPlanningPreparation] =
    React.useState(
      benchmarkingControlling.ctrlOperativePlanning
        ? benchmarkingControlling.ctrlOperativePlanning.planningPreparation
        : '',
    );
  const [operativePlanningTargetSetting, setOperativePlanningTargetSetting] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.targetSetting
      : '',
  );
  const [operativePlanningDecentralizedPlanning, setOperativePlanningDecentralizedPlanning] =
    React.useState(
      benchmarkingControlling.ctrlOperativePlanning
        ? benchmarkingControlling.ctrlOperativePlanning.decentralizedPlanning
        : '',
    );
  const [
    operativePlanningConsolidationCompletionOfPlanning,
    setOperativePlanningConsolidationCompletionOfPlanning,
  ] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.consolidationCompletionOfPlanning
      : '',
  );
  const [operativePlanningPlanShiftApproval, setOperativePlanningPlanShiftApproval] =
    React.useState(
      benchmarkingControlling.ctrlOperativePlanning
        ? benchmarkingControlling.ctrlOperativePlanning.planShiftApproval
        : '',
    );
  const [operativePlanningVotingRounds, setOperativePlanningVotingRounds] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.votingRounds
      : '',
  );
  const [operativePlanningPlanningAlignment, setOperativePlanningPlanningAlignment] =
    React.useState(
      benchmarkingControlling.ctrlOperativePlanning
        ? convertValueToPlanningAlignmentType(
            benchmarkingControlling.ctrlOperativePlanning.planningAlignment,
          )
        : '',
    );
  const [operativePlanningHorizonMonths, setOperativePlanningHorizonMonths] = React.useState(
    benchmarkingControlling.ctrlOperativePlanning
      ? benchmarkingControlling.ctrlOperativePlanning.planningHorizonMonths
      : '',
  );
  const [operativePlanningTypeOperativePlanning, setOperativePlanningTypeOperativePlanning] =
    React.useState(
      benchmarkingControlling.ctrlOperativePlanning
        ? convertValueToTypeOperativePlanningType(
            benchmarkingControlling.ctrlOperativePlanning.typeOperativePlanning,
          )
        : '',
    );
  const [strategicPlanningFTE, setStrategicPlanningFTE] = React.useState(
    benchmarkingControlling.ctrlStrategicPlanning
      ? benchmarkingControlling.ctrlStrategicPlanning.fte
      : '',
  );
  const [strategicPlanningCosts, setStrategicPlanningCosts] = React.useState(
    benchmarkingControlling.ctrlStrategicPlanning
      ? benchmarkingControlling.ctrlStrategicPlanning.cost
      : '',
  );
  const [strategicPlanningPlanningHorizonMonths, setStrategicPlanningPlanningHorizonMonths] =
    React.useState(
      benchmarkingControlling.ctrlStrategicPlanning
        ? benchmarkingControlling.ctrlStrategicPlanning.planningHorizonMonths
        : '',
    );
  const [strategicPlanningDurationWeeks, setStrategicPlanningDurationWeeks] = React.useState(
    benchmarkingControlling.ctrlStrategicPlanning
      ? benchmarkingControlling.ctrlStrategicPlanning.durationWeeks
      : '',
  );
  const [strategicPlanningVotingRounds, setStrategicPlanningVotingRounds] = React.useState(
    benchmarkingControlling.ctrlStrategicPlanning
      ? benchmarkingControlling.ctrlStrategicPlanning.votingRounds
      : '',
  );
  const [strategicPlanningNumberParticipants, setStrategicPlanningNumberParticipants] =
    React.useState(
      benchmarkingControlling.ctrlStrategicPlanning
        ? benchmarkingControlling.ctrlStrategicPlanning.numberParticipants
        : '',
    );
  const [forecastFTE, setForecastFTE] = React.useState(
    benchmarkingControlling.ctrlForecast ? benchmarkingControlling.ctrlForecast.fte : '',
  );
  const [forecastCosts, setForecastCosts] = React.useState(
    benchmarkingControlling.ctrlForecast ? benchmarkingControlling.ctrlForecast.cost : '',
  );
  const [forecastTypeForecast, setForecastTypeForecast] = React.useState(
    benchmarkingControlling.ctrlForecast ? benchmarkingControlling.ctrlForecast.typeForecast : '',
  );
  const [forecastLevelDetailWeek, setForecastLevelDetailWeek] = React.useState(
    benchmarkingControlling.ctrlForecast
      ? benchmarkingControlling.ctrlForecast.levelDetailWeek
      : '',
  );
  const [forecastLevelDetailMonth, setForecastLevelDetailMonth] = React.useState(
    benchmarkingControlling.ctrlForecast
      ? benchmarkingControlling.ctrlForecast.levelDetailMonth
      : '',
  );
  const [forecastLevelDetailQuarter, setForecastLevelDetailQuarter] = React.useState(
    benchmarkingControlling.ctrlForecast
      ? benchmarkingControlling.ctrlForecast.levelDetailQuarter
      : '',
  );
  const [forecastLevelDetailOthers, setForecastLevelDetailOthers] = React.useState(
    benchmarkingControlling.ctrlForecast
      ? benchmarkingControlling.ctrlForecast.levelDetailOthers
      : '',
  );
  const [forecastAccuracy, setForecastAccuracy] = React.useState(
    benchmarkingControlling.ctrlForecast ? benchmarkingControlling.ctrlForecast.accuracy : '',
  );
  const [forecastForecastHorizonMonths, setForecastForecastHorizonMonths] = React.useState(
    benchmarkingControlling.ctrlForecast
      ? benchmarkingControlling.ctrlForecast.forecastHorizonMonths
      : '',
  );
  const [cpraFTE, setCpraFTE] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.fte : '',
  );
  const [cpraCosts, setCpraCosts] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.cost : '',
  );
  const [cpraVarianceCtrAcc, setCpraVarianceCtrAcc] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.varianceCtrAcc : '',
  );
  const [cpraForecastQuality, setCpraForecastQuality] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.forecastQuality : '',
  );
  const [cpraPlanQuality, setCpraPlanQuality] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.planQuality : '',
  );
  const [cpraDeviation, setCpraDeviation] = React.useState(
    benchmarkingControlling.ctrlCpra ? benchmarkingControlling.ctrlCpra.deviation : '',
  );

  const [mgmtReportingFTE, setMgmtReportingFTE] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.fte
      : '',
  );
  const [mgmtReportingCosts, setMgmtReportingCosts] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.cost
      : '',
  );
  const [mgmtReportingAmountReportsExecutiveBoard, setMgmtReportingAmountReportsExecutiveBoard] =
    React.useState(
      benchmarkingControlling.ctrlManagementReporting
        ? benchmarkingControlling.ctrlManagementReporting.amountReportsExecutiveBoard
        : '',
    );
  const [
    mgmtReportingAmountReportsSupervisoryBoard,
    setMgmtReportingAmountReportsSupervisoryBoard,
  ] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.amountReportsSupervisoryBoard
      : '',
  );
  const [mgmtReportingAdHocReports, setMgmtReportingAdHocReports] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.adHocReports
      : '',
  );
  const [mgmtReportingStandardizedReports, setMgmtReportingStandardizedReports] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.standardizedReports
      : '',
  );
  const [mgmtReportingKpisAmount, setMgmtReportingKpisAmount] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.kpisAmount
      : '',
  );
  const [
    mgmtReportingAutomationPotentialDataManagement,
    setMgmtReportingAutomationPotentialDataManagement,
  ] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.automationPotentialDataManagement
      : '',
  );
  const [
    mgmtReportingAutomationPotentialReportGeneration,
    setMgmtReportingAutomationPotentialReportGeneration,
  ] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.automationPotentialReportGeneration
      : '',
  );
  const [mgmtReportingAutomationPotentialAnalysis, setMgmtReportingAutomationPotentialAnalysis] =
    React.useState(
      benchmarkingControlling.ctrlManagementReporting
        ? benchmarkingControlling.ctrlManagementReporting.automationPotentialAnalysis
        : '',
    );
  const [
    mgmtReportingAutomationPotentialDecisionSupport,
    setMgmtReportingAutomationPotentialDecisionSupport,
  ] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.automationPotentialDecisionSupport
      : '',
  );
  const [mgmtReportingFormOfUsePush, setMgmtReportingFormOfUsePush] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.formOfUsePush
      : '',
  );
  const [mgmtReportingFormOfUseStandard, setMgmtReportingFormOfUseStandard] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.formOfUseStandard
      : '',
  );
  const [mgmtReportingFormOfUseSelfService, setMgmtReportingFormOfUseSelfService] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.formOfUseSelfService
      : '',
  );
  const [mgmtReportingReportShares, setMgmtReportingReportShares] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.reportShares
      : '',
  );
  const [mgmtReportingDegreeDigitization, setMgmtReportingDegreeDigitization] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.degreeDigitization
      : '',
  );
  const [mgmtReportingReportingDimensions, setMgmtReportingReportingDimensions] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.reportingDimensions
      : '',
  );
  const [mgmtReportingIntegrationAiMl, setMgmtReportingIntegrationAiMl] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.integrationAiMl
      : '',
  );
  const [mgmtReportingIntegrationComment, setMgmtReportingIntegrationComment] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.integrationComment
      : '',
  );
  const [mgmtReportingIntegrationSustainability, setMgmtReportingIntegrationSustainability] =
    React.useState(
      benchmarkingControlling.ctrlManagementReporting
        ? benchmarkingControlling.ctrlManagementReporting.integrationSustainability
        : '',
    );
  const [mgmtReportingReportingProcess, setMgmtReportingReportingProcess] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.reportingProcess
      : '',
  );
  const [mgmtReportingReportingClosing, setMgmtReportingReportingClosing] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.reportingClosing
      : '',
  );
  const [mgmtReportingReportingFlash, setMgmtReportingReportingFlash] = React.useState(
    benchmarkingControlling.ctrlManagementReporting
      ? benchmarkingControlling.ctrlManagementReporting.reportingFlash
      : '',
  );
  const [mgmtReportingReportingMonthlyUncommented, setMgmtReportingReportingMonthlyUncommented] =
    React.useState(
      benchmarkingControlling.ctrlManagementReporting
        ? benchmarkingControlling.ctrlManagementReporting.reportingMonthlyUncommented
        : '',
    );
  const [mgmtReportingReportingMonthlyCommented, setMgmtReportingReportingMonthlyCommented] =
    React.useState(
      benchmarkingControlling.ctrlManagementReporting
        ? benchmarkingControlling.ctrlManagementReporting.reportingMonthlyCommented
        : '',
    );
  const [picFTE, setPicFTE] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.fte : '',
  );
  const [picCosts, setPicCosts] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.cost : '',
  );
  const [picInvestmentLimit, setPicInvestmentLimit] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.investmentLimit : '',
  );
  const [picDurationRequest, setPicDurationRequest] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.durationRequest : '',
  );
  const [picStatusProjectsSuccessful, setPicStatusProjectsSuccessful] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.statusProjectsSuccessful : '',
  );
  const [picStatusProjectsAborted, setPicStatusProjectsAborted] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.statusProjectAborted : '',
  );
  const [picStatusProjectsStopped, setPicStatusProjectsStopped] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.statusProjectStopped : '',
  );
  const [picDeviationProjectCosts, setPicDeviationProjectCosts] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.deviationProjectCosts : null,
  );
  const [picDeviationProjectTime, setPicDeviationProjectTime] = React.useState(
    benchmarkingControlling.ctrlPic ? benchmarkingControlling.ctrlPic.deviationProjectTime : null,
  );
  const [picDeviationProjectPerformance, setPicDeviationProjectPerformance] = React.useState(
    benchmarkingControlling.ctrlPic
      ? benchmarkingControlling.ctrlPic.deviationProjectPerformance
      : null,
  );

  const [riskManagementFTE, setRiskManagementFTE] = React.useState(
    benchmarkingControlling.ctrlRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.fte
      : '',
  );
  const [riskManagementCosts, setRiskManagementCosts] = React.useState(
    benchmarkingControlling.ctrlRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.cost
      : '',
  );
  const [riskManagementTypeRiskManagement, setRiskManagementTypeRiskManagement] = React.useState(
    benchmarkingControlling.ctrlRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.typeRiskManagement
      : '',
  );
  const [
    riskManagementResponsibilityRiskManagement,
    setRiskManagementResponsibilityRiskManagement,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.responsibilityRiskManagement
      : '',
  );
  const [riskManagementFrequenceRiskManagement, setRiskManagementFrequenceRiskManagement] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.frequenceRiskManagement
        : '',
    );
  const [riskManagementControllingRelevance, setRiskManagementControllingRelevance] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.controllingRelevance
        : '',
    );
  const [riskManagementMeasures, setRiskManagementMeasures] = React.useState(
    benchmarkingControlling.ctrlRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.measures
      : '',
  );
  const [riskManagementKpiGoalFulfilledRequirement, setRiskManagementKpiGoalFulfilledRequirement] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.fulfilledRequirement
        : '',
    );
  const [
    riskManagementKpiGoalImprovedControllingRisks,
    setRiskManagementKpiGoalImprovedControllingRisks,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.companyControlling
      : '',
  );
  const [riskManagementKpiGoalCompanyControlling, setRiskManagementKpiGoalCompanyControlling] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.companyControlling
        : '',
    );
  const [
    riskManagementKpiIntegrationStrategicPlanning,
    setRiskManagementKpiIntegrationStrategicPlanning,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.strategicPlanning
      : '',
  );
  const [
    riskManagementKpiIntegrationOperativePlanning,
    setRiskManagementKpiIntegrationOperativePlanning,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.operativePlanning
      : '',
  );
  const [riskManagementKpiIntegrationBudgeting, setRiskManagementKpiIntegrationBudgeting] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.budgeting
        : '',
    );
  const [
    riskManagementKpiIntegrationStrategicDecisionSupport,
    setRiskManagementKpiIntegrationStrategicDecisionSupport,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
          .strategicDecisionSupport
      : '',
  );
  const [
    riskManagementKpiIntegrationOperativeDecisionSupport,
    setRiskManagementKpiIntegrationOperativeDecisionSupport,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
      ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
          .operativeDecisionSupport
      : '',
  );
  const [riskManagementKpiIntegrationNoInterfaces, setRiskManagementKpiIntegrationNoInterfaces] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
        ? benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.noInterfaces
        : '',
    );
  const [riskManagementKpiAnalysisNoConcept, setRiskManagementKpiAnalysisNoConcept] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
        ? benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.noConcept
        : '',
    );
  const [riskManagementKpiAnalysisEquityCoverage, setRiskManagementKpiAnalysisEquityCoverage] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
        ? benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.equityCoverage
        : '',
    );
  const [riskManagementKpiAnalysisLiquidityReserve, setRiskManagementKpiAnalysisLiquidityReserve] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
        ? benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.liquidityReserve
        : '',
    );
  const [riskManagementKpiAnalysisOther, setRiskManagementKpiAnalysisOther] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
      ? benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.other
      : '',
  );
  const [
    riskManagementKpiSoftwareRiskRecordingRating,
    setRiskManagementKpiSoftwareRiskRecordingRating,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
      ? benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.riskRecordingRating
      : '',
  );
  const [riskManagementKpiSoftwareReporting, setRiskManagementKpiSoftwareReporting] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
        ? benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.reporting
        : '',
    );
  const [riskManagementKpiSoftwareRiskDashboards, setRiskManagementKpiSoftwareRiskDashboards] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
        ? benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.riskDashboards
        : '',
    );
  const [
    riskManagementKpiSoftwareSimulationModeling,
    setRiskManagementKpiSoftwareSimulationModeling,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
      ? benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.simulationModeling
      : '',
  );
  const [
    riskManagementKpiSoftwareNoSoftwareSupport,
    setRiskManagementKpiSoftwareNoSoftwareSupport,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
      ? benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.noSoftwareSupport
      : '',
  );
  const [riskManagementKpiScenarioCurrentRiskDev, setRiskManagementKpiScenarioCurrentRiskDev] =
    React.useState(
      benchmarkingControlling.ctrlRiskManagement &&
        benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
        ? benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.currentRiskDev
        : '',
    );
  const [
    riskManagementKpiScenarioIndividualSingleRisks,
    setRiskManagementKpiScenarioIndividualSingleRisks,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
      ? benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.individualSingleRisks
      : '',
  );
  const [riskManagementKpiScenarioTopRisks, setRiskManagementKpiScenarioTopRisks] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
      ? benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.topRisks
      : '',
  );
  const [
    riskManagementKpiScenarioCompleteRiskPortfolio,
    setRiskManagementKpiScenarioCompleteRiskPortfolio,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
      ? benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.completeRiskPortfolio
      : '',
  );
  const [
    riskManagementKpiScenarioStrategicDecisionOptions,
    setRiskManagementKpiScenarioStrategicDecisionOptions,
  ] = React.useState(
    benchmarkingControlling.ctrlRiskManagement &&
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
      ? benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.strategicDecisionOptions
      : '',
  );
  const [functionalFTE, setFunctionalFTE] = React.useState(
    benchmarkingControlling.ctrlFunctional ? benchmarkingControlling.ctrlFunctional.fte : '',
  );
  const [functionalCosts, setFunctionalCosts] = React.useState(
    benchmarkingControlling.ctrlFunctional ? benchmarkingControlling.ctrlFunctional.cost : '',
  );
  const [participationFTE, setParticipationFTE] = React.useState(
    benchmarkingControlling.ctrlParticipation ? benchmarkingControlling.ctrlParticipation.fte : '',
  );
  const [participationCosts, setParticipationCosts] = React.useState(
    benchmarkingControlling.ctrlParticipation ? benchmarkingControlling.ctrlParticipation.cost : '',
  );
  const [researchDevelopmentFTE, setResearchDevelopmentFTE] = React.useState(
    benchmarkingControlling.ctrlResearchDevelopment
      ? benchmarkingControlling.ctrlResearchDevelopment.fte
      : '',
  );
  const [researchDevelopmentCosts, setResearchDevelopmentCosts] = React.useState(
    benchmarkingControlling.ctrlResearchDevelopment
      ? benchmarkingControlling.ctrlResearchDevelopment.cost
      : '',
  );
  const [researchDevelopmentPercentageShare, setResearchDevelopmentPercentageShare] =
    React.useState(
      benchmarkingControlling.ctrlResearchDevelopment
        ? benchmarkingControlling.ctrlResearchDevelopment.percentageShare
        : '',
    );
  const [researchDevelopmentResourcesEffort, setResearchDevelopmentResourcesEffort] =
    React.useState(
      benchmarkingControlling.ctrlResearchDevelopment
        ? benchmarkingControlling.ctrlResearchDevelopment.resourcesEffort
        : '',
    );
  const [
    researchDevelopmentCompositionPortfolioBasicResearch,
    setResearchDevelopmentCompositionPortfolioBasicResearch,
  ] = React.useState(
    benchmarkingControlling.ctrlResearchDevelopment
      ? benchmarkingControlling.ctrlResearchDevelopment.compositionPortfolioBasicResearch
      : '',
  );
  const [
    researchDevelopmentCompositionPortfolioNewProductDev,
    setResearchDevelopmentCompositionPortfolioNewProductDev,
  ] = React.useState(
    benchmarkingControlling.ctrlResearchDevelopment
      ? benchmarkingControlling.ctrlResearchDevelopment.compositionPortfolioNewProductDev
      : '',
  );
  const [
    researchDevelopmentCompositionPortfolioFurtherDev,
    setResearchDevelopmentCompositionPortfolioFurtherDev,
  ] = React.useState(
    benchmarkingControlling.ctrlResearchDevelopment
      ? benchmarkingControlling.ctrlResearchDevelopment.compositionPortfolioFurtherDev
      : '',
  );

  const [productionFTE, setProductionFTE] = React.useState(
    benchmarkingControlling.ctrlProduction ? benchmarkingControlling.ctrlProduction.fte : '',
  );
  const [productionCosts, setProductionCosts] = React.useState(
    benchmarkingControlling.ctrlProduction ? benchmarkingControlling.ctrlProduction.cost : '',
  );
  const [productionKeyDataDeliveryPunctuality, setProductionKeyDataDeliveryPunctuality] =
    React.useState(
      benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
        ? benchmarkingControlling.ctrlProduction.kpiKeyData.deliveryPunctuality
        : '',
    );
  const [productionKeyDataIdleTimeMachine, setProductionKeyDataIdleTimeMachine] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.idleTimeMachine
      : '',
  );
  const [productionKeyDataPlantUtilizationRate, setProductionKeyDataPlantUtilizationRate] =
    React.useState(
      benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
        ? benchmarkingControlling.ctrlProduction.kpiKeyData.plantUtilizationRate
        : '',
    );
  const [productionKeyDataUtilizationRate, setProductionKeyDataUtilizationRate] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.utilizationRate
      : '',
  );
  const [productionKeyDataManufacturingQuality, setProductionKeyDataManufacturingQuality] =
    React.useState(
      benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
        ? benchmarkingControlling.ctrlProduction.kpiKeyData.manufacturingQuality
        : '',
    );
  const [productionKeyDataPostProcessingRate, setProductionKeyDataPostProcessingRate] =
    React.useState(
      benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
        ? benchmarkingControlling.ctrlProduction.kpiKeyData.postProcessingRate
        : '',
    );
  const [productionKeyDataScrapRate, setProductionKeyDataScrapRate] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.scrapRate
      : '',
  );
  const [productionKeyDataDamageFrequency, setProductionKeyDataDamageFrequency] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.damageFrequency
      : '',
  );
  const [productionKeyDataStockBearingManufactory, setProductionKeyDataStockBearingManufactory] =
    React.useState(
      benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
        ? benchmarkingControlling.ctrlProduction.kpiKeyData.stockBearingManufacturing
        : '',
    );
  const [productionKeyDataSetupTimes, setProductionKeyDataSetupTimes] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.setupTimes
      : '',
  );
  const [productionKeyDataEmployment, setProductionKeyDataEmployment] = React.useState(
    benchmarkingControlling.ctrlProduction && benchmarkingControlling.ctrlProduction.kpiKeyData
      ? benchmarkingControlling.ctrlProduction.kpiKeyData.employment
      : '',
  );
  const [salesFTE, setSalesFTE] = React.useState(
    benchmarkingControlling.ctrlSales ? benchmarkingControlling.ctrlSales.fte : '',
  );
  const [salesCosts, setSalesCosts] = React.useState(
    benchmarkingControlling.ctrlSales ? benchmarkingControlling.ctrlSales.cost : '',
  );
  const [salesPercentageShare, setSalesPercentageShare] = React.useState(
    benchmarkingControlling.ctrlSales ? benchmarkingControlling.ctrlSales.percentageShare : '',
  );
  const [bcmFTE, setBcmFTE] = React.useState(
    benchmarkingControlling.ctrlBcm ? benchmarkingControlling.ctrlBcm.fte : '',
  );
  const [bcmCosts, setBcmCosts] = React.useState(
    benchmarkingControlling.ctrlBcm ? benchmarkingControlling.ctrlBcm.cost : '',
  );
  const [advancementFTE, setAdvancementFTE] = React.useState(
    benchmarkingControlling.ctrlImprovement ? benchmarkingControlling.ctrlImprovement.fte : '',
  );
  const [advancementCosts, setAdvancementCosts] = React.useState(
    benchmarkingControlling.ctrlImprovement ? benchmarkingControlling.ctrlImprovement.cost : '',
  );
  const [itCosts, setItCosts] = React.useState(
    benchmarkingControlling.ctraIt ? benchmarkingControlling.ctraIt.cost : '',
  );
  const [itFTE, setItFTE] = React.useState(
    benchmarkingControlling.ctraIt ? benchmarkingControlling.ctraIt.fte : '',
  );
  const [itStructureIt, setItStructureIt] = React.useState(
    benchmarkingControlling.ctraIt ? benchmarkingControlling.ctraIt.structureIt : '',
  );
  const [ctrlCorporateCosts, setCtrlCorporateCosts] = React.useState(
    benchmarkingControlling.ctrlCorporate ? benchmarkingControlling.ctrlCorporate.cost : '',
  );
  const [ctrlCorporateFTE, setCtrlCorporateFTE] = React.useState(
    benchmarkingControlling.ctrlCorporate ? benchmarkingControlling.ctrlCorporate.fte : '',
  );
  const [ctrlCorporateLeadershipClaim, setCtrlCorporateLeadershipClaim] = React.useState(
    benchmarkingControlling.ctrlCorporate
      ? benchmarkingControlling.ctrlCorporate.leadershipClaim
      : '',
  );
  const [ctrlBusinessConsultingManagementFTE, setCtrlBusinessConsultingManagementFTE] =
    React.useState(
      benchmarkingControlling.ctrlBusinessConsultingManagement
        ? benchmarkingControlling.ctrlBusinessConsultingManagement.fte
        : '',
    );
  const [ctrlBusinessConsultingManagementCosts, setCtrlBusinessConsultingManagementCosts] =
    React.useState(
      benchmarkingControlling.ctrlBusinessConsultingManagement
        ? benchmarkingControlling.ctrlBusinessConsultingManagement.cost
        : '',
    );
  const [ctrlFurtherDevelopmentControllingFTE, setCtrlFurtherDevelopmentControllingFTE] =
    React.useState(
      benchmarkingControlling.ctrlFurtherDevelopmentControlling
        ? benchmarkingControlling.ctrlFurtherDevelopmentControlling.fte
        : '',
    );
  const [ctrlFurtherDevelopmentControllingCosts, setCtrlFurtherDevelopmentControllingCosts] =
    React.useState(
      benchmarkingControlling.ctrlFurtherDevelopmentControlling
        ? benchmarkingControlling.ctrlFurtherDevelopmentControlling.cost
        : '',
    );

  const [currentProcessGranularity, setCurrentProcessGranularity] = React.useState(
    t('controlling.benchmarking.singleProcesses.title'),
  );

  /*
   * UseEffect is needed because when the language changes the state currentProcessGranularity must be
   * "manually" translated to the new language (this is not automatically done)
   */
  React.useEffect(() => {
    switch (currentProcessGranularity) {
      case 'Einzelprozesse':
      case 'Single Processes': {
        setCurrentProcessGranularity(t('controlling.benchmarking.singleProcesses.title'));
        break;
      }
      case 'Gesamtprozess':
      case 'Total Process': {
        setCurrentProcessGranularity(t('controlling.benchmarking.totalProcess.title'));
        break;
      }
      default: {
        setCurrentProcessGranularity(t('controlling.benchmarking.singleProcesses.title'));
        break;
      }
    }
  }, [i18n.language]);

  /*
   * Error messages for invalid inputs from the user
   */
  const [errorMsgOPWeeks, setErrorMsgOPWeeks] = React.useState('');
  const [errorMsgOPPlanningPreparation, setErrorMsgOPPlanningPreparation] = React.useState('');
  const [errorMsgOPTargetSetting, setErrorMsgOPTargetSetting] = React.useState('');
  const [errorMsgOPDecentralizedPlanning, setErrorMsgOPDecentralizedPlanning] = React.useState('');
  const [
    errorMsgOPConsolidationCompletionOfPlanning,
    setErrorMsgOPConsolidationCompletionOfPlanning,
  ] = React.useState('');
  const [errorMsgOPPlanShiftApproval, setErrorMsgOPPlanShiftApproval] = React.useState('');
  const [errorMsgOPVotingRounds, setErrorMsgOPVotingRoungs] = React.useState('');
  const [errorMsgOPPlanningHorizonMonths, setErrorMsgOPPlanningHorizonMonths] = React.useState('');
  const [
    errorMsgStrategicPlanningPlanningHorizonMonths,
    setErrorMsgStrategicPlanningPlanningHorizonMonths,
  ] = React.useState('');
  const [errorMsgStrategicPlanningDurationWeeks, setErrorMsgStrategicPlanningDurationWeeks] =
    React.useState('');
  const [errorMsgStrategicPlanningVotingsRounds, setErrorMsgStrategicPlanningVotingsRounds] =
    React.useState('');
  const [
    errorMsgStrategicPlanningNumberParticipants,
    setErrorMsgStrategicPlanningNumberParticipants,
  ] = React.useState('');
  const [errorMsgPicInvestmentLimit, setErrorMsgPicInvestmentLimit] = React.useState('');
  const [errorMsgPicDurationRequest, setErrorMsgPicDurationRequest] = React.useState('');
  const [errorMsgPicStatusProjectsSuccessful, setErrorMsgPicStatusProjectsSuccessful] =
    React.useState('');
  const [errorMsgPicStatusProjectsAborted, setErrorMsgPicStatusProjectsAborted] =
    React.useState('');
  const [errorMsgPicStatusProjectsStopped, setErrorMsgPicStatusProjectsStopped] =
    React.useState('');
  // Temporarly removed due to customer request
  // const [errorMsgForecastLevelDetailWeek, setErrorMsgForecastLevelDetailWeek] = React.useState('');
  // const [errorMsgForecastLevelDetailMonth, setErrorMsgForecastLevelDetailMonth] =
  //   React.useState('');
  // const [errorMsgForecastLevelDetailQuarter, setErrorMsgForecastLevelDetailQuarter] =
  //   React.useState('');
  // const [errorMsgForecastLevelDetailOthers, setErrorMsgForecastLevelDetailOthers] =
  //   React.useState('');
  const [errorMsgForecastAccuracy, setErrorMsgForecastAccuracy] = React.useState('');
  const [errorMsgForecastForecastHorizonMonths, setErrorMsgForecastForecastHorizonMonths] =
    React.useState('');
  const [errorMsgSalesPercentageShare, setErrorMsgSalesPercentageShare] = React.useState('');
  const [errorMsgCpraVarianceCtrAcc, setErrorMsgCpraVarianceCtrAcc] = React.useState('');
  const [errorMsgCpraForecastQuality, setErrorMsgCpraForecastQuality] = React.useState('');
  const [errorMsgCpraPlanQuality, setErrorMsgCpraPlanQuality] = React.useState('');
  const [errorMsgCpraDeviation, setErrorMsgCpraDeviation] = React.useState('');
  const [
    errorMsgMgmtReportingAmountReportsExecutiveBoard,
    setErrorMsgMgmtReportingAmountReportsExecutiveBoard,
  ] = React.useState('');
  const [
    errorMsgMgmtReportingAmountReportsSupervisoryBoard,
    setErrorMsgMgmtReportingAmountReportsSupervisoryBoard,
  ] = React.useState('');
  const [errorMsgMgmtReportingAdHocReports, setErrorMsgMgmtReportingAdHocReports] =
    React.useState('');
  const [errorMsgMgmtReportingStandardizedReports, setErrorMsgMgmtReportingStandardizedReports] =
    React.useState('');
  const [errorMsgMgmtReportingKpisAmount, setErrorMsgMgmtReportingKpisAmount] = React.useState('');
  const [
    errorMsgMgmtReportingAutomationPotentialDataManagement,
    setErrorMsgMgmtReportingAutomationPotentialDataManagement,
  ] = React.useState('');
  const [
    errorMsgMgmtReportingAutomationPotentialReportGeneration,
    setErrorMsgMgmtReportingAutomationPotentialReportGeneration,
  ] = React.useState('');
  const [
    errorMsgMgmtReportingAutomationPotentialAnalysis,
    setErrorMsgMgmtReportingAutomationPotentialAnalysis,
  ] = React.useState('');
  const [
    errorMsgMgmtReportingAutomationPotentialDecisionSupport,
    setErrorMsgMgmtReportingAutomationPotentialDecisionSupport,
  ] = React.useState('');
  const [errorMsgMgmtReportingFormOfUsePush, setErrorMsgMgmtReportingFormOfUsePush] =
    React.useState('');
  const [errorMsgMgmtReportingFormOfUseStandard, setErrorMsgMgmtReportingFormOfUseStandard] =
    React.useState('');
  const [errorMsgMgmtReportingFormOfUseSelfService, setErrorMsgMgmtReportingFormOfUseSelfService] =
    React.useState('');
  const [errorMsgMgmtReportingReportShares, setErrorMsgMgmtReportingReportShares] =
    React.useState('');
  const [errorMsgMgmtReportingDegreeDigitization, setErrorMsgMgmtReportingDegreeDigitization] =
    React.useState('');
  const [errorMsgMgmtReportingReportingClosing, setErrorMsgMgmtReportingReportingClosing] =
    React.useState('');
  const [errorMsgMgmtReportingReportingFlash, setErrorMsgMgmtReportingReportingFlash] =
    React.useState('');
  const [
    errorMsgMgmtReportingReportingMonthlyUncommented,
    setErrorMsgMgmtReportingReportingMonthlyUncommented,
  ] = React.useState('');
  const [
    errorMsgMgmtReportingrReportingMonthlyCommented,
    setErrorMsgMgmtReportingReportingMonthlyCommented,
  ] = React.useState('');
  const [
    errorMsgResearchDevelopmentPercentageShare,
    setErrorMsgResearchDevelopmentPercentageShare,
  ] = React.useState('');
  const [
    errorMsgResearchDevelopmentResourcesEffort,
    setErrorMsgResearchDevelopmentResourcesEffort,
  ] = React.useState('');
  const [
    errorMsgResearchDevelopmentCompositionPortfolioBasicResearch,
    setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch,
  ] = React.useState('');
  const [
    errorMsgResearchDevelopmentCompositionPortfolioNewProductDev,
    setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev,
  ] = React.useState('');
  const [
    errorMsgResearchDevelopmentCompositionPortfolioFurtherDev,
    setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev,
  ] = React.useState('');

  // States for input validation to save user inputs of benchmarks
  const [validateOPWeeks, setValidateOPWeeks] = React.useState(true);
  const [validateOPPlanningPreparation, setValidateOPPlanningPreparation] = React.useState(true);
  const [validateOPTargetSetting, setValidateOPTargetSetting] = React.useState(true);
  const [validateOPDecentralizedPlanning, setValidateOPDecentralizedPlanning] =
    React.useState(true);
  const [
    validateOPConsolidationCompletionOfPlanning,
    setValidateOPConsolidationCompletionOfPlanning,
  ] = React.useState(true);
  const [validateOPPlanShiftApproval, setValidateOPPlanShiftApproval] = React.useState(true);
  const [validateOPVotingRounds, setValidateOPVotingRounds] = React.useState(true);
  const [validateOPPlanningHorizonMonths, setValidateOPPlanningHorizonMonths] =
    React.useState(true);
  const [
    validateStrategicPlanningPlanningHorizonMonths,
    setValidateStrategicPlanningPlanningHorizonMonths,
  ] = React.useState(true);
  const [validateStrategicPlanningDurationWeeks, setValidateStrategicPlanningDurationWeeks] =
    React.useState(true);
  const [validateStrategicPlanningVotingRounds, setValidateStrategicPlanningVotingRounds] =
    React.useState(true);
  const [
    validateStrategicPlanningNumberParticipants,
    setValidateStrategicPlanningNumberParticipants,
  ] = React.useState(true);
  const [validatePicInvestmentLimit, setValidatePicInvestmentLimit] = React.useState(true);
  const [validatePicDurationRequest, setValidatePicDurationRequest] = React.useState(true);
  const [validatePicStatusProjectsSuccessful, setValidatePicStatusProjectsSuccessful] =
    React.useState(true);
  const [validatePicStatusProjectsAborted, setValidatePicStatusProjectsAborted] =
    React.useState(true);
  const [validatePicStatusProjectsStopped, setValidatePicStatusProjectsStopped] =
    React.useState(true);
  // Temporarly removed due to customer request
  // const [validateForecastLevelDetailWeek, setValidateForecastLevelDetailWeek] =
  //   React.useState(true);
  // const [validateForecastLevelDetailMonth, setValidateForecastLevelDetailMonth] =
  //   React.useState(true);
  // const [validateForecastLevelDetailQuarter, setValidateForecastLevelDetailQuarter] =
  //   React.useState(true);
  // const [validateForecastLevelDetailOthers, setValidateForecastLevelDetailOthers] =
  //   React.useState(true);
  const [validateForecastAccuracy, setValidateForecastAccuracy] = React.useState(true);
  const [validateForecastForecastHorizonMonths, setValidateForecastForecastHorizonMonths] =
    React.useState(true);
  const [validateSalesPercentageShare, setValidateSalesPercentageShare] = React.useState(true);
  const [validateCpraVarianceCtrAcc, setValidateCpraVarianceCtrAcc] = React.useState(true);
  const [validateCpraForecastQuality, setValidateCpraForecastQuality] = React.useState(true);
  const [validateCpraPlanQuality, setValidateCpraPlanQuality] = React.useState(true);
  const [validateCpraDeviation, setValidateCpraDeviation] = React.useState(true);
  const [
    validateMgmtReportingAmountReportsExecutiveBoard,
    setValidateMgmtReportingAmountReportsExecutiveBoard,
  ] = React.useState(true);
  const [
    validateMgmtReportingAmountReportsSupervisoryBoard,
    setValidateMgmtReportingAmountReportsSupervisoryBoard,
  ] = React.useState(true);
  const [validateMgmtReportingAdHocReports, setValidateMgmtReportingAdHocReports] =
    React.useState(true);
  const [validateMgmtReportingStandardizedReports, setValidateMgmtReportingStandardizedReports] =
    React.useState(true);
  const [validateMgmtReportingKpisAmount, setValidateMgmtReportingKpisAmount] =
    React.useState(true);
  const [
    validateMgmtReportingAutomationPotentialDataManagement,
    setValidateMgmtReportingAutomationPotentialDataManagement,
  ] = React.useState(true);
  const [
    validateMgmtReportingAutomationPotentialReportGeneration,
    setValidateMgmtReportingAutomationPotentialReportGeneration,
  ] = React.useState(true);
  const [
    validateMgmtReportingAutomationPotentialAnalysis,
    setValidateMgmtReportingAutomationPotentialAnalysis,
  ] = React.useState(true);
  const [
    validateMgmtReportingAutomationPotentialDecisionSupport,
    setValidateMgmtReportingAutomationPotentialDecisionSupport,
  ] = React.useState(true);
  const [validateMgmtReportingFormOfUsePush, setValidateMgmtReportingFormOfUsePush] =
    React.useState(true);
  const [validateMgmtReportingFormOfUseStandard, setValidateMgmtReportingFormOfUseStandard] =
    React.useState(true);
  const [validateMgmtReportingFormOfUseSelfService, setValidateMgmtReportingFormOfUseSelfService] =
    React.useState(true);
  const [validateMgmtReportingReportShares, setValidateMgmtReportingReportShares] =
    React.useState(true);
  const [validateMgmtReportingDegreeDigitization, setValidateMgmtReportingDegreeDigitization] =
    React.useState(true);
  const [validateMgmtReportingReportingClosing, setValidateMgmtReportingReportingClosing] =
    React.useState(true);
  const [validateMgmtReportingReportingFlash, setValidateMgmtReportingReportingFlash] =
    React.useState(true);
  const [
    validateMgmtReportingReportingMonthlyUncommented,
    setValidateMgmtReportingReportingMonthlyUncommented,
  ] = React.useState(true);
  const [
    validateMgmtReportingReportingMonthlyCommented,
    setValidateMgmtReportingReportingMonthlyCommented,
  ] = React.useState(true);
  const [
    validateResearchDevelopmentPercentageShare,
    setValidateResearchDevelopmentPercentageShare,
  ] = React.useState(true);
  const [
    validateResearchDevelopmentResourcesEffort,
    setValidateResearchDevelopmentResourcesEffort,
  ] = React.useState(true);
  const [
    validateResearchDevelopmentCompositionPortfolioBasicResearch,
    setValidateResearchDevelopmentCompositionPortfolioBasicResearch,
  ] = React.useState(true);
  const [
    validateResearchDevelopmentCompositionPortfolioNewProductDev,
    setValidateResearchDevelopmentCompositionPortfolioNewProductDev,
  ] = React.useState(true);
  const [
    validateResearchDevelopmentCompositionPortfolioFurtherDev,
    setValidateResearchDevelopmentCompositionPortfolioFurtherDev,
  ] = React.useState(true);

  /**
   * Handles the change and validation of the kpi percentage share of the process research and
   * development which should be larger than 0 and smaller than 100
   */
  const handleRDPercentageShareValueChange = (inputValue) => {
    setResearchDevelopmentPercentageShare(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgResearchDevelopmentPercentageShare(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateResearchDevelopmentPercentageShare(false);
    } else {
      setErrorMsgResearchDevelopmentPercentageShare('');
      setValidateResearchDevelopmentPercentageShare(true);
    }
  };

  /**
   * Handles the change and validation of the kpi resource effort in the process reasearch and
   * development which should be less or equal than 1 000 000 000
   */
  const handleRDResourceEffortValueChange = (inputValue) => {
    setResearchDevelopmentResourcesEffort(inputValue);
    const max = 1000000000;
    if (inputValue > max) {
      setErrorMsgResearchDevelopmentResourcesEffort(
        t('errors.benchmarking.valueBelowError', { max }),
      );
      setValidateResearchDevelopmentResourcesEffort(false);
    } else {
      setErrorMsgResearchDevelopmentResourcesEffort('');
      setValidateResearchDevelopmentPercentageShare(true);
    }
  };

  /**
   * Handles the change and validation of the kpi composition portfolio regarding basic research in the process research
   * and development
   */
  const handleRDCompositionPortfolioBasicResearchValueChange = (inputValue) => {
    setResearchDevelopmentCompositionPortfolioBasicResearch(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue >= min && inputValue <= max) {
      if (
        parseInt(inputValue, 10) +
          parseInt(researchDevelopmentCompositionPortfolioFurtherDev, 10) +
          parseInt(researchDevelopmentCompositionPortfolioNewProductDev, 10) !==
        100
      ) {
        if (
          researchDevelopmentCompositionPortfolioFurtherDev === 0 &&
          inputValue === 0 &&
          researchDevelopmentCompositionPortfolioNewProductDev === 0
        ) {
          setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
          setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
          setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
          setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
          setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
          setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
        } else {
          setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch(
            t('errors.benchmarking.sumOfKPIsError', {
              sum: max,
            }),
          );
          setValidateResearchDevelopmentCompositionPortfolioBasicResearch(false);
        }
      } else {
        setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
        setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
        setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
        setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
        setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
        setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
      }
    } else {
      setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateResearchDevelopmentCompositionPortfolioBasicResearch(false);
    }
  };

  /**
   * Handles the change and validation of the kpi composition portfolio regarding new product development in the process research
   * and development
   */
  const handleRDCompositionPortfolioNewProductDevValueChange = (inputValue) => {
    setResearchDevelopmentCompositionPortfolioNewProductDev(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue >= min && inputValue <= max) {
      if (
        parseInt(inputValue, 10) +
          parseInt(researchDevelopmentCompositionPortfolioFurtherDev, 10) +
          parseInt(researchDevelopmentCompositionPortfolioBasicResearch, 10) !==
        100
      ) {
        if (
          researchDevelopmentCompositionPortfolioFurtherDev === '0' &&
          researchDevelopmentCompositionPortfolioBasicResearch === '0' &&
          inputValue === '0'
        ) {
          setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
          setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
          setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
          setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
          setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
          setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
        } else {
          setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev(
            t('errors.benchmarking.sumOfKPIsError', {
              sum: max,
            }),
          );
          setValidateResearchDevelopmentCompositionPortfolioNewProductDev(false);
        }
      } else {
        setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
        setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
        setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
        setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
        setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
        setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
      }
    } else {
      setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateResearchDevelopmentCompositionPortfolioNewProductDev(false);
    }
  };

  /**
   * Handles the change and validation of the kpi composition portfolio regarding further development in the process research
   * and development
   */
  const handleRDCompositionPortfolioFurtherDevValueChange = (inputValue) => {
    setResearchDevelopmentCompositionPortfolioFurtherDev(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue >= min && inputValue <= max) {
      if (
        parseInt(inputValue, 10) +
          parseInt(researchDevelopmentCompositionPortfolioNewProductDev, 10) +
          parseInt(researchDevelopmentCompositionPortfolioBasicResearch, 10) !==
        100
      ) {
        if (
          inputValue === '0' &&
          researchDevelopmentCompositionPortfolioBasicResearch === '0' &&
          researchDevelopmentCompositionPortfolioNewProductDev === '0'
        ) {
          setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
          setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
          setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
          setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
          setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
          setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
        } else {
          setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev(
            t('errors.benchmarking.sumOfKPIsError', {
              sum: max,
            }),
          );
          setValidateResearchDevelopmentCompositionPortfolioFurtherDev(false);
        }
      } else {
        setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
        setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
        setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
        setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
        setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
        setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
      }
    } else {
      setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateResearchDevelopmentCompositionPortfolioFurtherDev(false);
    }
    if (
      researchDevelopmentCompositionPortfolioFurtherDev === '0' &&
      researchDevelopmentCompositionPortfolioBasicResearch === '0' &&
      researchDevelopmentCompositionPortfolioNewProductDev === '0'
    ) {
      setErrorMsgResearchDevelopmentCompositionPortfolioBasicResearch('');
      setErrorMsgResearchDevelopmentCompositionPortfolioFurtherDev('');
      setErrorMsgResearchDevelopmentCompositionPortfolioNewProductDev('');
      setValidateResearchDevelopmentCompositionPortfolioFurtherDev(true);
      setValidateResearchDevelopmentCompositionPortfolioBasicResearch(true);
      setValidateResearchDevelopmentCompositionPortfolioNewProductDev(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning duration weeks state
   */
  const handleOPWeeksValueChange = (inputValue) => {
    setOperativePlanningWeeks(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPWeeks(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPWeeks(false);
    } else {
      setErrorMsgOPWeeks('');
      setValidateOPWeeks(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning plan ning preparation state
   */
  const handleOPPlanningPreparationValueChange = (inputValue) => {
    setOperativePlanningPlanningPreparation(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPPlanningPreparation(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPPlanningPreparation(false);
    } else {
      setErrorMsgOPPlanningPreparation('');
      setValidateOPPlanningPreparation(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning target setting state
   */
  const handleOPTargetSettingValueChange = (inputValue) => {
    setOperativePlanningTargetSetting(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPTargetSetting(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPTargetSetting(false);
    } else {
      setErrorMsgOPTargetSetting('');
      setValidateOPTargetSetting(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning decentralized planning state
   */
  const handleOPDecentralizedPlanningValueChange = (inputValue) => {
    setOperativePlanningDecentralizedPlanning(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPDecentralizedPlanning(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPDecentralizedPlanning(false);
    } else {
      setErrorMsgOPDecentralizedPlanning('');
      setValidateOPDecentralizedPlanning(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning consolidation completion of planning state
   */
  const handleOPConsolidationCompletionOfPlanningValueChange = (inputValue) => {
    setOperativePlanningConsolidationCompletionOfPlanning(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPConsolidationCompletionOfPlanning(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateOPConsolidationCompletionOfPlanning(false);
    } else {
      setErrorMsgOPConsolidationCompletionOfPlanning('');
      setValidateOPConsolidationCompletionOfPlanning(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning duration weeks state
   */
  const handleOPPlanShiftApprovalValueChange = (inputValue) => {
    setOperativePlanningPlanShiftApproval(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPPlanShiftApproval(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPPlanShiftApproval(false);
    } else {
      setErrorMsgOPPlanShiftApproval('');
      setValidateOPPlanShiftApproval(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning voting rounds state
   */
  const handleOPVotingRoundsValueChange = (inputValue) => {
    setOperativePlanningVotingRounds(inputValue);
    const min = 0;
    const max = 10;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPVotingRoungs(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPVotingRounds(false);
    } else {
      setErrorMsgOPVotingRoungs('');
      setValidateOPVotingRounds(true);
    }
  };

  /**
   * Handles the change and validation of the kpi operative planning horizon months state
   */
  const handleOPPlanningHorizonMonthsValueChange = (inputValue) => {
    setOperativePlanningHorizonMonths(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgOPPlanningHorizonMonths(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateOPPlanningHorizonMonths(false);
    } else {
      setErrorMsgOPPlanningHorizonMonths('');
      setValidateOPPlanningHorizonMonths(true);
    }
  };

  /**
   * Handles the change and validation of the kpi strategic planning planning horizon months state
   */
  const handleStrategicPlanningPlanningHorizonMonthsValueChange = (inputValue) => {
    setStrategicPlanningPlanningHorizonMonths(inputValue);
    const min = 0;
    const max = 200;
    if (inputValue > max || inputValue < min) {
      setErrorMsgStrategicPlanningPlanningHorizonMonths(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateStrategicPlanningPlanningHorizonMonths(false);
    } else {
      setErrorMsgStrategicPlanningPlanningHorizonMonths('');
      setValidateStrategicPlanningPlanningHorizonMonths(true);
    }
  };

  /**
   * Handles the change and validation of the kpi pic investment limit state
   */
  const handlePicInvestmentLimitValueChange = (inputValue) => {
    setPicInvestmentLimit(inputValue);
    const min = 0;
    const max = 1000000000;
    if (inputValue > max || inputValue < min) {
      setErrorMsgPicInvestmentLimit(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidatePicInvestmentLimit(false);
    } else {
      setErrorMsgPicInvestmentLimit('');
      setValidatePicInvestmentLimit(true);
    }
  };

  /*
  Temporarly removed due to customer request
  */

  /**
   * Handles the change and validation of the kpi forecast level detail week state
   */
  // const handleForecastLevelDetailWeekValueChange = (inputValue) => {
  //   setForecastLevelDetailWeek(inputValue);
  //   const min = 0;
  //  const max = 100;
  //  if (inputValue > max || inputValue < min) {
  //     setErrorMsgForecastLevelDetailWeek(t('errors.benchmarking.valueBetweenError', { min, max }));
  //     setValidateForecastLevelDetailWeek(false);
  //   } else {
  //     setErrorMsgForecastLevelDetailWeek('');
  //     setValidateForecastLevelDetailWeek(true);
  //   }
  // };

  /**
   * Handles the change and validation of the kpi forecast level detail months state
   */
  // const handleForecastLevelDetailMonthValueChange = (inputValue) => {
  //   setForecastLevelDetailMonth(inputValue);
  //   const min = 0;
  //  const max = 100;
  //  if (inputValue > max || inputValue < min) {
  //     setErrorMsgForecastLevelDetailMonth(t('errors.benchmarking.valueBetweenError', { min, max }));
  //     setValidateForecastLevelDetailMonth(false);
  //   } else {
  //     setErrorMsgForecastLevelDetailMonth('');
  //     setValidateForecastLevelDetailMonth(true);
  //   }
  // };

  /**
   * Handles the change and validation of the kpi cpra variance ctr acc state
   */
  const handleCpraVarianceCtrAccValueChange = (inputValue) => {
    setCpraVarianceCtrAcc(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgCpraVarianceCtrAcc(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateCpraVarianceCtrAcc(false);
    } else {
      setErrorMsgCpraVarianceCtrAcc('');
      setValidateCpraVarianceCtrAcc(true);
    }
  };

  /**
   * Handles the change and validation of the kpi strategic planning duration days state
   */
  const handleStrategicPlanningDurationDaysValueChange = (inputValue) => {
    setStrategicPlanningDurationWeeks(inputValue);
    const min = 0;
    const max = 52;
    if (inputValue > max || inputValue < min) {
      setErrorMsgStrategicPlanningDurationWeeks(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateStrategicPlanningDurationWeeks(false);
    } else {
      setErrorMsgStrategicPlanningDurationWeeks('');
      setValidateStrategicPlanningDurationWeeks(true);
    }
  };

  /**
   * Handles the change and validation of the kpi sales percentage share state
   */
  const handleSalesPercentageShareValueChange = (inputValue) => {
    setSalesPercentageShare(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgSalesPercentageShare(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateSalesPercentageShare(false);
    } else {
      setErrorMsgSalesPercentageShare('');
      setValidateSalesPercentageShare(true);
    }
  };

  /**
   * Handles the change and validation of the kpi strategic planning voting rounds state
   */
  const handleStrategicPlanningVotingRoundsValueChange = (inputValue) => {
    setStrategicPlanningVotingRounds(inputValue);
    const min = 0;
    const max = 10;
    if (inputValue > max || inputValue < min) {
      setErrorMsgStrategicPlanningVotingsRounds(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateStrategicPlanningVotingRounds(false);
    } else {
      setErrorMsgStrategicPlanningVotingsRounds('');
      setValidateStrategicPlanningVotingRounds(true);
    }
  };

  /**
   * Handles the change and validation of the kpi pic duration request state
   */
  const handlePicDurationRequestValueChange = (inputValue) => {
    setPicDurationRequest(inputValue);
    const min = 0;
    const max = 365;
    if (inputValue > max || inputValue < min) {
      setErrorMsgPicDurationRequest(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidatePicDurationRequest(false);
    } else {
      setErrorMsgPicDurationRequest('');
      setValidatePicDurationRequest(true);
    }
  };

  /**
   * Temporarly removed due to customer request
   * Handles the change and validation of the kpi forecast level detail quarter state
   */
  // const handleForecastLevelDetailQuarterValueChange = (inputValue) => {
  //   setForecastLevelDetailQuarter(inputValue);
  //   const min = 0;
  //  const max = 100;
  //  if (inputValue > max || inputValue < min) {
  //     setErrorMsgForecastLevelDetailQuarter(t('errors.benchmarking.valueBetweenError', { min, max }));
  //     setValidateForecastLevelDetailQuarter(false);
  //   } else {
  //     setErrorMsgForecastLevelDetailQuarter('');
  //     setValidateForecastLevelDetailQuarter(true);
  //   }
  // };

  /**
   * Handles the change and validation of the kpi cpra forecast quality state
   */
  const handleCpraForecastQualityValueChange = (inputValue) => {
    setCpraForecastQuality(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgCpraForecastQuality(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateCpraForecastQuality(false);
    } else {
      setErrorMsgCpraForecastQuality('');
      setValidateCpraForecastQuality(true);
    }
  };

  /**
   * Handles the change and validation of the kpi strategic planning number participants state
   */
  const handleStrategicPlanningNumberParticipantsValueChange = (inputValue) => {
    setStrategicPlanningNumberParticipants(inputValue);
    const min = 0;
    const max = 999;
    if (inputValue > max || inputValue < min) {
      setErrorMsgStrategicPlanningNumberParticipants(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateStrategicPlanningNumberParticipants(false);
    } else {
      setErrorMsgStrategicPlanningNumberParticipants('');
      setValidateStrategicPlanningNumberParticipants(true);
    }
  };

  /**
   * Handles the change and validation of the kpi pic status projects successful state
   */
  const handlePicStatusProjectsSuccessfulValueChange = (inputValue) => {
    setPicStatusProjectsSuccessful(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgPicStatusProjectsSuccessful(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidatePicStatusProjectsSuccessful(false);
    } else {
      setErrorMsgPicStatusProjectsSuccessful('');
      setValidatePicStatusProjectsSuccessful(true);
    }
  };

  /**
   * Handles the change and validation of the kpi forecast level detail others state
   * Temporarly removed due to customer request
   */
  // const handleForecastLevelDetailOthersValueChange = (inputValue) => {
  //   setForecastLevelDetailOthers(inputValue);
  //   const min = 0;
  //  const max = 100;
  //  if (inputValue > max || inputValue < min) {
  //     setErrorMsgForecastLevelDetailOthers(t('errors.benchmarking.valueBetweenError', { min, max }));
  //     setValidateForecastLevelDetailOthers(false);
  //   } else {
  //     setErrorMsgForecastLevelDetailOthers('');
  //     setValidateForecastLevelDetailOthers(true);
  //   }
  // };

  /**
   * Handles the change and validation of the kpi cpra plan quality state
   */
  const handleCpraPlanQualityValueChange = (inputValue) => {
    setCpraPlanQuality(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgCpraPlanQuality(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateCpraPlanQuality(false);
    } else {
      setErrorMsgCpraPlanQuality('');
      setValidateCpraPlanQuality(true);
    }
  };

  /**
   * Handles the change and validation of the kpi pic status projects Aborted state
   */
  const handlePicStatusProjectsAbortedValueChange = (inputValue) => {
    setPicStatusProjectsAborted(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgPicStatusProjectsAborted(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidatePicStatusProjectsAborted(false);
    } else {
      setErrorMsgPicStatusProjectsAborted('');
      setValidatePicStatusProjectsAborted(true);
    }
  };

  /**
   * Handles the change and validation of the kpi forecast accuracy state
   */
  const handleForecastAccuracyValueChange = (inputValue) => {
    setForecastAccuracy(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgForecastAccuracy(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateForecastAccuracy(false);
    } else {
      setErrorMsgForecastAccuracy('');
      setValidateForecastAccuracy(true);
    }
  };

  /**
   * Handles the change and validation of the kpi pic status projects stopped state
   */
  const handlePicStatusProjectsStoppedValueChange = (inputValue) => {
    setPicStatusProjectsStopped(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgPicStatusProjectsStopped(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidatePicStatusProjectsStopped(false);
    } else {
      setErrorMsgPicStatusProjectsStopped('');
      setValidatePicStatusProjectsStopped(true);
    }
  };

  /**
   * Handles the change and validation of the kpi forecast forecast horizon months state
   */
  const handleForecastForecastHorizonMonthsValueChange = (inputValue) => {
    setForecastForecastHorizonMonths(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgForecastForecastHorizonMonths(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateForecastForecastHorizonMonths(false);
    } else {
      setErrorMsgForecastForecastHorizonMonths('');
      setValidateForecastForecastHorizonMonths(true);
    }
  };

  /**
   * Handles the change and validation of the kpi cpra deviation state
   */
  const handleCpraDeviationValueChange = (inputValue) => {
    setCpraDeviation(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgCpraDeviation(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateCpraDeviation(false);
    } else {
      setErrorMsgCpraDeviation('');
      setValidateCpraDeviation(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting amount reports executive board
   */
  const handleMRAmountReportsExecutiveBoardValueChange = (inputValue) => {
    setMgmtReportingAmountReportsExecutiveBoard(inputValue);
    if (inputValue < 0) {
      setErrorMsgMgmtReportingAmountReportsExecutiveBoard(
        t('errors.benchmarking.notNegativeError'),
      );
      setValidateMgmtReportingAmountReportsExecutiveBoard(false);
    } else {
      setErrorMsgMgmtReportingAmountReportsExecutiveBoard('');
      setValidateMgmtReportingAmountReportsExecutiveBoard(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting amount reports supervisory board
   */
  const handleMRAmountReportsSupervisoryBoardValueChange = (inputValue) => {
    setMgmtReportingAmountReportsSupervisoryBoard(inputValue);
    if (inputValue < 0) {
      setErrorMsgMgmtReportingAmountReportsSupervisoryBoard(
        t('errors.benchmarking.notNegativeError'),
      );
      setValidateMgmtReportingAmountReportsSupervisoryBoard(false);
    } else {
      setErrorMsgMgmtReportingAmountReportsSupervisoryBoard('');
      setValidateMgmtReportingAmountReportsSupervisoryBoard(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting ad hoc reports
   */
  const handleMRAdHocReportsValueChange = (inputValue) => {
    setMgmtReportingAdHocReports(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue >= min && inputValue <= max) {
      if (parseInt(inputValue, 10) + parseInt(mgmtReportingStandardizedReports, 10) !== 100) {
        if (inputValue === 0 && mgmtReportingStandardizedReports === 0) {
          setErrorMsgMgmtReportingAdHocReports('');
          setErrorMsgMgmtReportingStandardizedReports('');
          setValidateMgmtReportingAdHocReports(true);
          setValidateMgmtReportingStandardizedReports(true);
        } else {
          setErrorMsgMgmtReportingAdHocReports(
            t('errors.benchmarking.sumOfKPIsError', {
              sum: max,
            }),
          );
          setValidateMgmtReportingAdHocReports(false);
        }
      } else {
        setErrorMsgMgmtReportingAdHocReports('');
        setErrorMsgMgmtReportingStandardizedReports('');
        setValidateMgmtReportingAdHocReports(true);
        setValidateMgmtReportingStandardizedReports(true);
      }
    } else {
      setErrorMsgMgmtReportingAdHocReports(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingAdHocReports(false);
    }
    if (inputValue === '0' && mgmtReportingStandardizedReports === '0') {
      setErrorMsgMgmtReportingAdHocReports('');
      setErrorMsgMgmtReportingStandardizedReports('');
      setValidateMgmtReportingAdHocReports(true);
      setValidateMgmtReportingStandardizedReports(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting standardized reports
   */
  const handleMRStandardizedReportsValueChange = (inputValue) => {
    setMgmtReportingStandardizedReports(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue >= min && inputValue <= max) {
      if (parseInt(inputValue, 10) + parseInt(mgmtReportingAdHocReports, 10) !== 100) {
        if (inputValue === 0 && mgmtReportingAdHocReports === 0) {
          setErrorMsgMgmtReportingAdHocReports('');
          setErrorMsgMgmtReportingStandardizedReports('');
          setValidateMgmtReportingAdHocReports(true);
          setValidateMgmtReportingStandardizedReports(true);
        } else {
          setErrorMsgMgmtReportingStandardizedReports(
            t('errors.benchmarking.sumOfKPIsError', {
              sum: max,
            }),
          );
          setValidateMgmtReportingStandardizedReports(false);
        }
      } else {
        setErrorMsgMgmtReportingAdHocReports('');
        setErrorMsgMgmtReportingStandardizedReports('');
        setValidateMgmtReportingAdHocReports(true);
        setValidateMgmtReportingStandardizedReports(true);
      }
    } else {
      setErrorMsgMgmtReportingStandardizedReports(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingStandardizedReports(false);
    }
    if (inputValue === '0' && mgmtReportingAdHocReports === '0') {
      setErrorMsgMgmtReportingAdHocReports('');
      setErrorMsgMgmtReportingStandardizedReports('');
      setValidateMgmtReportingAdHocReports(true);
      setValidateMgmtReportingStandardizedReports(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting kpis amount
   */
  const handleMRKpisAmountValueChange = (inputValue) => {
    setMgmtReportingKpisAmount(inputValue);
    const min = 0;
    const max = 1000;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingKpisAmount(t('errors.benchmarking.valueBetweenError', { min, max }));
      setValidateMgmtReportingKpisAmount(false);
    } else {
      setErrorMsgMgmtReportingKpisAmount('');
      setValidateMgmtReportingKpisAmount(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting automation potential data management
   */
  const handleMRAutomationPotentialDataManagementValueChange = (inputValue) => {
    setMgmtReportingAutomationPotentialDataManagement(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingAutomationPotentialDataManagement(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingAutomationPotentialDataManagement(false);
    } else {
      setErrorMsgMgmtReportingAutomationPotentialDataManagement('');
      setValidateMgmtReportingAutomationPotentialDataManagement(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting automation potential report genereration
   */
  const handleMRAutomationPotentialReportGenerationValueChange = (inputValue) => {
    setMgmtReportingAutomationPotentialReportGeneration(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingAutomationPotentialReportGeneration(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingAutomationPotentialReportGeneration(false);
    } else {
      setErrorMsgMgmtReportingAutomationPotentialReportGeneration('');
      setValidateMgmtReportingAutomationPotentialReportGeneration(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting automation potential analysis
   */
  const handleMRAutomationPotentialAnalysisValueChange = (inputValue) => {
    setMgmtReportingAutomationPotentialAnalysis(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingAutomationPotentialAnalysis(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingAutomationPotentialAnalysis(false);
    } else {
      setErrorMsgMgmtReportingAutomationPotentialAnalysis('');
      setValidateMgmtReportingAutomationPotentialAnalysis(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting automation decision support
   */
  const handleMRAutomationPotentialDecisionSupportValueChange = (inputValue) => {
    setMgmtReportingAutomationPotentialDecisionSupport(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingAutomationPotentialDecisionSupport(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingAutomationPotentialDecisionSupport(false);
    } else {
      setErrorMsgMgmtReportingAutomationPotentialDecisionSupport('');
      setValidateMgmtReportingAutomationPotentialDecisionSupport(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting form of use push
   */
  const handleMRFormOfUsePushValueChange = (inputValue) => {
    setMgmtReportingFormOfUsePush(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingFormOfUsePush(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingFormOfUsePush(false);
    } else {
      setErrorMsgMgmtReportingFormOfUsePush('');
      setValidateMgmtReportingFormOfUsePush(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting form of use standard
   */
  const handleMRFormOfUseStandardValueChange = (inputValue) => {
    setMgmtReportingFormOfUseStandard(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingFormOfUseStandard(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingFormOfUseStandard(false);
    } else {
      setErrorMsgMgmtReportingFormOfUseStandard('');
      setValidateMgmtReportingFormOfUseStandard(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting form of use self service
   */
  const handleMRFormOfUseSelfServiceValueChange = (inputValue) => {
    setMgmtReportingFormOfUseSelfService(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingFormOfUseSelfService(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingFormOfUseSelfService(false);
    } else {
      setErrorMsgMgmtReportingFormOfUseSelfService('');
      setValidateMgmtReportingFormOfUseSelfService(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting report share
   */
  const handleMRReportSharesValueChange = (inputValue) => {
    setMgmtReportingReportShares(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingReportShares(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingReportShares(false);
    } else {
      setErrorMsgMgmtReportingReportShares('');
      setValidateMgmtReportingReportShares(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting degree digitization
   */
  const handleMRDegreeDigitizationValueChange = (inputValue) => {
    setMgmtReportingDegreeDigitization(inputValue);
    const min = 0;
    const max = 100;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingDegreeDigitization(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingDegreeDigitization(false);
    } else {
      setErrorMsgMgmtReportingDegreeDigitization('');
      setValidateMgmtReportingDegreeDigitization(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting reporting closing
   */
  const handleMRReportingClosingValueChange = (inputValue) => {
    setMgmtReportingReportingClosing(inputValue);
    const min = 0;
    const max = 365;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingReportingClosing(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingReportingClosing(false);
    } else {
      setErrorMsgMgmtReportingReportingClosing('');
      setValidateMgmtReportingReportingClosing(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting reporting flash
   */
  const handleMRReportingFlashValueChange = (inputValue) => {
    setMgmtReportingReportingFlash(inputValue);
    const min = 0;
    const max = 365;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingReportingFlash(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingReportingFlash(false);
    } else {
      setErrorMsgMgmtReportingReportingFlash('');
      setValidateMgmtReportingReportingFlash(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting reporting monthly uncommented
   */
  const handleMRReportingMonthlyUncommentedValueChange = (inputValue) => {
    setMgmtReportingReportingMonthlyUncommented(inputValue);
    const min = 0;
    const max = 365;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingReportingMonthlyUncommented(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingReportingMonthlyUncommented(false);
    } else {
      setErrorMsgMgmtReportingReportingMonthlyUncommented('');
      setValidateMgmtReportingReportingMonthlyUncommented(true);
    }
  };

  /**
   * Handles the change and validation of the kpi management reporting reporting monthly commented
   */
  const handleMRReportingMonthlyCommentedValueChange = (inputValue) => {
    setMgmtReportingReportingMonthlyCommented(inputValue);
    const min = 0;
    const max = 365;
    if (inputValue > max || inputValue < min) {
      setErrorMsgMgmtReportingReportingMonthlyCommented(
        t('errors.benchmarking.valueBetweenError', { min, max }),
      );
      setValidateMgmtReportingReportingMonthlyCommented(false);
    } else {
      setErrorMsgMgmtReportingReportingMonthlyCommented('');
      setValidateMgmtReportingReportingMonthlyCommented(true);
    }
  };

  /**
   * Retrieves the width of the window
   * @returns The window width
   */
  const getWindowWidth = () => {
    const { innerWidth: width } = window;
    return width;
  };
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth());

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [totalProcessFteActive, setTotalProcessFteActive] = React.useState(true);
  const [totalProcessCostActive, setTotalProcessCostActive] = React.useState(true);

  // States for opening the alert dialog
  const { isOpen: isAlertShown, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  /**
   * Sets all single process cost states to zero
   */
  const clearProcessInputs = () => {
    setOperativePlanningCosts(null);
    setOperativePlanningFTE(null);
    setOperativePlanningHorizonMonths(null);
    setOperativePlanningWeeks(null);
    setOperativePlanningPlanningPreparation(null);
    setOperativePlanningTargetSetting(null);
    setOperativePlanningDecentralizedPlanning(null);
    setOperativePlanningConsolidationCompletionOfPlanning(null);
    setOperativePlanningPlanShiftApproval(null);
    setOperativePlanningPlanningAlignment(null);
    setOperativePlanningTypeOperativePlanning(null);
    setOperativePlanningVotingRounds(null);
    setStrategicPlanningCosts(null);
    setStrategicPlanningFTE(null);
    setStrategicPlanningPlanningHorizonMonths(null);
    setStrategicPlanningDurationWeeks(null);
    setStrategicPlanningVotingRounds(null);
    setStrategicPlanningNumberParticipants(null);
    setForecastCosts(null);
    setForecastFTE(null);
    setForecastTypeForecast(null);
    setForecastLevelDetailWeek(null);
    setForecastLevelDetailMonth(null);
    setForecastLevelDetailQuarter(null);
    setForecastLevelDetailOthers(null);
    setForecastAccuracy(null);
    setForecastForecastHorizonMonths(null);
    setCpraVarianceCtrAcc(null);
    setCpraForecastQuality(null);
    setCpraPlanQuality(null);
    setCpraDeviation(null);
    setCpraCosts(null);
    setMgmtReportingCosts(null);
    setMgmtReportingFTE(null);
    setMgmtReportingAdHocReports(null);
    setMgmtReportingAmountReportsExecutiveBoard(null);
    setMgmtReportingAmountReportsSupervisoryBoard(null);
    setMgmtReportingAutomationPotentialAnalysis(null);
    setMgmtReportingAutomationPotentialDataManagement(null);
    setMgmtReportingAutomationPotentialDecisionSupport(null);
    setMgmtReportingAutomationPotentialReportGeneration(null);
    setMgmtReportingDegreeDigitization(null);
    setMgmtReportingFormOfUsePush(null);
    setMgmtReportingFormOfUseSelfService(null);
    setMgmtReportingFormOfUseStandard(null);
    setMgmtReportingIntegrationAiMl(null);
    setMgmtReportingIntegrationComment(null);
    setMgmtReportingIntegrationSustainability(null);
    setMgmtReportingKpisAmount(null);
    setMgmtReportingReportShares(null);
    setMgmtReportingReportingDimensions(null);
    setMgmtReportingReportingProcess(null);
    setMgmtReportingReportingClosing(null);
    setMgmtReportingReportingFlash(null);
    setMgmtReportingReportingMonthlyUncommented(null);
    setMgmtReportingReportingMonthlyCommented(null);
    setMgmtReportingStandardizedReports(null);
    setPicCosts(null);
    setPicFTE(null);
    setPicInvestmentLimit(null);
    setPicDurationRequest(null);
    setPicStatusProjectsSuccessful(null);
    setPicStatusProjectsAborted(null);
    setPicStatusProjectsStopped(null);
    setPicDeviationProjectCosts(null);
    setPicDeviationProjectTime(null);
    setPicDeviationProjectPerformance(null);
    setRiskManagementCosts(null);
    setRiskManagementFTE(null);
    setRiskManagementControllingRelevance(null);
    setRiskManagementFrequenceRiskManagement(null);
    setRiskManagementKpiAnalysisEquityCoverage(null);
    setRiskManagementKpiAnalysisLiquidityReserve(null);
    setRiskManagementKpiAnalysisNoConcept(null);
    setRiskManagementKpiAnalysisOther(null);
    setRiskManagementKpiGoalCompanyControlling(null);
    setRiskManagementKpiGoalFulfilledRequirement(null);
    setRiskManagementKpiGoalImprovedControllingRisks(null);
    setRiskManagementKpiIntegrationBudgeting(null);
    setRiskManagementKpiIntegrationNoInterfaces(null);
    setRiskManagementKpiIntegrationOperativeDecisionSupport(null);
    setRiskManagementKpiIntegrationOperativePlanning(null);
    setRiskManagementKpiIntegrationStrategicDecisionSupport(null);
    setRiskManagementKpiIntegrationStrategicPlanning(null);
    setRiskManagementKpiScenarioCompleteRiskPortfolio(null);
    setRiskManagementKpiScenarioCurrentRiskDev(null);
    setRiskManagementKpiScenarioIndividualSingleRisks(null);
    setRiskManagementKpiScenarioStrategicDecisionOptions(null);
    setRiskManagementKpiScenarioTopRisks(null);
    setRiskManagementKpiSoftwareNoSoftwareSupport(null);
    setRiskManagementKpiSoftwareReporting(null);
    setRiskManagementKpiSoftwareRiskDashboards(null);
    setRiskManagementKpiSoftwareRiskRecordingRating(null);
    setRiskManagementKpiSoftwareSimulationModeling(null);
    setFunctionalCosts(null);
    setParticipationCosts(null);
    setResearchDevelopmentCosts(null);
    setResearchDevelopmentFTE(null);
    setProductionCosts(null);
    setProductionFTE(null);
    setProductionKeyDataDeliveryPunctuality(null);
    setProductionKeyDataIdleTimeMachine(null);
    setProductionKeyDataPlantUtilizationRate(null);
    setProductionKeyDataUtilizationRate(null);
    setProductionKeyDataManufacturingQuality(null);
    setProductionKeyDataPostProcessingRate(null);
    setProductionKeyDataScrapRate(null);
    setProductionKeyDataDamageFrequency(null);
    setProductionKeyDataStockBearingManufactory(null);
    setProductionKeyDataSetupTimes(null);
    setProductionKeyDataEmployment(null);
    setSalesCosts(null);
    setSalesFTE(null);
    setSalesPercentageShare(null);
    setBcmCosts(null);
    setAdvancementCosts(null);
    setCpraFTE(null);
    setMgmtReportingFTE(null);
    setFunctionalFTE(null);
    setParticipationFTE(null);
    setBcmFTE(null);
    setAdvancementFTE(null);
    setItCosts(null);
    setItFTE(null);
    setItStructureIt(null);
    setCtrlCorporateCosts(null);
    setCtrlCorporateFTE(null);
    setCtrlCorporateLeadershipClaim(null);
    setCtrlBusinessConsultingManagementCosts(null);
    setCtrlBusinessConsultingManagementFTE(null);
    setCtrlFurtherDevelopmentControllingCosts(null);
    setCtrlFurtherDevelopmentControllingFTE(null);
  };

  /**
   * Handles the change of single process fte states
   */
  const handleFteValueChange = (inputValue, oldValue, setState) => {
    const value = Number.isNaN(parseFloat(inputValue)) ? 0 : parseFloat(inputValue);
    const state = Number.isNaN(parseFloat(oldValue)) ? 0 : parseFloat(oldValue);
    const dif = value - state;
    setState(inputValue);
    if (totalProcessFteActive) {
      setProcessFTE(parseFloat(dif).toFixed(2));
      setTotalProcessFteActive(false);
    } else {
      setProcessFTE(
        processFTE
          ? (parseFloat(processFTE) + parseFloat(dif)).toFixed(2)
          : parseFloat(dif).toFixed(2),
      );
    }
  };

  /**
   * Handles the change of single process cost states
   */
  const handleCostValueChange = (inputValue, oldValue, setState) => {
    const value = Number.isNaN(parseFloat(inputValue)) ? 0 : parseFloat(inputValue);
    const state = Number.isNaN(parseFloat(oldValue)) ? 0 : parseFloat(oldValue);
    const dif = value - state;
    setState(inputValue);
    if (totalProcessCostActive) {
      setProcessCosts(parseFloat(dif).toFixed(2));
      setTotalProcessCostActive(false);
    } else {
      setProcessCosts(
        processCosts
          ? (parseFloat(processCosts) + parseFloat(dif)).toFixed(2)
          : parseFloat(dif).toFixed(2),
      );
    }
  };

  /**
   * Handles the change of the total process fte state
   */
  const handleProcessFteValueChange = (inputValue) => {
    setTotalProcessFteActive(true);
    setProcessFTE(inputValue);
    clearProcessInputs();
  };

  /**
   * Handles the change of the total process cost state
   */
  const handleProcessCostValueChange = (inputValue) => {
    setTotalProcessCostActive(true);
    setProcessCosts(inputValue);
    clearProcessInputs();
  };

  /**
   * Fetches the data for the controlling benchmarking
   */
  const fetchData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(fetchBenchmarkingControlling({ companyId: company.id, benchmarkingId: id }))
          .then(unwrapResult)
          .then((res) => {
            setProcessFTE(res.sumFteControlling);
            setProcessCosts(res.sumCostControlling);
            if (res.ctrlOperativePlanning) {
              setOperativePlanningFTE(res.ctrlOperativePlanning.fte);
              if (res.ctrlOperativePlanning.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setOperativePlanningFTE('');
            }
            if (res.ctrlOperativePlanning) {
              setOperativePlanningCosts(res.ctrlOperativePlanning.cost);
              if (res.ctrlOperativePlanning.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setOperativePlanningCosts('');
            }
            setOperativePlanningWeeks(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.durationWeeks : '',
            );
            setOperativePlanningPlanningPreparation(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.planningPreparation : '',
            );
            setOperativePlanningTargetSetting(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.targetSetting : '',
            );
            setOperativePlanningDecentralizedPlanning(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.decentralizedPlanning : '',
            );
            setOperativePlanningConsolidationCompletionOfPlanning(
              res.ctrlOperativePlanning
                ? res.ctrlOperativePlanning.consolidationCompletionOfPlanning
                : '',
            );
            setOperativePlanningPlanShiftApproval(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.planShiftApproval : '',
            );
            setOperativePlanningVotingRounds(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.votingRounds : '',
            );
            setOperativePlanningHorizonMonths(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.planningHorizonMonths : '',
            );
            setOperativePlanningPlanningAlignment(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.planningAlignment : '',
            );
            setOperativePlanningTypeOperativePlanning(
              res.ctrlOperativePlanning ? res.ctrlOperativePlanning.typeOperativePlanning : '',
            );
            if (res.ctrlStrategicPlanning) {
              setStrategicPlanningFTE(res.ctrlStrategicPlanning.fte);
              if (res.ctrlStrategicPlanning.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setStrategicPlanningFTE('');
            }
            if (res.ctrlStrategicPlanning) {
              setStrategicPlanningCosts(res.ctrlStrategicPlanning.cost);
              if (res.ctrlStrategicPlanning.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setStrategicPlanningCosts('');
            }
            setStrategicPlanningPlanningHorizonMonths(
              res.ctrlStrategicPlanning ? res.ctrlStrategicPlanning.planningHorizonMonths : '',
            );
            setStrategicPlanningDurationWeeks(
              res.ctrlStrategicPlanning ? res.ctrlStrategicPlanning.durationWeeks : '',
            );
            setStrategicPlanningVotingRounds(
              res.ctrlStrategicPlanning ? res.ctrlStrategicPlanning.votingRounds : '',
            );
            setStrategicPlanningNumberParticipants(
              res.ctrlStrategicPlanning ? res.ctrlStrategicPlanning.numberParticipants : '',
            );
            if (res.ctrlForecast) {
              setForecastFTE(res.ctrlForecast.fte);
              if (res.ctrlForecast.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setForecastFTE('');
            }
            if (res.ctrlForecast) {
              setForecastCosts(res.ctrlForecast.cost);
              if (res.ctrlForecast.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setForecastCosts('');
            }
            setForecastForecastHorizonMonths(
              res.ctrlForecast ? res.ctrlForecast.forecastHorizonMonths : '',
            );
            setForecastLevelDetailMonth(res.ctrlForecast ? res.ctrlForecast.levelDetailMonth : '');
            setForecastLevelDetailWeek(res.ctrlForecast ? res.ctrlForecast.levelDetailWeek : '');
            setForecastLevelDetailQuarter(
              res.ctrlForecast ? res.ctrlForecast.levelDetailQuarter : '',
            );
            setForecastLevelDetailOthers(
              res.ctrlForecast ? res.ctrlForecast.levelDetailOthers : '',
            );
            setForecastAccuracy(res.ctrlForecast ? res.ctrlForecast.accuracy : '');
            setForecastTypeForecast(res.ctrlForecast ? res.ctrlForecast.typeForecast : '');
            if (res.ctrlCpra) {
              setCpraFTE(res.ctrlCpra.fte);
              if (res.ctrlCpra.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCpraFTE('');
            }
            if (res.ctrlCpra) {
              setCpraCosts(res.ctrlCpra.cost);
              if (res.ctrlCpra.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCpraCosts('');
            }
            setCpraVarianceCtrAcc(res.ctrlCpra ? res.ctrlCpra.varianceCtrAcc : '');
            setCpraForecastQuality(res.ctrlCpra ? res.ctrlCpra.forecastQuality : '');
            setCpraPlanQuality(res.ctrlCpra ? res.ctrlCpra.planQuality : '');
            setCpraDeviation(res.ctrlCpra ? res.ctrlCpra.deviation : '');
            if (res.ctrlManagementReporting) {
              setMgmtReportingFTE(res.ctrlManagementReporting.fte);
              if (res.ctrlManagementReporting.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setMgmtReportingFTE('');
            }
            if (res.ctrlManagementReporting) {
              setMgmtReportingCosts(res.ctrlManagementReporting.cost);
              if (res.ctrlManagementReporting.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setMgmtReportingCosts('');
            }
            setMgmtReportingAmountReportsExecutiveBoard(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.amountReportsExecutiveBoard
                : '',
            );
            setMgmtReportingAmountReportsSupervisoryBoard(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.amountReportsSupervisoryBoard
                : '',
            );
            setMgmtReportingAdHocReports(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.adHocReports : '',
            );
            setMgmtReportingStandardizedReports(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.standardizedReports : '',
            );
            setMgmtReportingKpisAmount(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.kpisAmount : '',
            );
            setMgmtReportingAutomationPotentialAnalysis(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.automationPotentialAnalysis
                : '',
            );
            setMgmtReportingAutomationPotentialDataManagement(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.automationPotentialDataManagement
                : '',
            );
            setMgmtReportingAutomationPotentialDecisionSupport(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.automationPotentialDecisionSupport
                : '',
            );
            setMgmtReportingAutomationPotentialReportGeneration(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.automationPotentialReportGeneration
                : '',
            );
            setMgmtReportingFormOfUsePush(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.formOfUsePush : '',
            );
            setMgmtReportingFormOfUseSelfService(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.formOfUseSelfService : '',
            );
            setMgmtReportingFormOfUseStandard(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.formOfUseStandard : '',
            );
            setMgmtReportingReportShares(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.reportShares : '',
            );
            setMgmtReportingDegreeDigitization(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.degreeDigitization : '',
            );
            setMgmtReportingReportingClosing(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.reportingClosing : '',
            );
            setMgmtReportingReportingFlash(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.reportingFlash : '',
            );
            setMgmtReportingReportingMonthlyUncommented(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.reportingMonthlyUncommented
                : '',
            );
            setMgmtReportingReportingMonthlyCommented(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.reportingMonthlyCommented
                : '',
            );
            setMgmtReportingReportingDimensions(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.reportingDimensions : '',
            );
            setMgmtReportingIntegrationAiMl(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.integrationAiMl : '',
            );
            setMgmtReportingIntegrationComment(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.integrationComment : '',
            );
            setMgmtReportingIntegrationSustainability(
              res.ctrlManagementReporting
                ? res.ctrlManagementReporting.integrationSustainability
                : '',
            );
            setMgmtReportingReportingProcess(
              res.ctrlManagementReporting ? res.ctrlManagementReporting.reportingProcess : '',
            );
            if (res.ctrlPic) {
              setPicFTE(res.ctrlPic.fte);
              if (res.ctrlPic.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setPicFTE('');
            }
            if (res.ctrlPic) {
              setPicCosts(res.ctrlPic.cost);
              if (res.ctrlPic.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setPicCosts('');
            }
            setPicInvestmentLimit(res.ctrlPic ? res.ctrlPic.investmentLimit : '');
            setPicDurationRequest(res.ctrlPic ? res.ctrlPic.durationRequest : '');
            setPicStatusProjectsSuccessful(res.ctrlPic ? res.ctrlPic.statusProjectsSuccessful : '');
            setPicStatusProjectsAborted(res.ctrlPic ? res.ctrlPic.statusProjectsAborted : '');
            setPicStatusProjectsStopped(res.ctrlPic ? res.ctrlPic.statusProjectsStopped : '');
            setPicDeviationProjectCosts(res.ctrlPic ? res.ctrlPic.deviationProjectCosts : null);
            setPicDeviationProjectTime(res.ctrlPic ? res.ctrlPic.deviationProjectTime : null);
            setPicDeviationProjectPerformance(
              res.ctrlPic ? res.ctrlPic.deviationProjectPerformance : null,
            );
            if (res.ctrlRiskManagement) {
              setRiskManagementFTE(res.ctrlRiskManagement.fte);
              if (res.ctrlRiskManagement.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setRiskManagementFTE('');
            }
            if (res.ctrlRiskManagement) {
              setRiskManagementCosts(res.ctrlRiskManagement.cost);
              if (res.ctrlRiskManagement.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setRiskManagementCosts('');
            }
            setRiskManagementTypeRiskManagement(
              res.ctrlRiskManagement ? res.ctrlRiskManagement.typeRiskManagement : '',
            );
            setRiskManagementResponsibilityRiskManagement(
              res.ctrlRiskManagement ? res.ctrlRiskManagement.responsibilityRiskManagement : '',
            );
            setRiskManagementFrequenceRiskManagement(
              res.ctrlRiskManagement ? res.ctrlRiskManagement.frequenceRiskManagement : '',
            );
            setRiskManagementControllingRelevance(
              res.ctrlRiskManagement ? res.ctrlRiskManagement.controllingRelevance : '',
            );
            setRiskManagementMeasures(
              res.ctrlRiskManagement ? res.ctrlRiskManagement.measures : '',
            );
            setRiskManagementKpiGoalCompanyControlling(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiGoalRiskManagement
                ? res.ctrlRiskManagement.kpiGoalRiskManagement.companyControlling
                : null,
            );
            setRiskManagementKpiGoalFulfilledRequirement(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiGoalRiskManagement
                ? res.ctrlRiskManagement.kpiGoalRiskManagement.fulfilledRequirement
                : null,
            );
            setRiskManagementKpiGoalImprovedControllingRisks(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiGoalRiskManagement
                ? res.ctrlRiskManagement.kpiGoalRiskManagement.improvedControllingRisks
                : null,
            );
            setRiskManagementKpiAnalysisEquityCoverage(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
                ? res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.equityCoverage
                : null,
            );
            setRiskManagementKpiAnalysisLiquidityReserve(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
                ? res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.liquidityReserve
                : null,
            );
            setRiskManagementKpiAnalysisNoConcept(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
                ? res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.noConcept
                : null,
            );
            setRiskManagementKpiAnalysisOther(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity
                ? res.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.other
                : null,
            );
            setRiskManagementKpiIntegrationBudgeting(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.budgeting
                : null,
            );
            setRiskManagementKpiIntegrationOperativePlanning(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.operativePlanning
                : null,
            );
            setRiskManagementKpiIntegrationStrategicDecisionSupport(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.strategicDecisionSupport
                : null,
            );
            setRiskManagementKpiIntegrationStrategicPlanning(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.strategicPlanning
                : null,
            );
            setRiskManagementKpiIntegrationOperativeDecisionSupport(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.operativeDecisionSupport
                : null,
            );
            setRiskManagementKpiIntegrationNoInterfaces(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiIntegrationRiskManagement
                ? res.ctrlRiskManagement.kpiIntegrationRiskManagement.noInterfaces
                : null,
            );
            setRiskManagementKpiScenarioCompleteRiskPortfolio(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiScenarioAnalysis
                ? res.ctrlRiskManagement.kpiScenarioAnalysis.completeRiskPortfolio
                : null,
            );
            setRiskManagementKpiScenarioCurrentRiskDev(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiScenarioAnalysis
                ? res.ctrlRiskManagement.kpiScenarioAnalysis.currentRiskDev
                : null,
            );
            setRiskManagementKpiScenarioIndividualSingleRisks(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiScenarioAnalysis
                ? res.ctrlRiskManagement.kpiScenarioAnalysis.individualSingleRisks
                : null,
            );
            setRiskManagementKpiScenarioTopRisks(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiScenarioAnalysis
                ? res.ctrlRiskManagement.kpiScenarioAnalysis.topRisks
                : null,
            );
            setRiskManagementKpiScenarioStrategicDecisionOptions(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiScenarioAnalysis
                ? res.ctrlRiskManagement.kpiScenarioAnalysis.strategicDecisionOptions
                : null,
            );
            setRiskManagementKpiSoftwareNoSoftwareSupport(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiSoftwareSupport
                ? res.ctrlRiskManagement.kpiSoftwareSupport.noSoftwareSupport
                : null,
            );
            setRiskManagementKpiSoftwareReporting(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiSoftwareSupport
                ? res.ctrlRiskManagement.kpiSoftwareSupport.reporting
                : null,
            );
            setRiskManagementKpiSoftwareRiskDashboards(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiSoftwareSupport
                ? res.ctrlRiskManagement.kpiSoftwareSupport.riskDashboards
                : null,
            );
            setRiskManagementKpiSoftwareRiskRecordingRating(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiSoftwareSupport
                ? res.ctrlRiskManagement.kpiSoftwareSupport.riskRecordingRating
                : null,
            );
            setRiskManagementKpiSoftwareSimulationModeling(
              res.ctrlRiskManagement && res.ctrlRiskManagement.kpiSoftwareSupport
                ? res.ctrlRiskManagement.kpiSoftwareSupport.simulationModeling
                : null,
            );
            if (res.ctrlFunctional) {
              setFunctionalFTE(res.ctrlFunctional.fte);
              if (res.ctrlFunctional.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFunctionalFTE('');
            }
            if (res.ctrlFunctional) {
              setFunctionalCosts(res.ctrlFunctional.cost);
              if (res.ctrlFunctional.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFunctionalCosts('');
            }
            if (res.ctrlParticipation) {
              setParticipationFTE(res.ctrlParticipation.fte);
              if (res.ctrlParticipation.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setParticipationFTE('');
            }
            if (res.ctrlParticipation) {
              setParticipationCosts(res.ctrlParticipation.cost);
              if (res.ctrlParticipation.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setParticipationCosts('');
            }
            if (res.ctrlResearchDevelopment) {
              setResearchDevelopmentFTE(res.ctrlResearchDevelopment.fte);
              if (res.ctrlResearchDevelopment.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setResearchDevelopmentFTE('');
            }
            if (res.ctrlResearchDevelopment) {
              setResearchDevelopmentCosts(res.ctrlResearchDevelopment.cost);
              if (res.ctrlResearchDevelopment.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setResearchDevelopmentCosts('');
            }
            setResearchDevelopmentPercentageShare(
              res.ctrlResearchDevelopment ? res.ctrlResearchDevelopment.percentageShare : '',
            );
            setResearchDevelopmentResourcesEffort(
              res.ctrlResearchDevelopment ? res.ctrlResearchDevelopment.resourcesEffort : '',
            );
            setResearchDevelopmentCompositionPortfolioBasicResearch(
              res.ctrlResearchDevelopment ? res.ctrlResearchDevelopment.projectBasicResearch : '',
            );
            setResearchDevelopmentCompositionPortfolioNewProductDev(
              res.ctrlResearchDevelopment ? res.ctrlResearchDevelopment.projectNewProductDev : '',
            );
            setResearchDevelopmentCompositionPortfolioFurtherDev(
              res.ctrlResearchDevelopment ? res.ctrlResearchDevelopment.projectFurtherDev : '',
            );
            if (res.ctrlProduction) {
              setProductionFTE(res.ctrlProduction.fte);
              if (res.ctrlProduction.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setProductionFTE('');
            }
            if (res.ctrlProduction) {
              setProductionCosts(res.ctrlProduction.cost);
              if (res.ctrlProduction.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setProductionCosts('');
            }
            setProductionKeyDataDeliveryPunctuality(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.deliveryPunctuality
                : null,
            );
            setProductionKeyDataIdleTimeMachine(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.idleTimeMachine
                : null,
            );
            setProductionKeyDataPlantUtilizationRate(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.plantUtilizationRate
                : null,
            );
            setProductionKeyDataUtilizationRate(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.utilizationRate
                : null,
            );
            setProductionKeyDataManufacturingQuality(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.manufacturingQuality
                : null,
            );
            setProductionKeyDataPostProcessingRate(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.postProcessingRate
                : null,
            );
            setProductionKeyDataScrapRate(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.scrapRate
                : null,
            );
            setProductionKeyDataDamageFrequency(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.damageFrequency
                : null,
            );
            setProductionKeyDataStockBearingManufactory(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.stockBearingManufacturing
                : null,
            );
            setProductionKeyDataSetupTimes(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.setupTimes
                : null,
            );
            setProductionKeyDataEmployment(
              res.ctrlProduction && res.ctrlProduction.kpiKeyData
                ? res.ctrlProduction.kpiKeyData.employment
                : null,
            );
            if (res.ctrlSales) {
              setSalesCosts(res.ctrlSales.cost);
              if (res.ctrlSales.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setSalesCosts('');
            }
            setSalesPercentageShare(res.ctrlSales ? res.ctrlSales.percentageShare : '');
            if (res.ctrlBcm) {
              setBcmFTE(res.ctrlBcm.fte);
              if (res.ctrlBcm.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setBcmFTE('');
            }
            if (res.ctrlBcm) {
              setBcmCosts(res.ctrlBcm.cost);
              if (res.ctrlBcm.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setBcmCosts('');
            }
            if (res.ctrlImprovement) {
              setAdvancementFTE(res.ctrlImprovement.fte);
              if (res.ctrlImprovement.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setAdvancementFTE('');
            }
            if (res.ctrlImprovement) {
              setAdvancementCosts(res.ctrlImprovement.cost);
              if (res.ctrlImprovement.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setAdvancementCosts('');
            }
            if (res.ctrlIt) {
              setItCosts(res.ctrlIt.cost);
              if (res.ctrlIt.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setItCosts('');
            }
            if (res.ctrlIt) {
              setItFTE(res.ctrlIt.fte);
              if (res.ctrlIt.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setItFTE('');
            }
            setItStructureIt(res.ctrlIt ? res.ctrlIt.structureIt : '');
            if (res.ctrlCorporate) {
              setCtrlCorporateCosts(res.ctrlCorporate.cost);
              if (res.ctrlCorporate.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlCorporateCosts('');
            }
            if (res.ctrlCorporate) {
              setCtrlCorporateFTE(res.ctrlCorporate.fte);
              if (res.ctrlCorporate.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlCorporateFTE('');
            }
            setCtrlCorporateLeadershipClaim(
              res.ctrlCorporate ? res.ctrlCorporate.leadershipClaim : '',
            );
            if (res.ctrlBusinessConsultingManagement) {
              setCtrlBusinessConsultingManagementFTE(res.ctrlBusinessConsultingManagement.fte);
              if (res.ctrlBusinessConsultingManagement.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlBusinessConsultingManagementFTE('');
            }
            if (res.ctrlBusinessConsultingManagement) {
              setCtrlBusinessConsultingManagementCosts(res.ctrlBusinessConsultingManagement.cost);
              if (res.ctrlBusinessConsultingManagement.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlBusinessConsultingManagementCosts('');
            }
            if (res.ctrlFurtherDevelopmentControlling) {
              setCtrlFurtherDevelopmentControllingFTE(res.ctrlFurtherDevelopmentControlling.fte);
              if (res.ctrlFurtherDevelopmentControlling.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlFurtherDevelopmentControllingFTE('');
            }
            if (res.ctrlFurtherDevelopmentControlling) {
              setCtrlFurtherDevelopmentControllingCosts(res.ctrlFurtherDevelopmentControlling.cost);
              if (res.ctrlFurtherDevelopmentControlling.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCtrlFurtherDevelopmentControllingCosts('');
            }
          })
          .catch(() => {
            toast({
              title: t('errors.benchmarking.controlling.fetchControllingDataFailed.title'),
              description: t(
                'errors.benchmarking.controlling.fetchControllingDataFailed.description',
              ),
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: t('errors.benchmarking.controlling.fetchCompanyDataFailed.title'),
          description: t('errors.benchmarking.controlling.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetch benchmarking data
   */
  React.useEffect(() => {
    fetchData();
  }, []);

  /**
   * Save Benchmarking Data and navigate to overview
   */
  const saveBenchmarkingControllingData = () => {
    if (
      !validateOPWeeks ||
      !validateOPPlanningPreparation ||
      !validateOPTargetSetting ||
      !validateOPDecentralizedPlanning ||
      !validateOPConsolidationCompletionOfPlanning ||
      !validateOPPlanShiftApproval ||
      !validateOPPlanningHorizonMonths ||
      !validateOPVotingRounds ||
      !validateStrategicPlanningPlanningHorizonMonths ||
      !validateStrategicPlanningDurationWeeks ||
      !validateStrategicPlanningVotingRounds ||
      !validateStrategicPlanningNumberParticipants ||
      !validatePicInvestmentLimit ||
      !validatePicDurationRequest ||
      !validatePicStatusProjectsSuccessful ||
      !validatePicStatusProjectsAborted ||
      !validatePicStatusProjectsStopped ||
      // Temporarly removed due to customer request
      // !validateForecastLevelDetailWeek ||
      // !validateForecastLevelDetailMonth ||
      // !validateForecastLevelDetailQuarter ||
      // !validateForecastLevelDetailOthers ||
      !validateForecastAccuracy ||
      !validateForecastForecastHorizonMonths ||
      !validateCpraVarianceCtrAcc ||
      !validateCpraForecastQuality ||
      !validateCpraPlanQuality ||
      !validateCpraDeviation ||
      !validateMgmtReportingAdHocReports ||
      !validateMgmtReportingAmountReportsExecutiveBoard ||
      !validateMgmtReportingAmountReportsSupervisoryBoard ||
      !validateMgmtReportingAutomationPotentialAnalysis ||
      !validateMgmtReportingAutomationPotentialDataManagement ||
      !validateMgmtReportingAutomationPotentialDecisionSupport ||
      !validateMgmtReportingAutomationPotentialReportGeneration ||
      !validateMgmtReportingDegreeDigitization ||
      !validateMgmtReportingFormOfUsePush ||
      !validateMgmtReportingFormOfUseSelfService ||
      !validateMgmtReportingFormOfUseStandard ||
      !validateMgmtReportingKpisAmount ||
      !validateMgmtReportingReportShares ||
      !validateMgmtReportingReportingClosing ||
      !validateMgmtReportingReportingFlash ||
      !validateMgmtReportingReportingMonthlyCommented ||
      !validateMgmtReportingReportingMonthlyUncommented ||
      !validateMgmtReportingStandardizedReports ||
      !validateResearchDevelopmentPercentageShare ||
      !validateResearchDevelopmentResourcesEffort ||
      !validateResearchDevelopmentCompositionPortfolioBasicResearch ||
      !validateResearchDevelopmentCompositionPortfolioNewProductDev ||
      !validateResearchDevelopmentCompositionPortfolioFurtherDev
    ) {
      toast({
        title: t('errors.benchmarking.controlling.saveDataFailed.title'),
        description: t('errors.benchmarking.controlling.saveDataFailed.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    dispatch(
      editBenchmarkingControlling({
        ...benchmarkingControlling,
        sumFteControlling: parseFloat(processFTE, 10),
        sumCostControlling: parseInt(processCosts, 10),
        ctrlBcm: {
          ...benchmarkingControlling.ctrlBcm,
          fte: parseFloat(bcmFTE, 10),
          cost: parseInt(bcmCosts, 10),
        },
        ctrlCpra: {
          ...benchmarkingControlling.ctrlCpra,
          fte: parseFloat(cpraFTE, 10),
          cost: parseInt(cpraCosts, 10),
          varianceCtrAcc: parseInt(cpraVarianceCtrAcc, 10),
          forecastQuality: parseInt(cpraForecastQuality, 10),
          planQuality: parseInt(cpraPlanQuality, 10),
          deviation: parseInt(cpraDeviation, 10),
        },
        ctrlForecast: {
          ...benchmarkingControlling.ctrlForecast,
          fte: parseFloat(forecastFTE, 10),
          cost: parseInt(forecastCosts, 10),
          typeForecast: forecastTypeForecast !== '' ? forecastTypeForecast : null,
          levelDetailWeek: parseInt(forecastLevelDetailWeek, 10),
          levelDetailMonth: parseInt(forecastLevelDetailMonth, 10),
          levelDetailQuarter: parseInt(forecastLevelDetailQuarter, 10),
          levelDetailOthers: parseInt(forecastLevelDetailOthers, 10),
          accuracy: parseInt(forecastAccuracy, 10),
          forecastHorizonMonths: parseInt(forecastForecastHorizonMonths, 10),
        },
        ctrlFunctional: {
          ...benchmarkingControlling.ctrliFunctional,
          fte: parseFloat(functionalFTE, 10),
          cost: parseInt(functionalCosts, 10),
        },
        ctrlImprovement: {
          ...benchmarkingControlling.ctrlImprovement,
          fte: parseFloat(advancementFTE, 10),
          cost: parseInt(advancementCosts, 10),
        },
        ctrlManagementReporting: {
          ...benchmarkingControlling.ctrlManagementReporting,
          fte: parseFloat(mgmtReportingFTE, 10),
          cost: parseInt(mgmtReportingCosts, 10),
          amountReportsExecutiveBoard: parseInt(mgmtReportingAmountReportsExecutiveBoard, 10),
          amountReportsSupervisoryBoard: parseInt(mgmtReportingAmountReportsSupervisoryBoard, 10),
          adHocReports:
            mgmtReportingAdHocReports !== 0 ? parseInt(mgmtReportingAdHocReports, 10) : null,
          standardizedReports:
            mgmtReportingAdHocReports !== 0 ? parseInt(mgmtReportingStandardizedReports, 10) : null,
          kpisAmount: parseInt(mgmtReportingKpisAmount, 10),
          automationPotentialDataManagement: parseInt(
            mgmtReportingAutomationPotentialDataManagement,
            10,
          ),
          automationPotentialReportGeneration: parseInt(
            mgmtReportingAutomationPotentialReportGeneration,
            10,
          ),
          automationPotentialAnalysis: parseInt(mgmtReportingAutomationPotentialAnalysis, 10),
          automationPotentialDecisionSupport: parseInt(
            mgmtReportingAutomationPotentialDecisionSupport,
            10,
          ),
          formOfUsePush: parseInt(mgmtReportingFormOfUsePush, 10),
          formOfUseStandard: parseInt(mgmtReportingFormOfUseStandard, 10),
          formOfUseSelfService: parseInt(mgmtReportingFormOfUseSelfService, 10),
          reportShares: parseInt(mgmtReportingReportShares, 10),
          degreeDigitization: parseInt(mgmtReportingDegreeDigitization, 10),
          reportingClosing: parseInt(mgmtReportingReportingClosing, 10),
          reportingFlash: parseInt(mgmtReportingReportingFlash, 10),
          reportingMonthlyUncommented: parseInt(mgmtReportingReportingMonthlyUncommented, 10),
          reportingMonthlyCommented: parseInt(mgmtReportingReportingMonthlyCommented, 10),
          reportingDimensions:
            mgmtReportingReportingDimensions !== '' ? mgmtReportingReportingDimensions : null,
          integrationAiMl:
            mgmtReportingIntegrationAiMl !== '' ? mgmtReportingIntegrationAiMl : null,
          integrationComment:
            mgmtReportingIntegrationComment !== '' ? mgmtReportingIntegrationComment : null,
          integrationSustainability:
            mgmtReportingIntegrationSustainability !== ''
              ? mgmtReportingIntegrationSustainability
              : null,
          reportingProcess:
            mgmtReportingReportingProcess !== '' ? mgmtReportingReportingProcess : null,
        },
        ctrlOperativePlanning: {
          ...benchmarkingControlling.ctrlOperativePlanning,
          fte: parseFloat(operativePlanningFTE, 10),
          cost: parseInt(operativePlanningCosts, 10),
          durationWeeks: parseInt(operativePlanningWeeks, 10),
          planningPreparation: parseInt(operativePlanningPlanningPreparation, 10),
          targetSetting: parseInt(operativePlanningTargetSetting, 10),
          decentralizedPlanning: parseInt(operativePlanningDecentralizedPlanning, 10),
          consolidationCompletionOfPlanning: parseInt(
            operativePlanningConsolidationCompletionOfPlanning,
            10,
          ),
          planShiftApproval: parseInt(operativePlanningPlanShiftApproval, 10),
          votingRounds: parseInt(operativePlanningVotingRounds, 10),
          planningAlignment:
            operativePlanningPlanningAlignment !== ''
              ? convertPlanningAlignmentTypeToValue(operativePlanningPlanningAlignment)
              : null,
          planningHorizonMonths: parseInt(operativePlanningHorizonMonths, 10),
          typeOperativePlanning:
            operativePlanningTypeOperativePlanning !== ''
              ? convertTypeOperativePlanningTypeToValue(operativePlanningTypeOperativePlanning)
              : null,
        },
        ctrlParticipation: {
          ...benchmarkingControlling.ctrlParticipation,
          fte: parseFloat(participationFTE, 10),
          cost: parseInt(participationCosts, 10),
        },
        ctrlPic: {
          ...benchmarkingControlling.ctrlPic,
          fte: parseFloat(picFTE, 10),
          cost: parseInt(picCosts, 10),
          investmentLimit: parseInt(picInvestmentLimit, 10),
          durationRequest: parseInt(picDurationRequest, 10),
          statusProjectsSuccessful: parseInt(picStatusProjectsSuccessful, 10),
          statusProjectsAborted: parseInt(picStatusProjectsAborted, 10),
          statusProjectsStopped: parseInt(picStatusProjectsStopped, 10),
          deviationProjectCosts: picDeviationProjectCosts,
          deviationProjectTime: picDeviationProjectTime,
          deviationProjectPerformance: picDeviationProjectPerformance,
        },
        ctrlProduction: {
          ...benchmarkingControlling.ctrlProduction,
          fte: parseFloat(productionFTE, 10),
          cost: parseInt(productionCosts, 10),
          kpiKeyData: benchmarkingControlling.ctrlProduction
            ? {
                ...benchmarkingControlling.ctrlProduction.kpiKeyData,
                deliveryPunctuality: productionKeyDataDeliveryPunctuality,
                idleTimeMachine: productionKeyDataIdleTimeMachine,
                plantUtilizationRate: productionKeyDataPlantUtilizationRate,
                utilizationRate: productionKeyDataUtilizationRate,
                manufacturingQuality: productionKeyDataManufacturingQuality,
                postProcessingRate: productionKeyDataPostProcessingRate,
                scrapRate: productionKeyDataScrapRate,
                damageFrequency: productionKeyDataDamageFrequency,
                stockBearingManufacturing: productionKeyDataStockBearingManufactory,
                setupTimes: productionKeyDataSetupTimes,
                employment: productionKeyDataEmployment,
              }
            : {
                deliveryPunctuality: productionKeyDataDeliveryPunctuality,
                idleTimeMachine: productionKeyDataIdleTimeMachine,
                plantUtilizationRate: productionKeyDataPlantUtilizationRate,
                utilizationRate: productionKeyDataUtilizationRate,
                manufacturingQuality: productionKeyDataManufacturingQuality,
                postProcessingRate: productionKeyDataPostProcessingRate,
                scrapRate: productionKeyDataScrapRate,
                damageFrequency: productionKeyDataDamageFrequency,
                stockBearingManufacturing: productionKeyDataStockBearingManufactory,
                setupTimes: productionKeyDataSetupTimes,
                employment: productionKeyDataEmployment,
              },
        },
        ctrlResearchDevelopment: {
          ...benchmarkingControlling.ctrlResearchDevelopment,
          fte: parseFloat(researchDevelopmentFTE, 10),
          cost: parseInt(researchDevelopmentCosts, 10),
          percentageShare: parseInt(researchDevelopmentPercentageShare, 10),
          resourcesEffort: parseInt(researchDevelopmentResourcesEffort, 10),
          projectBasicResearch: parseInt(researchDevelopmentCompositionPortfolioBasicResearch, 10),
          projectNewProductDev: parseInt(researchDevelopmentCompositionPortfolioNewProductDev, 10),
          projectFurtherDev: parseInt(researchDevelopmentCompositionPortfolioFurtherDev, 10),
        },
        ctrlRiskManagement: {
          ...benchmarkingControlling.ctrlRiskManagement,
          fte: parseFloat(riskManagementFTE, 10),
          cost: parseInt(riskManagementCosts, 10),
          typeRiskManagement:
            riskManagementTypeRiskManagement !== '' ? riskManagementTypeRiskManagement : null,
          responsibilityRiskManagement:
            riskManagementResponsibilityRiskManagement !== ''
              ? riskManagementResponsibilityRiskManagement
              : null,
          frequenceRiskManagement:
            riskManagementFrequenceRiskManagement !== ''
              ? riskManagementFrequenceRiskManagement
              : null,
          controllingRelevance:
            riskManagementControllingRelevance !== '' ? riskManagementControllingRelevance : null,
          measures: riskManagementMeasures !== '' ? riskManagementMeasures : null,
          kpiGoalRiskManagement: benchmarkingControlling.ctrlRiskManagement
            ? {
                ...benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement,
                fulfilledRequirement: riskManagementKpiGoalFulfilledRequirement,
                improvedControllingRisks: riskManagementKpiGoalImprovedControllingRisks,
                companyControlling: riskManagementKpiGoalCompanyControlling,
              }
            : {
                fulfilledRequirement: riskManagementKpiGoalFulfilledRequirement,
                improvedControllingRisks: riskManagementKpiGoalImprovedControllingRisks,
                companyControlling: riskManagementKpiGoalCompanyControlling,
              },
          kpiIntegrationRiskManagement: benchmarkingControlling.ctrlRiskManagement
            ? {
                ...benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement,
                strategicPlanning: riskManagementKpiIntegrationStrategicPlanning,
                operativePlanning: riskManagementKpiIntegrationOperativePlanning,
                budgeting: riskManagementKpiIntegrationBudgeting,
                strategicDecisionSupport: riskManagementKpiIntegrationStrategicDecisionSupport,
                operativeDecisionSupport: riskManagementKpiIntegrationOperativeDecisionSupport,
                noInterfaces: riskManagementKpiIntegrationNoInterfaces,
              }
            : {
                strategicPlanning: riskManagementKpiIntegrationStrategicPlanning,
                operativePlanning: riskManagementKpiIntegrationOperativePlanning,
                budgeting: riskManagementKpiIntegrationBudgeting,
                strategicDecisionSupport: riskManagementKpiIntegrationStrategicDecisionSupport,
                operativeDecisionSupport: riskManagementKpiIntegrationOperativeDecisionSupport,
                noInterfaces: riskManagementKpiIntegrationNoInterfaces,
              },
          kpiAnalysisRiskBearingCapacity: benchmarkingControlling.ctrlRiskManagement
            ? {
                ...benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity,
                noConcept: riskManagementKpiAnalysisNoConcept,
                equityCoverage: riskManagementKpiAnalysisEquityCoverage,
                liquidityReserve: riskManagementKpiAnalysisLiquidityReserve,
                other: riskManagementKpiAnalysisOther,
              }
            : {
                noConcept: riskManagementKpiAnalysisNoConcept,
                equityCoverage: riskManagementKpiAnalysisEquityCoverage,
                liquidityReserve: riskManagementKpiAnalysisLiquidityReserve,
                other: riskManagementKpiAnalysisOther,
              },
          kpiSoftwareSupport: benchmarkingControlling.ctrlRiskManagement
            ? {
                ...benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport,
                riskRecordingRating: riskManagementKpiSoftwareRiskRecordingRating,
                reporting: riskManagementKpiSoftwareReporting,
                riskDashboards: riskManagementKpiSoftwareRiskDashboards,
                simulationModeling: riskManagementKpiSoftwareSimulationModeling,
                noSoftwareSupport: riskManagementKpiSoftwareNoSoftwareSupport,
              }
            : {
                riskRecordingRating: riskManagementKpiSoftwareRiskRecordingRating,
                reporting: riskManagementKpiSoftwareReporting,
                riskDashboards: riskManagementKpiSoftwareRiskDashboards,
                simulationModeling: riskManagementKpiSoftwareSimulationModeling,
                noSoftwareSupport: riskManagementKpiSoftwareNoSoftwareSupport,
              },
          kpiScenarioAnalysis: benchmarkingControlling.ctrlRiskManagement
            ? {
                ...benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis,
                currentRiskDev: riskManagementKpiScenarioCurrentRiskDev,
                individualSingleRisks: riskManagementKpiScenarioIndividualSingleRisks,
                topRisks: riskManagementKpiScenarioTopRisks,
                completeRiskPortfolio: riskManagementKpiScenarioCompleteRiskPortfolio,
                strategicDecisionOptions: riskManagementKpiScenarioStrategicDecisionOptions,
              }
            : {
                currentRiskDev: riskManagementKpiScenarioCurrentRiskDev,
                individualSingleRisks: riskManagementKpiScenarioIndividualSingleRisks,
                topRisks: riskManagementKpiScenarioTopRisks,
                completeRiskPortfolio: riskManagementKpiScenarioCompleteRiskPortfolio,
                strategicDecisionOptions: riskManagementKpiScenarioStrategicDecisionOptions,
              },
        },
        ctrlSales: {
          ...benchmarkingControlling.ctrlSales,
          fte: parseFloat(salesFTE, 10),
          cost: parseInt(salesCosts, 10),
          percentageShare: parseInt(salesPercentageShare, 10),
        },
        ctrlStrategicPlanning: {
          ...benchmarkingControlling.ctrlStrategicPlanning,
          fte: parseFloat(strategicPlanningFTE, 10),
          cost: parseInt(strategicPlanningCosts, 10),
          planningHorizonMonths: parseInt(strategicPlanningPlanningHorizonMonths, 10),
          durationWeeks: parseInt(strategicPlanningDurationWeeks, 10),
          votingRounds: parseInt(strategicPlanningVotingRounds, 10),
          numberParticipants: parseInt(strategicPlanningNumberParticipants, 10),
        },
        ctrlIt: {
          ...benchmarkingControlling.ctrlIt,
          fte: parseFloat(itFTE, 10),
          cost: parseInt(itCosts, 10),
          structureIt: itStructureIt !== '' ? itStructureIt : null,
        },
        ctrlCorporate: {
          ...benchmarkingControlling.ctrlCorporate,
          fte: parseFloat(ctrlCorporateFTE, 10),
          cost: parseInt(ctrlCorporateCosts, 10),
          leadershipClaim:
            ctrlCorporateLeadershipClaim !== '' ? ctrlCorporateLeadershipClaim : null,
        },
        ctrlBusinessConsultingManagement: {
          ...benchmarkingControlling.ctrlBusinessConsultingManagement,
          fte: parseFloat(ctrlBusinessConsultingManagementFTE, 10),
          cost: parseInt(ctrlBusinessConsultingManagementCosts, 10),
        },
        ctrlFurtherDevelopmentControlling: {
          ...benchmarkingControlling.ctrlFurtherDevelopmentControlling,
          fte: parseFloat(ctrlFurtherDevelopmentControllingFTE, 10),
          cost: parseInt(ctrlFurtherDevelopmentControllingCosts, 10),
        },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchBenchmarkingControlling());
        history.push(`/benchmarking/${benchmarkingControlling.id}`);
        toast({
          title: t('controlling.benchmarking.saveSuccessMessage.title'),
          description: '',
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t('errors.benchmarking.controlling.saveDataFailed.title'),
          description: t('errors.benchmarking.controlling.saveDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Handles the click Input by showing a warning
   */
  const handleInputClick = (process, name) => {
    if (!process.warningShown) {
      dispatch(deactivateWarning(name));
      onAlertOpen();
    }
  };

  const overallProcess = () => (
    <Card>
      <CardHeader
        title={t('controlling.benchmarking.totalProcess.title')}
        tooltipText={t('controlling.benchmarking.totalProcess.tooltip')}
      />
      <FormControl>
        <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
          <FormProperty
            label={t('global.fte')}
            formType="number-input"
            value={processFTE}
            disabled={benchmarkingControlling.submitDate !== null}
            onValueChange={(val) => handleProcessFteValueChange(val)}
            onClick={() => {
              handleInputClick(warningBenchmarkingControlling.ctrlProcess, 'ctrlProcess');
            }}
            testName="Company FTE"
          />
          <FormProperty
            label={t('global.costs')}
            formType="currency-input"
            value={processCosts}
            disabled={benchmarkingControlling.submitDate !== null}
            onValueChange={(val) => handleProcessCostValueChange(val)}
            onClick={() => {
              handleInputClick(warningBenchmarkingControlling.ctrlProcess, 'ctrlProcess');
            }}
            testName="Company Gesamtkosten"
          />
        </Stack>
      </FormControl>
    </Card>
  );

  const singleProcesses = (isDisabled) => (
    <Card>
      <CardHeader
        title={t('controlling.benchmarking.singleProcesses.title')}
        tooltipText={t('controlling.benchmarking.singleProcesses.tooltip')}
      />
      <Accordion allowToggle>
        <AccordionItem p={2} isDisabled={isDisabled}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                  background: 'horvath.grey-light',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.strategicPlanning.title')}
                  tooltipText={t('controlling.benchmarking.strategicPlanning.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={strategicPlanningFTE}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={(val) =>
                      handleFteValueChange(val, strategicPlanningFTE, setStrategicPlanningFTE)
                    }
                    testName="Strategy FTE"
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={strategicPlanningCosts}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={(val) => {
                      handleCostValueChange(val, strategicPlanningCosts, setStrategicPlanningCosts);
                    }}
                    testName="Strategy Gesamtkosten"
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.strategicPlanning.planningHorizonMonths.title',
                    )}
                    formType="number-input"
                    value={strategicPlanningPlanningHorizonMonths}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleStrategicPlanningPlanningHorizonMonthsValueChange}
                    testName="SP Planungshorizont"
                    tooltipText={t(
                      'controlling.benchmarking.strategicPlanning.planningHorizonMonths.tooltip',
                    )}
                    invalid={!validateStrategicPlanningPlanningHorizonMonths}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgStrategicPlanningPlanningHorizonMonths}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.strategicPlanning.durationWeeks.title')}
                    formType="number-input"
                    value={strategicPlanningDurationWeeks}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleStrategicPlanningDurationDaysValueChange}
                    testName="SP Dauer strategische Planung"
                    tooltipText={t(
                      'controlling.benchmarking.strategicPlanning.durationWeeks.tooltip',
                    )}
                    invalid={!validateStrategicPlanningDurationWeeks}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgStrategicPlanningDurationWeeks}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.strategicPlanning.votingRounds.title')}
                    formType="number-input"
                    value={strategicPlanningVotingRounds}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleStrategicPlanningVotingRoundsValueChange}
                    testName="SP Abstimmungsrunden"
                    tooltipText={t(
                      'controlling.benchmarking.strategicPlanning.votingRounds.tooltip',
                    )}
                    invalid={!validateStrategicPlanningVotingRounds}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgStrategicPlanningVotingsRounds}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.strategicPlanning.numberParticipants.title')}
                    formType="number-input"
                    value={strategicPlanningNumberParticipants}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleStrategicPlanningNumberParticipantsValueChange}
                    testName="SP involvierte Akteure"
                    tooltipText={t(
                      'controlling.benchmarking.strategicPlanning.numberParticipants.tooltip',
                    )}
                    invalid={!validateStrategicPlanningNumberParticipants}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgStrategicPlanningNumberParticipants}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                  background: 'horvath.grey-light',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.operativePlanning.title')}
                  tooltipText={t('controlling.benchmarking.operativePlanning.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={operativePlanningFTE}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={(val) =>
                      handleFteValueChange(val, operativePlanningFTE, setOperativePlanningFTE)
                    }
                    testName="OP FTE"
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={operativePlanningCosts}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={(val) => {
                      handleCostValueChange(val, operativePlanningCosts, setOperativePlanningCosts);
                    }}
                    testName="OP Gesamtkosten"
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <Divider mb={2} />
                  <Heading as="h3" size="sm">
                    Weitere Kennzahlen
                  </Heading>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.operativePlanningWeeks.title',
                    )}
                    formType="number-input"
                    value={operativePlanningWeeks}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPWeeksValueChange}
                    testName="OP Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.operativePlanningWeeks.tooltip',
                    )}
                    invalid={!validateOPWeeks}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPWeeks}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.planningPreparation.title',
                    )}
                    formType="number-input"
                    value={operativePlanningPlanningPreparation}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPPlanningPreparationValueChange}
                    testName="OP Planungsvorbereitung Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.planningPreparation.tooltip',
                    )}
                    invalid={!validateOPPlanningPreparation}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPPlanningPreparation}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.operativePlanning.targetSetting.title')}
                    formType="number-input"
                    value={operativePlanningTargetSetting}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPTargetSettingValueChange}
                    testName="OP Zielsetzung Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.targetSetting.tooltip',
                    )}
                    invalid={!validateOPTargetSetting}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPTargetSetting}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.decentralizedPlanning.title',
                    )}
                    formType="number-input"
                    value={operativePlanningDecentralizedPlanning}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPDecentralizedPlanningValueChange}
                    testName="OP Dezentrale Planung Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.decentralizedPlanning.tooltip',
                    )}
                    invalid={!validateOPDecentralizedPlanning}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPDecentralizedPlanning}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.consolidationCompletionOfPlanning.title',
                    )}
                    formType="number-input"
                    value={operativePlanningConsolidationCompletionOfPlanning}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPConsolidationCompletionOfPlanningValueChange}
                    testName="OP Konsolidierung und Fertigstellung der Planung Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.consolidationCompletionOfPlanning.tooltip',
                    )}
                    invalid={!validateOPConsolidationCompletionOfPlanning}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPConsolidationCompletionOfPlanning}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.operativePlanning.planShiftApproval.title')}
                    formType="number-input"
                    value={operativePlanningPlanShiftApproval}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPPlanShiftApprovalValueChange}
                    testName="OP Planverabschiedung und Plangenehmigung Dauer in Wochen"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.planShiftApproval.tooltip',
                    )}
                    invalid={!validateOPPlanShiftApproval}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPPlanShiftApproval}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.operativePlanning.votingRounds.title')}
                    formType="number-input"
                    value={operativePlanningVotingRounds}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPVotingRoundsValueChange}
                    testName="OP Abstimmungsrunden"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.votingRounds.tooltip',
                    )}
                    invalid={!validateOPVotingRounds}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPVotingRounds}</Text>
                  </Box>
                  {windowWidth < 768 ? (
                    <FormProperty
                      label={t(
                        'controlling.benchmarking.operativePlanning.planningAlignment.title',
                      )}
                      formType="select"
                      value={convertValueToPlanningAlignmentType(
                        operativePlanningPlanningAlignment,
                      )}
                      disabled={benchmarkingControlling.submitDate !== null}
                      onValueChange={setOperativePlanningPlanningAlignment}
                      options={[
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.topDown',
                        ),
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.bottomUp',
                        ),
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.mixed',
                        ),
                      ]}
                      testName="OP Ausrichtung"
                      tooltipText={t(
                        'controlling.benchmarking.operativePlanning.planningAlignment.tooltip',
                      )}
                    />
                  ) : (
                    <SliderPropery
                      label={t(
                        'controlling.benchmarking.operativePlanning.planningAlignment.title',
                      )}
                      marksPositions={['-25', '-7', '-25']}
                      value={convertValueToPlanningAlignmentType(
                        operativePlanningPlanningAlignment,
                      )}
                      disabled={benchmarkingControlling.submitDate !== null}
                      onValueChange={setOperativePlanningPlanningAlignment}
                      marks={[
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.topDown',
                        ),
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.bottomUp',
                        ),
                        t(
                          'controlling.benchmarking.operativePlanning.planningAlignment.options.mixed',
                        ),
                      ]}
                      testName="OP Ausrichtung"
                      tooltipText={t(
                        'controlling.benchmarking.operativePlanning.planningAlignment.tooltip',
                      )}
                    />
                  )}
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.planningHorizonMonths.title',
                    )}
                    formType="number-input"
                    value={operativePlanningHorizonMonths}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleOPPlanningHorizonMonthsValueChange}
                    testName="OP Planungshorizont"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.planningHorizonMonths.tooltip',
                    )}
                    invalid={!validateOPPlanningHorizonMonths}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgOPPlanningHorizonMonths}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.operativePlanning.typeOperativePlanning.title',
                    )}
                    formType="select"
                    value={convertValueToTypeOperativePlanningType(
                      operativePlanningTypeOperativePlanning,
                    )}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setOperativePlanningTypeOperativePlanning}
                    options={[
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.mediumTermPlanning',
                      ),
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanning',
                      ),
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningSeasonalization',
                      ),
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningRollingForecasts',
                      ),
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningYtef',
                      ),
                      t(
                        'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.rollingPlanning',
                      ),
                    ]}
                    testName="OP Art der Planung"
                    tooltipText={t(
                      'controlling.benchmarking.operativePlanning.typeOperativePlanning.tooltip',
                    )}
                  />
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>

        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.forecast.title')}
                  tooltipText={t('controlling.benchmarking.forecast.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={forecastFTE}
                    onValueChange={(val) => handleFteValueChange(val, forecastFTE, setForecastFTE)}
                    testName="Forecast FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={forecastCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, forecastCosts, setForecastCosts);
                    }}
                    testName="Forecast Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.typeForecast.title')}
                    tooltipText={t('controlling.benchmarking.forecast.typeForecast.tooltip')}
                    formType="selectNameValue"
                    value={forecastTypeForecast}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setForecastTypeForecast}
                    options={typeForecastOptions}
                    testName="Art Forecast"
                  />
                  {/*
                   * Temporarly removed due to customer request
                   */}
                  {/* <FormProperty
                    label={t('controlling.benchmarking.forecast.levelDetailQuarter.title')}
                    tooltipText={t('controlling.benchmarking.forecast.levelDetailQuarter.tooltip')}
                    formType="number-input"
                    value={forecastLevelDetailQuarter}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastLevelDetailQuarterValueChange}
                    testName="Quartals-Forecast"
                    invalid={!validateForecastLevelDetailQuarter}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastLevelDetailQuarter}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.levelDetailMonth.title')}
                    tooltipText={t('controlling.benchmarking.forecast.levelDetailMonth.tooltip')}
                    formType="number-input"
                    value={forecastLevelDetailMonth}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastLevelDetailMonthValueChange}
                    testName="Monats-Forecast"
                    invalid={!validateForecastLevelDetailMonth}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastLevelDetailMonth}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.levelDetailOthers.title')}
                    tooltipText={t('controlling.benchmarking.forecast.levelDetailOthers.tooltip')}
                    formType="number-input"
                    value={forecastLevelDetailOthers}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastLevelDetailOthersValueChange}
                    testName="Forecast Sonstige"
                    invalid={!validateForecastLevelDetailOthers}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastLevelDetailOthers}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.levelDetailWeek.title'))}
                    tooltipText={t('controlling.benchmarking.forecast.levelDetailWeek.tooltip')}
                    formType="number-input"
                    value={forecastLevelDetailWeek}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastLevelDetailWeekValueChange}
                    testName="Wochen-Forecast"
                    invalid={!validateForecastLevelDetailOthers}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastLevelDetailWeek}</Text>
                  </Box> */}
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.accuracy.title')}
                    tooltipText={t('controlling.benchmarking.forecast.accuracy.tooltip')}
                    formType="number-input"
                    value={forecastAccuracy}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastAccuracyValueChange}
                    testName="Genauigkeit Forecast"
                    invalid={!validateForecastAccuracy}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastAccuracy}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.forecast.forecastHorizonMonths.title')}
                    tooltipText={t(
                      'controlling.benchmarking.forecast.forecastHorizonMonths.tooltip',
                    )}
                    formType="number-input"
                    value={forecastForecastHorizonMonths}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleForecastForecastHorizonMonthsValueChange}
                    testName="Prognosehorizont Forecast"
                    invalid={!validateForecastForecastHorizonMonths}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgForecastForecastHorizonMonths}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.cpra.title')}
                  tooltipText={t('controlling.benchmarking.cpra.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={cpraFTE}
                    onValueChange={(val) => handleFteValueChange(val, cpraFTE, setCpraFTE)}
                    testName="KLE FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={cpraCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, cpraCosts, setCpraCosts);
                      setCpraCosts(val);
                    }}
                    testName="KLE Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.cpra.varianceCtrAcc.title')}
                    tooltipText={t('controlling.benchmarking.cpra.varianceCtrAcc.tooltip')}
                    formType="number-input"
                    value={cpraVarianceCtrAcc}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleCpraVarianceCtrAccValueChange}
                    testName="Ergebnisabweichung Controlling vs. Accounting"
                    invalid={!validateCpraVarianceCtrAcc}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCpraVarianceCtrAcc}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.cpra.forecastQuality.title')}
                    tooltipText={t('controlling.benchmarking.cpra.forecastQuality.tooltip')}
                    formType="number-input"
                    value={cpraForecastQuality}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleCpraForecastQualityValueChange}
                    testName="Prognosequalität"
                    invalid={!validateCpraForecastQuality}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCpraForecastQuality}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.cpra.planQuality.title')}
                    tooltipText={t('controlling.benchmarking.cpra.planQuality.tooltip')}
                    formType="number-input"
                    value={cpraPlanQuality}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleCpraPlanQualityValueChange}
                    testName="Planqualität"
                    invalid={!validateCpraPlanQuality}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCpraPlanQuality}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.cpra.deviation.title')}
                    tooltipText={t('controlling.benchmarking.cpra.deviation.tooltip')}
                    formType="number-input"
                    value={cpraDeviation}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleCpraDeviationValueChange}
                    testName="Abweichungen Kosten der Leistungserbringung"
                    invalid={!validateCpraDeviation}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCpraDeviation}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.mgmtReporting.title')}
                  tooltipText={t('controlling.benchmarking.mgmtReporting.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={mgmtReportingFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, mgmtReportingFTE, setMgmtReportingFTE)
                    }
                    testName="Management Reporting FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={mgmtReportingCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, mgmtReportingCosts, setMgmtReportingCosts);
                    }}
                    testName="Management Reporting Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.amountReportsExecutiveBoard.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.amountReportsExecutiveBoard.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAmountReportsExecutiveBoard}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAmountReportsExecutiveBoardValueChange}
                    testName="Management Reporting Berichte Vorstand"
                    invalid={!validateMgmtReportingAmountReportsExecutiveBoard}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingAmountReportsExecutiveBoard}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.amountReportsSupervisoryBoard.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.amountReportsSupervisoryBoard.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAmountReportsSupervisoryBoard}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAmountReportsSupervisoryBoardValueChange}
                    testName="Management Reporting Berichte Aufsichtsrat"
                    invalid={!validateMgmtReportingAmountReportsSupervisoryBoard}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingAmountReportsSupervisoryBoard}</Text>
                  </Box>
                  <Divider mb={1} />
                  <Flex sx={{ pointerEvents: 'all' }}>
                    <Heading as="h3" size="sm">
                      {t('controlling.benchmarking.mgmtReporting.compositionOfReports.title')}
                    </Heading>
                    <Tooltip
                      bg="horvath.blue-light"
                      p={3}
                      hasArrow="true"
                      placement="right"
                      label={t(
                        'controlling.benchmarking.mgmtReporting.compositionOfReports.tooltip',
                      )}
                    >
                      <QuestionIcon color="horvath.blue-light" ml={1} />
                    </Tooltip>
                  </Flex>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.adhocReports.title')}
                    tooltipText={t('controlling.benchmarking.mgmtReporting.adhocReports.tooltip')}
                    formType="number-input"
                    value={mgmtReportingAdHocReports}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAdHocReportsValueChange}
                    testName="Management Reporting Adhoc-Berichte"
                    invalid={!validateMgmtReportingAdHocReports}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingAdHocReports}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.standardizedReports.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.standardizedReports.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingStandardizedReports}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRStandardizedReportsValueChange}
                    testName="Management Reporting standardisierte Berichte"
                    invalid={!validateMgmtReportingStandardizedReports}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingStandardizedReports}</Text>
                  </Box>
                  <Divider mb={1} />
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.kpisAmount.title')}
                    tooltipText={t('controlling.benchmarking.mgmtReporting.kpisAmount.tooltip')}
                    formType="number-input"
                    value={mgmtReportingKpisAmount}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRKpisAmountValueChange}
                    testName="Management Reporting KPIs"
                    invalid={!validateMgmtReportingKpisAmount}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingKpisAmount}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.reportingDimensions.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.reportingDimensions.tooltip',
                    )}
                    formType="selectNameValue"
                    value={mgmtReportingReportingDimensions}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setMgmtReportingReportingDimensions}
                    options={reportingDimensionsOptions}
                    testName="Management Reporting Dimensionen"
                  />
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialDataManagement.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialDataManagement.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAutomationPotentialDataManagement}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAutomationPotentialDataManagementValueChange}
                    testName="Management Reporting Data Management"
                    invalid={!validateMgmtReportingAutomationPotentialDataManagement}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgMgmtReportingAutomationPotentialDataManagement}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialReportGeneration.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialReportGeneration.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAutomationPotentialReportGeneration}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAutomationPotentialReportGenerationValueChange}
                    testName="Management Reporting Berichtserstellung"
                    invalid={!validateMgmtReportingAutomationPotentialReportGeneration}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgMgmtReportingAutomationPotentialReportGeneration}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialAnalysis.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialAnalysis.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAutomationPotentialAnalysis}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAutomationPotentialAnalysisValueChange}
                    testName="Management Reporting Analyse"
                    invalid={!validateMgmtReportingAutomationPotentialAnalysis}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingAutomationPotentialAnalysis}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialDecisionSupport.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.automationPotentialDecisionSupport.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingAutomationPotentialDecisionSupport}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRAutomationPotentialDecisionSupportValueChange}
                    testName="Management Reporting Entscheidungsunterstützung"
                    invalid={!validateMgmtReportingAutomationPotentialDecisionSupport}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgMgmtReportingAutomationPotentialDecisionSupport}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.integrationAiMl.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.integrationAiMl.tooltip',
                    )}
                    formType="selectNameValue"
                    value={mgmtReportingIntegrationAiMl}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setMgmtReportingIntegrationAiMl}
                    options={integrationAiMlOptions}
                    testName="Management Reporting Integration KI/ML"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.integrationComment.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.integrationComment.tooltip',
                    )}
                    formType="selectNameValue"
                    value={mgmtReportingIntegrationComment}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setMgmtReportingIntegrationComment}
                    options={integrationCommentOptions}
                    testName="Management Reporting Integrierung Kommentar"
                  />
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.integrationSustainability.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.integrationSustainability.tooltip',
                    )}
                    formType="selectNameValue"
                    value={mgmtReportingIntegrationSustainability}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setMgmtReportingIntegrationSustainability}
                    options={integrationSustainabilityOptions}
                    testName="Management Reporting Integrierung Sustainability"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.formOfUsePush.title')}
                    tooltipText={t('controlling.benchmarking.mgmtReporting.formOfUsePush.tooltip')}
                    formType="number-input"
                    value={mgmtReportingFormOfUsePush}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRFormOfUsePushValueChange}
                    testName="Management Reporting Nutzungsform Push"
                    invalid={!validateMgmtReportingFormOfUsePush}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingFormOfUsePush}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.formOfUseStandard.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.formOfUseStandard.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingFormOfUseStandard}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRFormOfUseStandardValueChange}
                    testName="Management Reporting Nutzungsform Standard"
                    invalid={!validateMgmtReportingFormOfUseStandard}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingFormOfUseStandard}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.formOfUseSelfService.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.formOfUseSelfService.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingFormOfUseSelfService}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRFormOfUseSelfServiceValueChange}
                    testName="Management Reporting Nutzungsform self-Service"
                    invalid={!validateMgmtReportingFormOfUseSelfService}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingFormOfUseSelfService}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.reportShares.title')}
                    tooltipText={t('controlling.benchmarking.mgmtReporting.reportShares.tooltip')}
                    formType="number-input"
                    value={mgmtReportingReportShares}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRReportSharesValueChange}
                    testName="Management Reporting Berichte Papier"
                    invalid={!validateMgmtReportingReportShares}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingReportShares}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.degreeDigitization.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.degreeDigitization.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingDegreeDigitization}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRDegreeDigitizationValueChange}
                    testName="Management Reporting Grad Digitalisierung"
                    invalid={!validateMgmtReportingDegreeDigitization}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingDegreeDigitization}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.reportingProcess.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.reportingProcess.tooltip',
                    )}
                    formType="selectNameValue"
                    value={mgmtReportingReportingProcess}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setMgmtReportingReportingProcess}
                    options={reportingProcessOptions}
                    testName="Management Reporting Berichtserstellung"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.reportingClosing.title')}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.reportingClosing.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingReportingClosing}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRReportingClosingValueChange}
                    testName="Management Reporting Bericht Closing"
                    invalid={!validateMgmtReportingReportingClosing}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingReportingClosing}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.mgmtReporting.reportingFlash.title')}
                    tooltipText={t('controlling.benchmarking.mgmtReporting.reportingFlash.tooltip')}
                    formType="number-input"
                    value={mgmtReportingReportingFlash}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRReportingFlashValueChange}
                    testName="Management Reporting Bericht Flash"
                    invalid={!validateMgmtReportingReportingFlash}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingReportingFlash}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.reportingMonthlyUncommented.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.reportingMonthlyUncommented.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingReportingMonthlyUncommented}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRReportingMonthlyUncommentedValueChange}
                    testName="Management Reporting Monatsbericht unkommentiert"
                    invalid={!validateMgmtReportingReportingMonthlyUncommented}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingReportingMonthlyUncommented}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.mgmtReporting.reportingMonthlyCommented.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.mgmtReporting.reportingMonthlyCommented.tooltip',
                    )}
                    formType="number-input"
                    value={mgmtReportingReportingMonthlyCommented}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleMRReportingMonthlyCommentedValueChange}
                    testName="Management Reporting Monatsbericht kommentiert"
                    invalid={!validateMgmtReportingReportingMonthlyCommented}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgMgmtReportingrReportingMonthlyCommented}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.pic.title')}
                  tooltipText={t('controlling.benchmarking.pic.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={picFTE}
                    onValueChange={(val) => handleFteValueChange(val, picFTE, setPicFTE)}
                    testName="PuI-Controlling FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={picCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, picCosts, setPicCosts);
                    }}
                    testName="PuI-Controlling Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.investmentLimit.title')}
                    tooltipText={t('controlling.benchmarking.pic.investmentLimit.tooltip')}
                    formType="number-input"
                    value={picInvestmentLimit}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handlePicInvestmentLimitValueChange}
                    testName="Pic Investitionsvolumengrenze"
                    invalid={!validatePicInvestmentLimit}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgPicInvestmentLimit}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.durationRequest.title')}
                    tooltipText={t('controlling.benchmarking.pic.durationRequest.tooltip')}
                    formType="number-input"
                    value={picDurationRequest}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handlePicDurationRequestValueChange}
                    testName="Pic Dauer Investitionsantrag"
                    invalid={!validatePicDurationRequest}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgPicDurationRequest}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.statusProjectsSuccessful.title')}
                    tooltipText={t('controlling.benchmarking.pic.statusProjectsSuccessful.tooltip')}
                    formType="number-input"
                    value={picStatusProjectsSuccessful}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handlePicStatusProjectsSuccessfulValueChange}
                    testName="Pic erfolgreich abgeschlossene Projekte"
                    invalid={!validatePicStatusProjectsSuccessful}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgPicStatusProjectsSuccessful}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.statusProjectsAborted.title')}
                    tooltipText={t('controlling.benchmarking.pic.statusProjectsAborted.tooltip')}
                    formType="number-input"
                    value={picStatusProjectsAborted}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handlePicStatusProjectsAbortedValueChange}
                    testName="Pic fehlgeschlagene Projekte"
                    invalid={!validatePicStatusProjectsAborted}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgPicStatusProjectsAborted}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.statusProjectsStopped.title')}
                    tooltipText={t('controlling.benchmarking.pic.statusProjectsStopped.tooltip')}
                    formType="number-input"
                    value={picStatusProjectsStopped}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handlePicStatusProjectsStoppedValueChange}
                    testName="Pic gestoppte Projekte"
                    invalid={!validatePicStatusProjectsStopped}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgPicStatusProjectsStopped}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.pic.deviationProjectCosts.title')}
                    tooltipText={t('controlling.benchmarking.pic.deviationProjectCosts.tooltip')}
                    formType="selectNameValue"
                    value={picDeviationProjectCosts}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setPicDeviationProjectCosts}
                    options={statusProjectsOptions}
                    testName="Pic Abweichung Kosten"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.pic.deviationProjectTime.title')}
                    tooltipText={t('controlling.benchmarking.pic.deviationProjectTime.tooltip')}
                    formType="selectNameValue"
                    value={picDeviationProjectTime}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setPicDeviationProjectTime}
                    options={statusProjectsOptions}
                    testName="Pic Abweichung Zeit"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.pic.deviationProjectPerformance.title')}
                    tooltipText={t(
                      'controlling.benchmarking.pic.deviationProjectPerformance.tooltip',
                    )}
                    formType="selectNameValue"
                    value={picDeviationProjectPerformance}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setPicDeviationProjectPerformance}
                    options={statusProjectsOptions}
                    testName="Pic Abweichung Leistung"
                  />
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.riskManagement.title')}
                  tooltipText={t('controlling.benchmarking.riskManagement.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={riskManagementFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, riskManagementFTE, setRiskManagementFTE)
                    }
                    testName="Risikomanagement FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={riskManagementCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, riskManagementCosts, setRiskManagementCosts);
                    }}
                    testName="Risikomanagement Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.riskManagement.typeRiskManagement.title')}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.typeRiskManagement.tooltip',
                    )}
                    formType="selectNameValue"
                    value={riskManagementTypeRiskManagement}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setRiskManagementTypeRiskManagement}
                    options={typeRiskManagementOptions}
                    testName="Risk-Management-Controlling Typ"
                  />
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.riskManagement.responsibilityRiskManagement.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.responsibilityRiskManagement.tooltip',
                    )}
                    formType="selectNameValue"
                    value={riskManagementResponsibilityRiskManagement}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setRiskManagementResponsibilityRiskManagement}
                    options={responsibilityRiskManagementOptions}
                    testName="Risk-Management-Controlling Verankerung"
                  />
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.riskManagement.frequenceRiskManagement.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.frequenceRiskManagement.tooltip',
                    )}
                    formType="selectNameValue"
                    value={riskManagementFrequenceRiskManagement}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setRiskManagementFrequenceRiskManagement}
                    options={frequenceRiskManagementOptions}
                    testName="Risk-Management-Controlling Frequenz"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.riskManagement.controllingRelevance.title')}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.controllingRelevance.tooltip',
                    )}
                    formType="selectNameValue"
                    value={riskManagementControllingRelevance}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setRiskManagementControllingRelevance}
                    options={controllingRelevanceOptions}
                    testName="Risk-Management-Controlling Steuerungsrelevanz"
                  />
                  <FormProperty
                    label={t('controlling.benchmarking.riskManagement.measures.title')}
                    tooltipText={t('controlling.benchmarking.riskManagement.measures.tooltip')}
                    formType="selectNameValue"
                    value={riskManagementMeasures}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setRiskManagementMeasures}
                    options={measuresOptions}
                    testName="Risk-Management-Controlling Messung"
                  />
                  <Checkboxes
                    label={t('controlling.benchmarking.riskManagement.checkboxGoals.title')}
                    tooltipText={t('controlling.benchmarking.riskManagement.checkboxGoals.tooltip')}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxGoals.options.goalFulfilledRequirement',
                      )}
                      optionValue={riskManagementKpiGoalFulfilledRequirement}
                      onChange={setRiskManagementKpiGoalFulfilledRequirement}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxGoals.options.goalImprovedControllingRisks',
                      )}
                      optionValue={riskManagementKpiGoalImprovedControllingRisks}
                      onChange={setRiskManagementKpiGoalImprovedControllingRisks}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxGoals.options.goalCompanyControlling',
                      )}
                      optionValue={riskManagementKpiGoalCompanyControlling}
                      onChange={setRiskManagementKpiGoalCompanyControlling}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                  <Checkboxes
                    label={t('controlling.benchmarking.riskManagement.checkboxIntegration.title')}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.checkboxIntegration.tooltip',
                    )}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationStrategicPlanning',
                      )}
                      optionValue={riskManagementKpiIntegrationStrategicPlanning}
                      onChange={setRiskManagementKpiIntegrationStrategicPlanning}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationOperativePlanning',
                      )}
                      optionValue={riskManagementKpiIntegrationOperativePlanning}
                      onChange={setRiskManagementKpiIntegrationOperativePlanning}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationBudgeting',
                      )}
                      optionValue={riskManagementKpiIntegrationBudgeting}
                      onChange={setRiskManagementKpiIntegrationBudgeting}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationStrategicDecisionSupport',
                      )}
                      optionValue={riskManagementKpiIntegrationStrategicDecisionSupport}
                      onChange={setRiskManagementKpiIntegrationStrategicDecisionSupport}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationOperativeDecisionSupport',
                      )}
                      optionValue={riskManagementKpiIntegrationOperativeDecisionSupport}
                      onChange={setRiskManagementKpiIntegrationOperativeDecisionSupport}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationNoInterfaces',
                      )}
                      optionValue={riskManagementKpiIntegrationNoInterfaces}
                      onChange={setRiskManagementKpiIntegrationNoInterfaces}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                  <Checkboxes
                    label={t('controlling.benchmarking.riskManagement.checkboxAnalysis.title')}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.checkboxAnalysis.tooltip',
                    )}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisNoConcept',
                      )}
                      optionValue={riskManagementKpiAnalysisNoConcept}
                      onChange={setRiskManagementKpiAnalysisNoConcept}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisEquityCoverage',
                      )}
                      optionValue={riskManagementKpiAnalysisEquityCoverage}
                      onChange={setRiskManagementKpiAnalysisEquityCoverage}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisLiquidityReserve',
                      )}
                      optionValue={riskManagementKpiAnalysisLiquidityReserve}
                      onChange={setRiskManagementKpiAnalysisLiquidityReserve}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisOther',
                      )}
                      optionValue={riskManagementKpiAnalysisOther}
                      onChange={setRiskManagementKpiAnalysisOther}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                  <Checkboxes
                    label={t(
                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.tooltip',
                    )}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareRiskRecordingRating',
                      )}
                      optionValue={riskManagementKpiSoftwareRiskRecordingRating}
                      onChange={setRiskManagementKpiSoftwareRiskRecordingRating}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareReporting',
                      )}
                      optionValue={riskManagementKpiSoftwareReporting}
                      onChange={setRiskManagementKpiSoftwareReporting}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareRiskDashboards',
                      )}
                      optionValue={riskManagementKpiSoftwareRiskDashboards}
                      onChange={setRiskManagementKpiSoftwareRiskDashboards}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareSimulationModeling',
                      )}
                      optionValue={riskManagementKpiSoftwareSimulationModeling}
                      onChange={setRiskManagementKpiSoftwareSimulationModeling}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareNoSoftwareSupport',
                      )}
                      optionValue={riskManagementKpiSoftwareNoSoftwareSupport}
                      onChange={setRiskManagementKpiSoftwareNoSoftwareSupport}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                  <Checkboxes
                    label={t(
                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.tooltip',
                    )}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioCurrentRiskDev',
                      )}
                      optionValue={riskManagementKpiScenarioCurrentRiskDev}
                      onChange={setRiskManagementKpiScenarioCurrentRiskDev}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioIndividualSingleRisks',
                      )}
                      optionValue={riskManagementKpiScenarioIndividualSingleRisks}
                      onChange={setRiskManagementKpiScenarioIndividualSingleRisks}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioTopRisks',
                      )}
                      optionValue={riskManagementKpiScenarioTopRisks}
                      onChange={setRiskManagementKpiScenarioTopRisks}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioCompleteRiskPortfolio',
                      )}
                      optionValue={riskManagementKpiScenarioCompleteRiskPortfolio}
                      onChange={setRiskManagementKpiScenarioCompleteRiskPortfolio}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioStrategicDecisionOptions',
                      )}
                      optionValue={riskManagementKpiScenarioStrategicDecisionOptions}
                      onChange={setRiskManagementKpiScenarioStrategicDecisionOptions}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.ctrlCorporate.title')}
                  tooltipText={t('controlling.benchmarking.ctrlCorporate.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={ctrlCorporateFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, ctrlCorporateFTE, setCtrlCorporateFTE)
                    }
                    testName="Corporate FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={ctrlCorporateCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, ctrlCorporateCosts, setCtrlCorporateCosts);
                      setCtrlCorporateCosts(val);
                    }}
                    testName="Corporate Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.ctrlCorporate.leadershipClaim.title')}
                    tooltipText={t(
                      'controlling.benchmarking.ctrlCorporate.leadershipClaim.tooltip',
                    )}
                    formType="selectNameValue"
                    value={ctrlCorporateLeadershipClaim}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setCtrlCorporateLeadershipClaim}
                    options={leadershipClaimOptions}
                    testName="Führungsanspruch der Konzernzentrale"
                  />
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.researchDevelopment.title')}
                  tooltipText={t('controlling.benchmarking.researchDevelopment.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={researchDevelopmentFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, researchDevelopmentFTE, setResearchDevelopmentFTE)
                    }
                    testName="F&E Controlling FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={researchDevelopmentCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(
                        val,
                        researchDevelopmentCosts,
                        setResearchDevelopmentCosts,
                      );
                    }}
                    testName="F&E Controlling Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.researchDevelopment.percentageShare.title')}
                    tooltipText={t(
                      'controlling.benchmarking.researchDevelopment.percentageShare.tooltip',
                    )}
                    formType="number-input"
                    value={researchDevelopmentPercentageShare}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleRDPercentageShareValueChange}
                    testName="R&D Prozentualer Anteil"
                    invalid={!validateResearchDevelopmentPercentageShare}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgResearchDevelopmentPercentageShare}</Text>
                  </Box>
                  <FormProperty
                    label={t('controlling.benchmarking.researchDevelopment.resourcesEffort.title')}
                    tooltipText={t(
                      'controlling.benchmarking.researchDevelopment.resourcesEffort.tooltip',
                    )}
                    formType="number-input"
                    value={researchDevelopmentResourcesEffort}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleRDResourceEffortValueChange}
                    testName="R&D Resourcenaufwand"
                    invalid={!validateResearchDevelopmentResourcesEffort}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgResearchDevelopmentResourcesEffort}</Text>
                  </Box>
                  <Flex sx={{ pointerEvents: 'all' }}>
                    <Heading as="h3" size="sm">
                      {t('controlling.benchmarking.researchDevelopment.compositionPortfolio.title')}
                    </Heading>
                    <Tooltip
                      bg="horvath.blue-light"
                      p={3}
                      hasArrow="true"
                      placement="right"
                      label={t(
                        'controlling.benchmarking.researchDevelopment.compositionPortfolio.tooltip',
                      )}
                    >
                      <QuestionIcon color="horvath.blue-light" ml={1} />
                    </Tooltip>
                  </Flex>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioBasicResearch.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioBasicResearch.tooltip',
                    )}
                    formType="number-input"
                    value={researchDevelopmentCompositionPortfolioBasicResearch}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleRDCompositionPortfolioBasicResearchValueChange}
                    testName="R&D Zusammensetzung Portfolio Grundlagenforschung"
                    invalid={!validateResearchDevelopmentCompositionPortfolioBasicResearch}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgResearchDevelopmentCompositionPortfolioBasicResearch}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioNewProductDev.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioNewProductDev.tooltip',
                    )}
                    formType="number-input"
                    value={researchDevelopmentCompositionPortfolioNewProductDev}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleRDCompositionPortfolioNewProductDevValueChange}
                    testName="R&D Zusammensetzung Portfolio Neuproduktentwicklung"
                    invalid={!validateResearchDevelopmentCompositionPortfolioNewProductDev}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgResearchDevelopmentCompositionPortfolioNewProductDev}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioFurtherDev.title',
                    )}
                    tooltipText={t(
                      'controlling.benchmarking.researchDevelopment.compositionPortfolioFurtherDev.tooltip',
                    )}
                    formType="number-input"
                    value={researchDevelopmentCompositionPortfolioFurtherDev}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleRDCompositionPortfolioFurtherDevValueChange}
                    testName="R&D Zusammensetzung Portfolio Weiterentwicklung"
                    invalid={!validateResearchDevelopmentCompositionPortfolioFurtherDev}
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgResearchDevelopmentCompositionPortfolioFurtherDev}
                    </Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.production.title')}
                  tooltipText={t('controlling.benchmarking.production.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={productionFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, productionFTE, setProductionFTE)
                    }
                    testName="Produktionscontrolling FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={productionCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, productionCosts, setProductionCosts);
                      setProductionCosts(val);
                    }}
                    testName="Produktionscontrolling Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <Checkboxes
                    label={t('controlling.benchmarking.production.checkboxKeyData.title')}
                    tooltipText={t('controlling.benchmarking.production.checkboxKeyData.tooltip')}
                  >
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataDeliveryPunctuality',
                      )}
                      optionValue={productionKeyDataDeliveryPunctuality}
                      onChange={setProductionKeyDataDeliveryPunctuality}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataIdleTimeMachine',
                      )}
                      optionValue={productionKeyDataIdleTimeMachine}
                      onChange={setProductionKeyDataIdleTimeMachine}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataIdleTimeMachine',
                      )}
                      optionValue={productionKeyDataPlantUtilizationRate}
                      onChange={setProductionKeyDataPlantUtilizationRate}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataUtilizationRate',
                      )}
                      optionValue={productionKeyDataUtilizationRate}
                      onChange={setProductionKeyDataUtilizationRate}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataManufacturingQuality',
                      )}
                      optionValue={productionKeyDataManufacturingQuality}
                      onChange={setProductionKeyDataManufacturingQuality}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataPostProcessingRate',
                      )}
                      optionValue={productionKeyDataPostProcessingRate}
                      onChange={setProductionKeyDataPostProcessingRate}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataScrapRate',
                      )}
                      optionValue={productionKeyDataScrapRate}
                      onChange={setProductionKeyDataScrapRate}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataDamageFrequency',
                      )}
                      optionValue={productionKeyDataDamageFrequency}
                      onChange={setProductionKeyDataDamageFrequency}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataStockBearingManufactory',
                      )}
                      optionValue={productionKeyDataStockBearingManufactory}
                      onChange={setProductionKeyDataStockBearingManufactory}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataSetupTimes',
                      )}
                      optionValue={productionKeyDataSetupTimes}
                      onChange={setProductionKeyDataSetupTimes}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                    <MultipleChoiceOption
                      optionName={t(
                        'controlling.benchmarking.production.checkboxKeyData.options.keyDataEmployment',
                      )}
                      optionValue={productionKeyDataEmployment}
                      onChange={setProductionKeyDataEmployment}
                      disabled={benchmarkingControlling.submitDate !== null}
                    />
                  </Checkboxes>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.sales.title')}
                  tooltipText={t('controlling.benchmarking.sales.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={salesFTE}
                    onValueChange={(val) => handleFteValueChange(val, salesFTE, setSalesFTE)}
                    testName="Vertriebscontrolling FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={salesCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, salesCosts, setSalesCosts);
                    }}
                    testName="Vertriebscontrolling Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.sales.percentageShare.title')}
                    tooltipText={t('controlling.benchmarking.sales.percentageShare.tooltip')}
                    formType="number-input"
                    value={salesPercentageShare}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={handleSalesPercentageShareValueChange}
                    testName="Sales Anteil Vertriebskosten"
                    invalid={!validateSalesPercentageShare}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgSalesPercentageShare}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('controlling.benchmarking.itControlling.title')}
                  tooltipText={t('controlling.benchmarking.itControlling.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={itFTE}
                    onValueChange={(val) => handleFteValueChange(val, itFTE, setItFTE)}
                    testName="IT FTE"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={itCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, itCosts, setItCosts);
                      setItCosts(val);
                    }}
                    testName="IT Gesamtkosten"
                    disabled={benchmarkingControlling.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('controlling.benchmarking.itControlling.structureIt.title')}
                    tooltipText={t('controlling.benchmarking.itControlling.structureIt.tooltip')}
                    formType="selectNameValue"
                    value={itStructureIt}
                    disabled={benchmarkingControlling.submitDate !== null}
                    onValueChange={setItStructureIt}
                    options={itStructureItOptions}
                    testName="Organisationsstruktur IT"
                  />
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>

        <BenchmarkingInputCard
          titleText={t('controlling.benchmarking.participation.title')}
          tooltipText={t('controlling.benchmarking.participation.tooltip')}
          fteState={participationFTE}
          setFteState={(val) => handleFteValueChange(val, participationFTE, setParticipationFTE)}
          fteTestName="Participation Controlling FTE"
          fteDisabled={benchmarkingControlling.submitDate !== null}
          costState={participationCosts}
          setCostState={(val) =>
            handleCostValueChange(val, participationCosts, setParticipationCosts)
          }
          costTestName="Participation Controlling Gesamtkosten"
          costDisabled={benchmarkingControlling.submitDate !== null}
        />

        <BenchmarkingInputCard
          titleText={t('controlling.benchmarking.ctrlBusinessConsultingManagement.title')}
          tooltipText={t('controlling.benchmarking.ctrlBusinessConsultingManagement.tooltip')}
          fteState={ctrlBusinessConsultingManagementFTE}
          setFteState={(val) =>
            handleFteValueChange(
              val,
              ctrlBusinessConsultingManagementFTE,
              setCtrlBusinessConsultingManagementFTE,
            )
          }
          fteTestName="Business Consulting Management FTE"
          fteDisabled={benchmarkingControlling.submitDate !== null}
          costState={ctrlBusinessConsultingManagementCosts}
          setCostState={(val) =>
            handleCostValueChange(
              val,
              ctrlBusinessConsultingManagementCosts,
              setCtrlBusinessConsultingManagementCosts,
            )
          }
          costTestName="Business Consulting Management Gesamtkosten"
          costDisabled={benchmarkingControlling.submitDate !== null}
        />
        <BenchmarkingInputCard
          titleText={t('controlling.benchmarking.ctrlFurtherDevelopmentControlling.title')}
          tooltipText={t('controlling.benchmarking.ctrlFurtherDevelopmentControlling.tooltip')}
          fteState={ctrlFurtherDevelopmentControllingFTE}
          setFteState={(val) =>
            handleFteValueChange(
              val,
              ctrlFurtherDevelopmentControllingFTE,
              setCtrlFurtherDevelopmentControllingFTE,
            )
          }
          fteTestName="Further Development Controlling FTE"
          fteDisabled={benchmarkingControlling.submitDate !== null}
          costState={ctrlFurtherDevelopmentControllingCosts}
          setCostState={(val) =>
            handleCostValueChange(
              val,
              ctrlFurtherDevelopmentControllingCosts,
              setCtrlFurtherDevelopmentControllingCosts,
            )
          }
          costTestName="Further Development Controlling Gesamtkosten"
          costDisabled={benchmarkingControlling.submitDate !== null}
        />
      </Accordion>
    </Card>
  );

  /**
   * Renders the Benchmarkings
   */
  const renderControllingKPIs = () => {
    if (benchmarkingStatus === 'loading' || companyStatus === 'loading') {
      return (
        <>
          <Center mt={8}>
            <Spinner />
          </Center>
          <Center>
            <Text>{t('controlling.benchmarking.loading')}</Text>
          </Center>
        </>
      );
    }
    if (benchmarkingStatus === 'succeeded' && companyStatus === 'succeeded') {
      return (
        <>
          <PageHeader title={`Benchmarking Controlling ${benchmarkingControlling.year}`} />
          <p>{`${t('controlling.benchmarking.info')} ${benchmarkingControlling.year}.`}</p>
          <ContentWrapper>
            <SliderPropery
              label=""
              marks={[
                t('controlling.benchmarking.sliderOptions.singleProcesses'),
                t('controlling.benchmarking.sliderOptions.totalProcess'),
              ]}
              marksPositions={['-50', '-50']}
              marksWidth={100}
              value={currentProcessGranularity}
              onValueChange={setCurrentProcessGranularity}
              testName="Gesamtprozess vs Einzelprozess"
            />
            {currentProcessGranularity ===
            t('controlling.benchmarking.sliderOptions.singleProcesses') ? (
              <>
                <Disable>{overallProcess()}</Disable>
                <br />
                {singleProcesses(false)}
              </>
            ) : (
              <>
                {overallProcess()}
                <br />
                <Disable>{singleProcesses(true)}</Disable>
              </>
            )}
            <Center p={3}>
              <Button
                color="primary"
                text={t('controlling.benchmarking.saveButtonText')}
                disabled={benchmarkingControlling.submitDate !== null}
                action={() => {
                  saveBenchmarkingControllingData();
                }}
                testId="Save"
              />
            </Center>
          </ContentWrapper>
        </>
      );
    }
    if (benchmarkingStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingError}
          onRefresh={fetchData}
          maxW="7xl"
          mt={8}
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    return null;
  };

  return (
    <div>
      {renderControllingKPIs()}
      <Dialog title="Warnung!" type="warning" isOpen={isAlertShown} onClose={onAlertClose}>
        <Box p={3}>{t('controlling.benchmarking.changeInfoDialogText')}</Box>
      </Dialog>
    </div>
  );
};

export default ControllingBenchmarking;
