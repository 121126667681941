/*
 * Renders the landing page view
 */
import { Box, Center, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Clickable from '../components/Clickable';

import Picture1 from '../../assets/img/Picture1.jpg';
import Picture2 from '../../assets/img/Picture2.jpg';
import Picture3 from '../../assets/img/Picture3.jpg';
import Picture4 from '../../assets/img/Picture4_dark.jpg';
import Picture5 from '../../assets/img/Picture5.jpg';

import StudyDocument from '../../assets/2023_Horvath-Studie_CFO-Study-2024-I.pdf';

const LandingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box pt={20}>
      <Center>
        <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(1, 1fr)" gap={1}>
          <GridItem colSpan={2} rowStart={0} rowEnd={1}>
            <Clickable>
              <Box onClick={() => history.push('/benchmarking')}>
                <Image src={Picture1} width="604px" height="225px" alt="Benchmarking" />
                <Center>
                  <Box position="absolute" top="46%">
                    <Text as="b" fontSize="4xl" color="white">
                      {t('landingPage.pictureTop')}
                    </Text>
                  </Box>
                </Center>
              </Box>
            </Clickable>
          </GridItem>
          <GridItem colStart={0} colEnd={1} rowStart={1} rowEnd={2}>
            <Clickable>
              <Box
                onClick={() =>
                  window.open(
                    'https://d432.keyingress.de/?i_survey=1__75ca84e1b2721fe909c5a7896d1e9bae',
                    '_blank',
                  )
                }
              >
                <Image src={Picture2} width="300px" height="150px" alt="Virtual CFO-Meeting" />
                <Center>
                  <Box position="absolute" top="67%">
                    <Text as="b" fontSize="2xl" color="white">
                      {t('landingPage.pictureMiddleLeft.first')}
                    </Text>
                    <br />
                    <Center>
                      <Text as="b" fontSize="2xl" color="white">
                        {t('landingPage.pictureMiddleLeft.second')}
                      </Text>
                    </Center>
                  </Box>
                </Center>
              </Box>
            </Clickable>
          </GridItem>
          <GridItem colStart={1} colEnd={2} rowStart={1} rowEnd={2}>
            <Clickable>
              <Box
                onClick={() =>
                  window.open(
                    'https://www.horvath-partners.com/de/veranstaltungen/horvath-cfo-panel-meeting-2022',
                    '_blank',
                  )
                }
              >
                <Image src={Picture3} width="300px" height="150px" alt="Physical CFO-Meeting" />
                <Center>
                  <Box position="absolute" top="67%">
                    <Text as="b" fontSize="2xl" color="white">
                      {t('landingPage.pictureMiddleRight.first')}
                    </Text>
                    <br />
                    <Center>
                      <Text as="b" fontSize="2xl" color="white">
                        {t('landingPage.pictureMiddleRight.second')}
                      </Text>
                    </Center>
                  </Box>
                </Center>
              </Box>
            </Clickable>
          </GridItem>
          <GridItem colStart={0} colEnd={1} rowStart={2} rowEnd={3}>
            <Clickable>
              <Box
                onClick={() =>
                  window.open(
                    'https://d432.keyingress.de/?i_survey=2__70ccd1e7aadc0cb7ee8230bef6104905',
                    '_blank',
                  )
                }
              >
                <Image
                  src={Picture4}
                  width="300px"
                  height="150px"
                  alt="Digital Maturity Assessment"
                />
                <Center>
                  <Box position="absolute" top="89%">
                    <Text as="b" fontSize="2xl" color="white">
                      {t('landingPage.pictureBottomLeft.first')}
                    </Text>
                    <br />
                    <Center>
                      <Text as="b" fontSize="2xl" color="white">
                        {t('landingPage.pictureBottomLeft.second')}
                      </Text>
                    </Center>
                  </Box>
                </Center>
              </Box>
            </Clickable>
          </GridItem>
          <GridItem colStart={1} colEnd={2} rowStart={2} rowEnd={3}>
            <Clickable>
              <Box onClick={() => window.open(StudyDocument, '_blank')}>
                <Image src={Picture5} width="300px" height="150px" alt="CFO Study" />
                <Center>
                  <Box position="absolute" top="94%">
                    <Text as="b" fontSize="2xl" color="white">
                      {t('landingPage.pictureBottomRight')}
                    </Text>
                  </Box>
                </Center>
              </Box>
            </Clickable>
          </GridItem>
        </Grid>
      </Center>
    </Box>
  );
};

export default LandingPage;
