/*
 * Renders the ControllingBenchmarking view
 */
import * as React from 'react';
import {
  Stack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Divider,
  Center,
  Spinner,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import ContentWrapper from '../components/ContentWrapper';
import PageHeader from '../components/PageHeader';
import ErrorStat from '../components/ErrorStat';
import { fetchCompany, selectCompanyName, selectCompanyStatus } from '../slices/companySlice';
import { selectBenchmarkingCompanyInfo } from '../slices/benchmarkingCompanyInfoSlice';

import QuartileChart from '../components/charts/QuartileChart';
import SelectionChart from '../components/charts/SelectionChart';
import {
  fetchBenchmarkingControllingEvaluation,
  selectBenchmarkingControllingEvaluationData,
  selectBenchmarkingControllingEvaluationError,
  selectBenchmarkingControllingEvaluationStatus,
} from '../slices/benchmarkingControllingEvaluationSlice';
import {
  fetchBenchmarkingForEvaluation,
  selectBenchmarkingForEvaluationData,
} from '../slices/benchmarkingForEvaluationSlice';

/**
 * Displays cards for process and single processes with input fields for different controlling KPIs
 * @returns Cards with input fields for different controlling KPIs of the process and single processes
 */
const ControllingEvaluation = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const toast = useToast();
  const dispatch = useDispatch();
  const benchmarkingControlling = useSelector(selectBenchmarkingForEvaluationData);
  const companyName = useSelector(selectCompanyName);
  const benchmarkingControllingEvaluation = useSelector(
    selectBenchmarkingControllingEvaluationData,
  );
  const benchmarkingCompanyInfo = useSelector(selectBenchmarkingCompanyInfo);

  const companyStatus = useSelector(selectCompanyStatus);

  const benchmarkingControllingStatus = useSelector(selectBenchmarkingControllingEvaluationStatus);
  const benchmarkingControllingError = useSelector(selectBenchmarkingControllingEvaluationError);

  const benchmarkingControllingEvaluationStatus = useSelector(
    selectBenchmarkingControllingEvaluationStatus,
  );
  const benchmarkingControllingEvaluationError = useSelector(
    selectBenchmarkingControllingEvaluationError,
  );

  /**
   * Fetches the company data via Reducer function
   */
  const fetchCompanyData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .catch(() => {
        toast({
          title: t('errors.benchmarking.controlling.fetchCompanyDataFailed.title'),
          description: t('errors.benchmarking.controlling.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetches the benchmarking data via Reducer function
   */
  const fetchBenchmarkingData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(fetchBenchmarkingForEvaluation({ companyId: company.id, benchmarkingId: id }))
          .then(unwrapResult)
          .catch(() => {
            toast({
              title: t('errors.evaluation.controlling.fetchBenchmarkingDataFailed.title'),
              description: t(
                'errors.evaluation.controlling.fetchBenchmarkingDataFailed.description',
              ),
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: t('errors.evaluation.controlling.fetchCompanyDataFailed.title'),
          description: t('errors.evaluation.controlling.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetches the evaluation data via Reducer function
   */
  const fetchBenchmarkingEvaluationData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(
          fetchBenchmarkingControllingEvaluation({ companyId: company.id, benchmarkingId: id }),
        )
          .then(unwrapResult)
          .catch(() => {
            toast({
              title: t('errors.evaluation.controlling.fetchBenchmarkingEvaluationDataFailed.title'),
              description: t(
                'errors.evaluation.controlling.fetchBenchmarkingEvaluationDataFailed.description',
              ),
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: t('errors.evaluation.controlling.fetchCompanyDataFailed.title'),
          description: t('errors.evaluation.controlling.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetch company data
   */
  React.useEffect(() => {
    // Only fetch company data if the status of the reducer is 'idle'
    if (companyStatus === 'idle') {
      fetchCompanyData();
    }
  }, [companyStatus, dispatch]);

  /**
   * Fetch benchmarking data
   */
  React.useEffect(() => {
    if (benchmarkingControllingStatus === 'idle') {
      fetchBenchmarkingData();
    }
  }, [benchmarkingControllingStatus, dispatch]);

  /**
   * Fetch benchmarking evaluation data
   */
  React.useEffect(() => {
    // Only fetch controlling benchmarking evaluation data if the status of the reducer is 'idle'
    if (benchmarkingControllingEvaluationStatus === 'idle') {
      fetchBenchmarkingEvaluationData();
    }
  }, [benchmarkingControllingStatus, dispatch]);

  /**
   * Checks if not all kpis of strategic planning are null
   */
  const checkStrategicPlanningKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlStrategicPlanning.planningHorizonMonths !== null ||
      benchmarkingControlling.ctrlStrategicPlanning.durationWeeks !== null ||
      benchmarkingControlling.ctrlStrategicPlanning.votingRounds !== null ||
      benchmarkingControlling.ctrlStrategicPlanning.numberParticipants !== null
    );
  };

  /**
   * Checks if not all values of strategic planning are null
   */
  const checkStrategicPlanningNotNull = () => {
    return (
      benchmarkingControlling.ctrlStrategicPlanning !== null &&
      (benchmarkingControlling.ctrlStrategicPlanning.fte !== null ||
        benchmarkingControlling.ctrlStrategicPlanning.cost !== null ||
        checkStrategicPlanningKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of operative planning are null
   */
  const checkOperativePlanningKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlOperativePlanning.durationWeeks !== null ||
      benchmarkingControlling.ctrlOperativePlanning.planningPreparation !== null ||
      benchmarkingControlling.ctrlOperativePlanning.targetSetting !== null ||
      benchmarkingControlling.ctrlOperativePlanning.decentralizedPlanning !== null ||
      benchmarkingControlling.ctrlOperativePlanning.consolidationCompletionOfPlanning !== null ||
      benchmarkingControlling.ctrlOperativePlanning.planShiftApproval !== null ||
      benchmarkingControlling.ctrlOperativePlanning.votingRounds !== null ||
      benchmarkingControlling.ctrlOperativePlanning.planningAlignment !== null ||
      benchmarkingControlling.ctrlOperativePlanning.planningHorizonMonths !== null ||
      benchmarkingControlling.ctrlOperativePlanning.typeOperativePlanning !== null
    );
  };

  /**
   * Checks if not all values of operative planning are null
   */
  const checkOperativePlanningNotNull = () => {
    return (
      benchmarkingControlling.ctrlOperativePlanning !== null &&
      (benchmarkingControlling.ctrlOperativePlanning.fte !== null ||
        benchmarkingControlling.ctrlOperativePlanning.cost !== null ||
        checkOperativePlanningKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of forecast are null
   */
  const checkForecastKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlForecast.typeForecast !== null ||
      benchmarkingControlling.ctrlForecast.levelDetailQuarter !== null ||
      benchmarkingControlling.ctrlForecast.levelDetailMonth !== null ||
      benchmarkingControlling.ctrlForecast.levelDetailWeek !== null ||
      benchmarkingControlling.ctrlForecast.levelDetailOthers !== null ||
      benchmarkingControlling.ctrlForecast.accuracy !== null ||
      benchmarkingControlling.ctrlForecast.forecastHorizonMonths !== null
    );
  };

  /**
   * Checks if not all values of forecast are null
   */
  const checkForecastNotNull = () => {
    return (
      benchmarkingControlling.ctrlForecast !== null &&
      (benchmarkingControlling.ctrlForecast.fte !== null ||
        benchmarkingControlling.ctrlForecast.cost !== null ||
        checkForecastKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of cpra are null
   */
  const checkCpraKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlCpra.varianceCtrAcc !== null ||
      benchmarkingControlling.ctrlCpra.forecastQuality !== null ||
      benchmarkingControlling.ctrlCpra.planQuality !== null ||
      benchmarkingControlling.ctrlCpra.deviation !== null
    );
  };

  /**
   * Checks if not all values of cpra are null
   */
  const checkCpraNotNull = () => {
    return (
      benchmarkingControlling.ctrlCpra !== null &&
      (benchmarkingControlling.ctrlCpra.fte !== null ||
        benchmarkingControlling.ctrlCpra.cost !== null ||
        checkCpraKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of management reporting are null
   */
  const checkManagementReportingKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlManagementReporting.amountReportsExecutiveBoard !== null ||
      benchmarkingControlling.ctrlManagementReporting.amountReportsSupervisoryBoard !== null ||
      benchmarkingControlling.ctrlManagementReporting.adHocReports !== null ||
      benchmarkingControlling.ctrlManagementReporting.standardizedReports !== null ||
      benchmarkingControlling.ctrlManagementReporting.kpisAmount !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingDimensions !== null ||
      benchmarkingControlling.ctrlManagementReporting.automationPotentialDataManagement !== null ||
      benchmarkingControlling.ctrlManagementReporting.automationPotentialReportGeneration !==
        null ||
      benchmarkingControlling.ctrlManagementReporting.automationPotentialAnalysis !== null ||
      benchmarkingControlling.ctrlManagementReporting.automationPotentialDecisionSupport !== null ||
      benchmarkingControlling.ctrlManagementReporting.integrationAiMl !== null ||
      benchmarkingControlling.ctrlManagementReporting.integrationComment !== null ||
      benchmarkingControlling.ctrlManagementReporting.integrationSustainability !== null ||
      benchmarkingControlling.ctrlManagementReporting.formOfUsePush !== null ||
      benchmarkingControlling.ctrlManagementReporting.formOfUseStandard !== null ||
      benchmarkingControlling.ctrlManagementReporting.formOfUseSelfService !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportShares !== null ||
      benchmarkingControlling.ctrlManagementReporting.degreeDigitization !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingProcess !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingClosing !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingFlash !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingMonthlyUncommented !== null ||
      benchmarkingControlling.ctrlManagementReporting.reportingMonthlyCommented !== null
    );
  };

  /**
   * Checks if not all values of management reporting are null
   */
  const checkManagementReportingNotNull = () => {
    return (
      benchmarkingControlling.ctrlManagementReporting !== null &&
      (benchmarkingControlling.ctrlManagementReporting.fte !== null ||
        benchmarkingControlling.ctrlManagementReporting.cost !== null ||
        checkManagementReportingKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of pic are null
   */
  const checkPicKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlPic.investmentLimit !== null ||
      benchmarkingControlling.ctrlPic.durationRequest !== null ||
      benchmarkingControlling.ctrlPic.statusProjectsSuccessful !== null ||
      benchmarkingControlling.ctrlPic.statusProjectsAborted !== null ||
      benchmarkingControlling.ctrlPic.statusProjectsStopped !== null ||
      benchmarkingControlling.ctrlPic.deviationProjectCosts !== null ||
      benchmarkingControlling.ctrlPic.deviationProjectTime !== null ||
      benchmarkingControlling.ctrlPic.deviationProjectPerformance !== null
    );
  };

  /**
   * Checks if not all values of pic are null
   */
  const checkPicNotNull = () => {
    return (
      benchmarkingControlling.ctrlPic !== null &&
      (benchmarkingControlling.ctrlPic.fte !== null ||
        benchmarkingControlling.ctrlPic.cost !== null ||
        checkPicKPIsNotNull())
    );
  };

  /**
   * Checks if not all multiple choice selections of goal risk management are null
   */
  const checkRiskManagementKpiGoalRiskManagementNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.fulfilledRequirement !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.improvedControllingRisks !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement.companyControlling !== null
    );
  };

  /**
   * Checks if not all multiple choice selections of integration risk management are null
   */
  const checkRiskManagementKpiIntegrationRiskManagementNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.strategicPlanning !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.operativePlanning !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.budgeting !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
        .strategicDecisionSupport !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement
        .operativeDecisionSupport !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiIntegrationRiskManagement.noInterfaces !== null
    );
  };

  /**
   * Checks if not all multiple choice selections of analysis risk bearing capacity are null
   */
  const checkRiskManagementKpiAnalysisRiskBearingCapacityNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.noConcept !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.equityCoverage !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.liquidityReserve !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiAnalysisRiskBearingCapacity.other !== null
    );
  };

  /**
   * Checks if not all multiple choice selections of software support are null
   */
  const checkRiskManagementKpiSoftwareSupportNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.riskRecordingRating !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.reporting !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.riskDashboards !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.simulationModeling !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport.noSoftwareSupport !== null
    );
  };

  /**
   * Checks if not all multiple choice selections of scenario analysis options are null
   */
  const checkRiskManagementKpiScenarioAnalysisNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.currentRiskDev !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.individualSingleRisks !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.topRisks !== null ||
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.completeRiskPortfolio !==
        null ||
      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis.strategicDecisionOptions !==
        null
    );
  };

  /**
   * Checks if not all kpis of risk management are null
   */
  const checkRiskManagementKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement.typeRiskManagement !== null ||
      benchmarkingControlling.ctrlRiskManagement.responsibilityRiskManagement !== null ||
      benchmarkingControlling.ctrlRiskManagement.frequenceRiskManagement !== null ||
      benchmarkingControlling.ctrlRiskManagement.controllingRelevance !== null ||
      benchmarkingControlling.ctrlRiskManagement.measures !== null ||
      checkRiskManagementKpiGoalRiskManagementNotNull() ||
      checkRiskManagementKpiIntegrationRiskManagementNotNull() ||
      checkRiskManagementKpiAnalysisRiskBearingCapacityNotNull() ||
      checkRiskManagementKpiSoftwareSupportNotNull() ||
      checkRiskManagementKpiScenarioAnalysisNotNull()
    );
  };

  /**
   * Checks if not all values of risk management are null
   */
  const checkRiskManagementNotNull = () => {
    return (
      benchmarkingControlling.ctrlRiskManagement !== null &&
      (benchmarkingControlling.ctrlRiskManagement.fte !== null ||
        benchmarkingControlling.ctrlRiskManagement.cost !== null ||
        checkRiskManagementKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of corporate are null
   */
  const checkCorporateKPIsNotNull = () => {
    return benchmarkingControlling.ctrlCorporate.leadershipClaim !== null;
  };

  /**
   * Checks if not all values of corporate are null
   */
  const checkCorporateNotNull = () => {
    return (
      benchmarkingControlling.ctrlCorporate !== null &&
      (benchmarkingControlling.ctrlCorporate.fte !== null ||
        benchmarkingControlling.ctrlCorporate.cost !== null ||
        checkCorporateKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of research development project are null
   */
  const checkResearchDevelopmentProjectKPIsNotNull = () => {
    return (
      benchmarkingControlling.ctrlResearchDevelopment.projectBasicResearch !== null ||
      benchmarkingControlling.ctrlResearchDevelopment.projectNewProductDev !== null ||
      benchmarkingControlling.ctrlResearchDevelopment.projectFurtherDev !== null
    );
  };

  /**
   * Checks if not all kpis of research development are null
   */
  const checkResearchDevelopmentKPIsNotNull = () => {
    return (
      benchmarkingControllingEvaluation.ctrlResearchDevelopment.percentageShare !== null ||
      benchmarkingControllingEvaluation.ctrlResearchDevelopment.resourcesEffort !== null ||
      checkResearchDevelopmentProjectKPIsNotNull()
    );
  };

  /**
   * Checks if not all values of research development are null
   */
  const checkResearchDevelopmentNotNull = () => {
    return (
      benchmarkingControlling.ctrlResearchDevelopment !== null &&
      (benchmarkingControlling.ctrlResearchDevelopment.fte !== null ||
        benchmarkingControlling.ctrlResearchDevelopment.cost !== null ||
        checkResearchDevelopmentKPIsNotNull())
    );
  };

  /**
   * Checks if not all multiple choice selections of key data are null
   */
  const checkProductionKpiKeyDataNotNull = () => {
    return (
      benchmarkingControlling.ctrlProduction.kpiKeyData.deliveryPunctuality !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.idleTimeMachine !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.plantUtilizationRate !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.utilizationRate !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.manufacturingQuality !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.postProcessingRate !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.scrapRate !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.damageFrequency !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.stockBearingManufacturing !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.setupTimes !== null ||
      benchmarkingControlling.ctrlProduction.kpiKeyData.employment !== null
    );
  };

  /**
   * Checks if not all values of production are null
   */
  const checkProductionNotNull = () => {
    return (
      benchmarkingControlling.ctrlProduction !== null &&
      (benchmarkingControlling.ctrlProduction.fte !== null ||
        benchmarkingControlling.ctrlProduction.cost !== null ||
        checkProductionKpiKeyDataNotNull())
    );
  };

  /**
   * Checks if not all kpis of sales are null
   */
  const checkSalesKPIsNotNull = () => {
    return benchmarkingControlling.ctrlSales.percentageShare !== null;
  };

  /**
   * Checks if not all values of sales are null
   */
  const checkSalesNotNull = () => {
    return (
      benchmarkingControlling.ctrlSales !== null &&
      (benchmarkingControlling.ctrlSales.fte !== null ||
        benchmarkingControlling.ctrlSales.cost !== null ||
        checkSalesKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of it are null
   */
  const checkItKPIsNotNull = () => {
    return benchmarkingControlling.ctrlIt.structureIt !== null;
  };

  /**
   * Checks if not all values of it are null
   */
  const checkItNotNull = () => {
    return (
      benchmarkingControlling.ctrlIt !== null &&
      (benchmarkingControlling.ctrlIt.fte !== null ||
        benchmarkingControlling.ctrlIt.cost !== null ||
        checkItKPIsNotNull())
    );
  };

  /**
   * Checks if not all values of participation controlling are null
   */
  const checkParticipationNotNull = () => {
    return (
      benchmarkingControlling.ctrlParticipation !== null &&
      (benchmarkingControlling.ctrlParticipation.fte !== null ||
        benchmarkingControlling.ctrlParticipation.cost !== null)
    );
  };

  /**
   * Checks if not all kpis of business consulting management are null
   */
  const checkBusinessConsultingManagementNotNull = () => {
    return (
      benchmarkingControlling.ctrlBusinessConsultingManagement !== null &&
      (benchmarkingControlling.ctrlBusinessConsultingManagement.fte !== null ||
        benchmarkingControlling.ctrlBusinessConsultingManagement.cost !== null)
    );
  };

  /**
   * Checks if not all values of further development controlling are null
   */
  const checkFurtherDevelopmentControllingNotNull = () => {
    return (
      benchmarkingControlling.ctrlFurtherDevelopmentControlling !== null &&
      (benchmarkingControlling.ctrlFurtherDevelopmentControlling.fte !== null ||
        benchmarkingControlling.ctrlFurtherDevelopmentControlling.cost !== null)
    );
  };

  /**
   * Checks if not all values of the single processes are null
   */
  const checkSingleProcessesNotNull = () => {
    return (
      checkStrategicPlanningNotNull() ||
      checkOperativePlanningNotNull() ||
      checkBusinessConsultingManagementNotNull() ||
      checkCorporateNotNull() ||
      checkCpraNotNull() ||
      checkForecastNotNull() ||
      checkFurtherDevelopmentControllingNotNull() ||
      checkItNotNull() ||
      checkManagementReportingNotNull() ||
      checkPicNotNull() ||
      checkParticipationNotNull() ||
      checkProductionNotNull() ||
      checkResearchDevelopmentNotNull() ||
      checkRiskManagementNotNull() ||
      checkSalesNotNull()
    );
  };

  /**
   * Displays the controlling evaluation are after the data is fetched
   * @returns
   */
  const renderControllingEvaluation = () => {
    if (
      benchmarkingControllingStatus === 'loading' ||
      benchmarkingControllingEvaluationStatus === 'loading'
    ) {
      return (
        <>
          <Center mt={8}>
            <Spinner />
          </Center>
          <Center>
            <Text>{t('controlling.evaluation.loading')}</Text>
          </Center>
        </>
      );
    }
    if (
      benchmarkingControllingStatus === 'succeeded' &&
      benchmarkingControllingEvaluation.totalProcess.totalFte === null &&
      benchmarkingControllingEvaluation.totalProcess.totalCost === null
    ) {
      return (
        <Center>
          <Text>{t('controlling.evaluation.noDataExists')}</Text>
        </Center>
      );
    }
    if (benchmarkingControllingStatus === 'succeeded') {
      return (
        <>
          {benchmarkingControlling.sumCostControlling !== null ||
          benchmarkingControlling.sumFteControlling !== null ? (
            <Card>
              <CardHeader
                title={t('controlling.benchmarking.totalProcess.title')}
                tooltipText={t('controlling.benchmarking.totalProcess.tooltip')}
              />
              <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                {benchmarkingControlling.sumFteControlling !== null &&
                benchmarkingControlling.sumFteControlling !== 0 ? (
                  <Center>
                    <QuartileChart
                      legendTextLeft={t('controlling.evaluation.legendText.fte')}
                      companyName={companyName}
                      quartileData={benchmarkingControllingEvaluation.totalProcess.totalFte}
                      companyBenchmarkingValue={benchmarkingControlling.sumFteControlling}
                      data-testid
                    />
                  </Center>
                ) : null}
                {benchmarkingControlling.sumCostControlling !== null &&
                benchmarkingControlling.sumCostControlling !== 0 ? (
                  <Center>
                    <QuartileChart
                      legendTextLeft={t('controlling.evaluation.legendText.costs')}
                      companyName={companyName}
                      quartileData={benchmarkingControllingEvaluation.totalProcess.totalCost}
                      companyBenchmarkingValue={benchmarkingControlling.sumCostControlling}
                    />
                  </Center>
                ) : null}
              </Stack>
            </Card>
          ) : null}
          <br />
          {checkSingleProcessesNotNull() ? (
            <Card>
              <CardHeader
                title={t('controlling.benchmarking.singleProcesses.title')}
                tooltipText={t('controlling.benchmarking.singleProcesses.tooltip')}
              />
              <Accordion allowToggle>
                {checkStrategicPlanningNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkStrategicPlanningKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.strategicPlanning.title')}
                            tooltipText={t('controlling.benchmarking.strategicPlanning.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlStrategicPlanning.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning.fte
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlStrategicPlanning.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlStrategicPlanning.planningHorizonMonths !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.strategicPlanning.planningHorizonMonths.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning
                                    .planningHorizonMonths
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning
                                    .planningHorizonMonths
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlStrategicPlanning.durationWeeks !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.strategicPlanning.durationWeeks.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning
                                    .durationWeeks
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning.durationWeeks
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlStrategicPlanning.votingRounds !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.strategicPlanning.votingRounds.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning
                                    .votingRounds
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning.votingRounds
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlStrategicPlanning.numberParticipants !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.strategicPlanning.numberParticipants.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlStrategicPlanning
                                    .numberParticipants
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlStrategicPlanning.numberParticipants
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkOperativePlanningNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkOperativePlanningKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.operativePlanning.title')}
                            tooltipText={t('controlling.benchmarking.operativePlanning.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlOperativePlanning.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning.fte
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlOperativePlanning.durationWeeks !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.operativePlanningWeeks.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .durationWeeks
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.durationWeeks
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.planningPreparation !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.planningPreparation.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .planningPreparation
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.planningPreparation
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.targetSetting !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.targetSetting.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .targetSetting
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.targetSetting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.decentralizedPlanning !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.decentralizedPlanning.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .decentralizedPlanning
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning
                                    .decentralizedPlanning
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning
                            .consolidationCompletionOfPlanning !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.consolidationCompletionOfPlanning.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .consolidationCompletionOfPlanning
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning
                                    .consolidationCompletionOfPlanning
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.planShiftApproval !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.planShiftApproval.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .planShiftApproval
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.planShiftApproval
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.votingRounds !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.votingRounds.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .votingRounds
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning.votingRounds
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.planningAlignment !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.planningAlignment.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .planningAlignment
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.operativePlanning.planningAlignment.options.topDown',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.planningAlignment.options.bottomUp',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.planningAlignment.options.moxed',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlOperativePlanning.planningAlignment
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.planningHorizonMonths !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.planningHorizonMonths.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .planningHorizonMonths
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlOperativePlanning
                                    .planningHorizonMonths
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlOperativePlanning.typeOperativePlanning !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.operativePlanning.typeOperativePlanning.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlOperativePlanning
                                    .typeOperativePlanning
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.mediumTermPlanning',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanning',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningSeasonalization',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningRollingForecasts',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.annualPlanningYtef',
                                  ),
                                  t(
                                    'controlling.benchmarking.operativePlanning.typeOperativePlanning.options.rollingPlanning',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlOperativePlanning
                                    .typeOperativePlanning
                                }
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkForecastNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkForecastKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.forecast.title')}
                            tooltipText={t('controlling.benchmarking.forecast.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlForecast.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlForecast.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlForecast.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlForecast.cost}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlForecast.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlForecast.typeForecast !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.typeForecast.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlForecast.typeForecast
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.forecast.typeForecast.options.yearEndForecast',
                                  ),
                                  t(
                                    'controlling.benchmarking.forecast.typeForecast.options.rolling',
                                  ),
                                  t(
                                    'controlling.benchmarking.forecast.typeForecast.options.partRolling',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlForecast.typeForecast
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.levelDetailQuarter !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.levelDetailQuarter.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast.levelDetailQuarter
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.levelDetailQuarter
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.levelDetailMonth !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.levelDetailMonth.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast.levelDetailMonth
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.levelDetailMonth
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.levelDetailWeek !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.levelDetailWeek.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast.levelDetailWeek
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.levelDetailWeek
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.levelDetailOthers !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.levelDetailOthers.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast.levelDetailOthers
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.levelDetailOthers
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.accuracy !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.accuracy.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast.accuracy
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.accuracy
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlForecast.forecastHorizonMonths !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.forecast.forecastHorizonMonths.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlForecast
                                    .forecastHorizonMonths
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlForecast.forecastHorizonMonths
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkCpraNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkCpraKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.cpra.title')}
                            tooltipText={t('controlling.benchmarking.cpra.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlCpra.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlCpra.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlCpra.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlCpra.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlCpra.cost}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlCpra.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlCpra.varianceCtrAcc !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.cpra.varianceCtrAcc.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlCpra.varianceCtrAcc
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlCpra.varianceCtrAcc
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlCpra.forecastQuality !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.cpra.forecastQuality.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlCpra.forecastQuality
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlCpra.forecastQuality
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlCpra.planQuality !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.cpra.planQuality.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlCpra.planQuality
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlCpra.planQuality
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlCpra.deviation !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.benchmarking.cpra.deviation.title')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlCpra.deviation}
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlCpra.deviation
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkManagementReportingNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkManagementReportingKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.mgmtReporting.title')}
                            tooltipText={t('controlling.benchmarking.mgmtReporting.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlManagementReporting.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting.fte
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlManagementReporting
                            .amountReportsExecutiveBoard !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.amountReportsExecutiveBoard.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .amountReportsExecutiveBoard
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .amountReportsExecutiveBoard
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .amountReportsSupervisoryBoard !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.amountReportsSupervisoryBoard.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .amountReportsSupervisoryBoard
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .amountReportsSupervisoryBoard
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.adHocReports !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.adhocReports.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .adHocReports
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.adHocReports
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.standardizedReports !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.standardizedReports.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .standardizedReports
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .standardizedReports
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.kpisAmount !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.kpisAmount.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .kpisAmount
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.kpisAmount
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.reportingDimensions !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingDimensions.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingDimensions
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingDimensions.options.finance',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingDimensions.options.financeNot',
                                  ),

                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingDimensions.options.extern',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .reportingDimensions
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .automationPotentialDataManagement !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.automationPotentialDataManagement.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .automationPotentialDataManagement
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .automationPotentialDataManagement
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .automationPotentialReportGeneration !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.automationPotentialReportGeneration.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .automationPotentialReportGeneration
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .automationPotentialReportGeneration
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .automationPotentialAnalysis !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.automationPotentialAnalysis.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .automationPotentialAnalysis
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .automationPotentialAnalysis
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .automationPotentialDecisionSupport !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.automationPotentialDecisionSupport.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .automationPotentialDecisionSupport
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .automationPotentialDecisionSupport
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.integrationAiMl !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.integrationAiMl.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .integrationAiMl
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationAiMl.options.full',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationAiMl.options.selective',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationAiMl.options.exclusive',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlManagementReporting.integrationAiMl
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.integrationComment !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.integrationComment.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .integrationComment
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationComment.options.manualComplete',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationComment.options.manual',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationComment.options.semiAutomatedFrontend',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationComment.options.semiAutomatedBackend',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationComment.options.automatedAi',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlManagementReporting.integrationComment
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .integrationSustainability !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.integrationSustainability.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .integrationSustainability
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationSustainability.options.notExist',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationSustainability.options.separate',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationSustainability.options.oneSided',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.integrationSustainability.options.full',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .integrationSustainability
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.formOfUsePush !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.formOfUsePush.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .formOfUsePush
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.formOfUsePush
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.formOfUseStandard !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.formOfUseStandard.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .formOfUseStandard
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.formOfUseStandard
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.formOfUseSelfService !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.formOfUseSelfService.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .formOfUseSelfService
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .formOfUseSelfService
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.reportShares !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportShares.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportShares
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.reportShares
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.degreeDigitization !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.degreeDigitization.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .degreeDigitization
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.degreeDigitization
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.reportingProcess !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingProcess.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingProcess
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingProcess.options.manual',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingProcess.options.automatedDifReports',
                                  ),
                                  t(
                                    'controlling.benchmarking.mgmtReporting.reportingProcess.options.automatedIntegratedDatamodell',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlManagementReporting.reportingProcess
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.reportingClosing !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingClosing.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingClosing
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.reportingClosing
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting.reportingFlash !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingFlash.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingFlash
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting.reportingFlash
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .reportingMonthlyUncommented !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingMonthlyUncommented.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingMonthlyUncommented
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .reportingMonthlyUncommented
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlManagementReporting
                            .reportingMonthlyCommented !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.mgmtReporting.reportingMonthlyCommented.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlManagementReporting
                                    .reportingMonthlyCommented
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlManagementReporting
                                    .reportingMonthlyCommented
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkPicNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkPicKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.pic.title')}
                            tooltipText={t('controlling.benchmarking.pic.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlPic.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlPic.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlPic.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlPic.cost}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlPic.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlPic.investmentLimit !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.investmentLimit.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlPic.investmentLimit
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlPic.investmentLimit
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.durationRequest !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.durationRequest.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlPic.durationRequest
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlPic.durationRequest
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.statusProjectsSuccessful !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.statusProjectsSuccessful.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlPic.statusProjectsSuccessful
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlPic.statusProjectsSuccessful
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.statusProjectsAborted !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.statusProjectsAborted.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlPic.statusProjectsAborted
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlPic.statusProjectsAborted
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.statusProjectsStopped !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.statusProjectsStopped.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlPic.statusProjectsStopped
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlPic.statusProjectsStopped
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.deviationProjectCosts !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.deviationProjectCosts.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlPic.deviationProjectCosts
                                }
                                labels={[
                                  t('controlling.benchmarking.statusProjectOptions.bad'),
                                  t('controlling.benchmarking.statusProjectOptions.standard'),
                                  t('controlling.benchmarking.statusProjectOptions.good'),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlPic.deviationProjectCosts
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.deviationProjectTime !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.deviationProjectTime.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlPic.deviationProjectTime
                                }
                                labels={[
                                  t('controlling.benchmarking.statusProjectOptions.bad'),
                                  t('controlling.benchmarking.statusProjectOptions.standard'),
                                  t('controlling.benchmarking.statusProjectOptions.good'),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlPic.deviationProjectTime
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlPic.deviationProjectPerformance !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.pic.deviationProjectPerformance.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlPic
                                    .deviationProjectPerformance
                                }
                                labels={[
                                  t('controlling.benchmarking.statusProjectOptions.bad'),
                                  t('controlling.benchmarking.statusProjectOptions.standard'),
                                  t('controlling.benchmarking.statusProjectOptions.good'),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlPic.deviationProjectPerformance
                                }
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkRiskManagementNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkRiskManagementKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.riskManagement.title')}
                            tooltipText={t('controlling.benchmarking.riskManagement.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlRiskManagement.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement.fte
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlRiskManagement.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlRiskManagement.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlRiskManagement.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlRiskManagement.typeRiskManagement !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.riskManagement.typeRiskManagement.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement
                                    .typeRiskManagement
                                }
                                labels={['Formalisiert', 'Institutionalisiert']}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlRiskManagement.typeRiskManagement
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlRiskManagement
                            .responsibilityRiskManagement !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.riskManagement.responsibilityRiskManagement.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement
                                    .responsibilityRiskManagement
                                }
                                labels={[
                                  'Controlling',
                                  'Stabstelle Risikomanagement',
                                  'Unternehmensführung',
                                  'Interne Revision/Accounting/Treasury',
                                  'Dezentrale Mitarbeiter in den verschiedenen Geschäftseinheiten',
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlRiskManagement
                                    .responsibilityRiskManagement
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlRiskManagement.frequenceRiskManagement !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.riskManagement.frequenceRiskManagement.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement
                                    .frequenceRiskManagement
                                }
                                labels={[
                                  'Anlassbezogen/ad hoc',
                                  'Täglich',
                                  'Wöchentlich',
                                  'Monatlich',
                                  'Quartalsweise',
                                  'Halbjährig',
                                  'Jährlich',
                                  'Nie',
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlRiskManagement.frequenceRiskManagement
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlRiskManagement.controllingRelevance !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.riskManagement.controllingRelevance.title',
                                )}
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement
                                    .controllingRelevance
                                }
                                labels={[
                                  'Unabhängige Kontrollinstanz',
                                  'Unabhäniger Vermittler',
                                  'Echter Business Partner',
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlRiskManagement.controllingRelevance
                                }
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlRiskManagement.measures !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.riskManagement.measures.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlRiskManagement.measures
                                }
                                labels={[
                                  'Regelmäßig durch die zentrale Risikomanagementfunktion bei den Maßnahmenverantwortlichen hinterfragt',
                                  'Turnusmäßig im Rahmen des Risikoerfassungsprozesses durch die Risikoeigentümer bewertet',
                                  'Regelmäßig durch die Revisionsfunktion überprüft',
                                  'Kein aktives Monitoring von Gegenmaßnahmen',
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlRiskManagement.measures
                                }
                              />
                            </Center>
                          ) : null}
                          {checkRiskManagementKpiGoalRiskManagementNotNull() ? (
                            <>
                              <strong>
                                {t('controlling.benchmarking.riskManagement.checkboxGoals.title')}
                              </strong>
                              {benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
                                .fulfilledRequirement !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxGoals.options.goalFulfilledRequirement',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiGoalRiskManagement.fulfilledRequirement
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiGoalRiskManagement.fulfilledRequirement
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
                                .improvedControllingRisks !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxGoals.options.goalImprovedControllingRisks',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiGoalRiskManagement.improvedControllingRisks
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiGoalRiskManagement.improvedControllingRisks
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiGoalRiskManagement
                                .companyControlling !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxGoals.options.goalCompanyControlling',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiGoalRiskManagement.companyControlling
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiGoalRiskManagement.companyControlling
                                    }
                                  />
                                </Center>
                              ) : null}
                            </>
                          ) : null}
                          {checkRiskManagementKpiIntegrationRiskManagementNotNull() ? (
                            <>
                              <strong>
                                {t(
                                  'controlling.benchmarking.riskManagement.checkboxIntegration.title',
                                )}
                              </strong>
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.strategicPlanning !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationStrategicPlanning',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.strategicPlanning
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.strategicPlanning
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.operativePlanning !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationOperativePlanning',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.operativePlanning
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.operativePlanning
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.budgeting !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationBudgeting',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.budgeting
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.budgeting
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.strategicDecisionSupport !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationStrategicDecisionSupport',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.strategicDecisionSupport
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.strategicDecisionSupport
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.operativeDecisionSupport !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationOperativeDecisionSupport',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.operativeDecisionSupport
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.operativeDecisionSupport
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiIntegrationRiskManagement.noInterfaces !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxIntegration.options.integrationNoInterfaces',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.noInterfaces
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiIntegrationRiskManagement.noInterfaces
                                    }
                                  />
                                </Center>
                              ) : null}
                            </>
                          ) : null}
                          {checkRiskManagementKpiAnalysisRiskBearingCapacityNotNull() ? (
                            <>
                              <strong>
                                {t(
                                  'controlling.benchmarking.riskManagement.checkboxAnalysis.title',
                                )}
                              </strong>
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiAnalysisRiskBearingCapacity.noConcept !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisNoConcept',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.noConcept
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.noConcept
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiAnalysisRiskBearingCapacity.equityCoverage !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisEquityCoverage',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.equityCoverage
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.equityCoverage
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiAnalysisRiskBearingCapacity.liquidityReserve !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisLiquidityReserve',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.liquidityReserve
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.liquidityReserve
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement
                                .kpiAnalysisRiskBearingCapacity.other !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxAnalysis.options.analysisOther',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.other
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement
                                        .kpiAnalysisRiskBearingCapacity.other
                                    }
                                  />
                                </Center>
                              ) : null}
                            </>
                          ) : null}
                          {checkRiskManagementKpiSoftwareSupportNotNull() ? (
                            <>
                              <strong>
                                {t(
                                  'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.title',
                                )}
                              </strong>
                              {benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                .riskRecordingRating !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareRiskRecordingRating',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiSoftwareSupport.riskRecordingRating
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                        .riskRecordingRating
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                .reporting !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareReporting',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiSoftwareSupport.reporting
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                        .reporting
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                .riskDashboards !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareRiskDashboards',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiSoftwareSupport.riskDashboards
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                        .riskDashboards
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                .simulationModeling !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareSimulationModeling',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiSoftwareSupport.simulationModeling
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                        .simulationModeling
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                .noSoftwareSupport !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxSoftwareSupport.options.softwareNoSoftwareSupport',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiSoftwareSupport.noSoftwareSupport
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiSoftwareSupport
                                        .noSoftwareSupport
                                    }
                                  />
                                </Center>
                              ) : null}
                            </>
                          ) : null}
                          {checkRiskManagementKpiScenarioAnalysisNotNull() ? (
                            <>
                              <strong>
                                {t(
                                  'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.title',
                                )}
                              </strong>
                              {benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                .currentRiskDev !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioCurrentRiskDev',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiScenarioAnalysis.currentRiskDev
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                        .currentRiskDev
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                .individualSingleRisks !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioIndividualSingleRisks',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiScenarioAnalysis.individualSingleRisks
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                        .individualSingleRisks
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                .topRisks !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioTopRisks',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiScenarioAnalysis.topRisks
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                        .topRisks
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                .completeRiskPortfolio !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioCompleteRiskPortfolio',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiScenarioAnalysis.completeRiskPortfolio
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                        .completeRiskPortfolio
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                .strategicDecisionOptions !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.riskManagement.checkboxScenarioAnalysis.options.scenarioStrategicDecisionOptions',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlRiskManagement
                                        .kpiScenarioAnalysis.strategicDecisionOptions
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlRiskManagement.kpiScenarioAnalysis
                                        .strategicDecisionOptions
                                    }
                                  />
                                </Center>
                              ) : null}{' '}
                            </>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkCorporateNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkCorporateKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.ctrlCorporate.title')}
                            tooltipText={t('controlling.benchmarking.ctrlCorporate.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlCorporate.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlCorporate.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlCorporate.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlCorporate.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlCorporate.cost}
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlCorporate.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlCorporate.leadershipClaim !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.ctrlCorporate.leadershipClaim.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingControllingEvaluation.ctrlCorporate.leadershipClaim
                                }
                                labels={[
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.financialLeadership',
                                  ),
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.strategicLeadership',
                                  ),
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.operationalLeadership',
                                  ),
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.controllingRelevanceKeyFigure',
                                  ),
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.keyFigureLogicAllHierarchyLevels',
                                  ),
                                  t(
                                    'controlling.benchmarking.ctrlCorporate.leadershipClaim.options.keyFigureCompanyLevel',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingControlling.ctrlCorporate.leadershipClaim
                                }
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkResearchDevelopmentNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkResearchDevelopmentKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.researchDevelopment.title')}
                            tooltipText={t('controlling.benchmarking.researchDevelopment.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlResearchDevelopment.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlResearchDevelopment.fte
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlResearchDevelopment.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlResearchDevelopment.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlResearchDevelopment.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlResearchDevelopment.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControllingEvaluation.ctrlResearchDevelopment
                            .percentageShare !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.researchDevelopment.percentageShare.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlResearchDevelopment
                                    .percentageShare
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlResearchDevelopment.percentageShare
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControllingEvaluation.ctrlResearchDevelopment
                            .resourcesEffort !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.researchDevelopment.resourcesEffort.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlResearchDevelopment
                                    .resourcesEffort
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlResearchDevelopment.resourcesEffort
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {checkResearchDevelopmentProjectKPIsNotNull() ? (
                            <>
                              <strong>
                                {t(
                                  'controlling.benchmarking.researchDevelopment.compositionPortfolio.title',
                                )}
                              </strong>
                              {benchmarkingControlling.ctrlResearchDevelopment
                                .projectBasicResearch !== null ? (
                                <Center>
                                  <QuartileChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.researchDevelopment.compositionPortfolioBasicResearch.title',
                                    )}
                                    companyName={companyName}
                                    quartileData={
                                      benchmarkingControllingEvaluation.ctrlResearchDevelopment
                                        .projectBasicResearch
                                    }
                                    companyBenchmarkingValue={
                                      benchmarkingControlling.ctrlResearchDevelopment
                                        .projectBasicResearch
                                    }
                                    data-testid
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlResearchDevelopment
                                .projectNewProductDev !== null ? (
                                <Center>
                                  <QuartileChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.researchDevelopment.compositionPortfolioNewProductDev.title',
                                    )}
                                    companyName={companyName}
                                    quartileData={
                                      benchmarkingControllingEvaluation.ctrlResearchDevelopment
                                        .projectNewProductDev
                                    }
                                    companyBenchmarkingValue={
                                      benchmarkingControlling.ctrlResearchDevelopment
                                        .projectNewProductDev
                                    }
                                    data-testid
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlResearchDevelopment.projectFurtherDev !==
                              null ? (
                                <Center>
                                  <QuartileChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.researchDevelopment.compositionPortfolioFurtherDev.title',
                                    )}
                                    companyName={companyName}
                                    quartileData={
                                      benchmarkingControllingEvaluation.ctrlResearchDevelopment
                                        .projectFurtherDev
                                    }
                                    companyBenchmarkingValue={
                                      benchmarkingControlling.ctrlResearchDevelopment
                                        .projectFurtherDev
                                    }
                                    data-testid
                                  />
                                </Center>
                              ) : null}
                            </>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkProductionNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkProductionKpiKeyDataNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.production.title')}
                            tooltipText={t('controlling.benchmarking.production.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlProduction.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlProduction.fte}
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlProduction.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlProduction.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlProduction.cost}
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlProduction.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {checkProductionKpiKeyDataNotNull() ? (
                            <>
                              <strong>
                                {t('controlling.benchmarking.production.checkboxKeyData.title')}
                              </strong>
                              {benchmarkingControlling.ctrlProduction.kpiKeyData
                                .deliveryPunctuality !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataDeliveryPunctuality',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .deliveryPunctuality
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .deliveryPunctuality
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.idleTimeMachine !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataIdleTimeMachine',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .idleTimeMachine
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .idleTimeMachine
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData
                                .plantUtilizationRate !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataPlantUtilizationRate',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .plantUtilizationRate
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .plantUtilizationRate
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.utilizationRate !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataUtilizationRate',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .utilizationRate
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .utilizationRate
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData
                                .manufacturingQuality !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataManufacturingQuality',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .manufacturingQuality
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .manufacturingQuality
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData
                                .postProcessingRate !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataPostProcessingRate',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .postProcessingRate
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .postProcessingRate
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.scrapRate !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataScrapRate',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .scrapRate
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData.scrapRate
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.damageFrequency !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataDamageFrequency',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .damageFrequency
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .damageFrequency
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData
                                .stockBearingManufacturing !== null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataStockBearingManufactory',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .stockBearingManufacturing
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData
                                        .stockBearingManufacturing
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.setupTimes !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataSetupTimes',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .setupTimes
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData.setupTimes
                                    }
                                  />
                                </Center>
                              ) : null}
                              {benchmarkingControlling.ctrlProduction.kpiKeyData.employment !==
                              null ? (
                                <Center>
                                  <SelectionChart
                                    legendTextLeft={t(
                                      'controlling.benchmarking.production.checkboxKeyData.options.keyDataEmployment',
                                    )}
                                    companyName={companyName}
                                    selectionData={
                                      benchmarkingControllingEvaluation.ctrlProduction.kpiKeyData
                                        .employment
                                    }
                                    labels={[
                                      t('global.multipleChoiceOptions.yes'),
                                      t('global.multipleChoiceOptions.no'),
                                    ]}
                                    companySelectedValue={
                                      benchmarkingControlling.ctrlProduction.kpiKeyData.employment
                                    }
                                  />
                                </Center>
                              ) : null}{' '}
                            </>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkSalesNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkSalesKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.sales.title')}
                            tooltipText={t('controlling.benchmarking.sales.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlSales.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlSales.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlSales.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlSales.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlSales.cost}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlSales.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlSales.percentageShare !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.sales.percentageShare.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingControllingEvaluation.ctrlSales.percentageShare
                                }
                                companyBenchmarkingValue={
                                  benchmarkingControlling.ctrlSales.percentageShare
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkItNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkItKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('controlling.benchmarking.itControlling.title')}
                            tooltipText={t('controlling.benchmarking.itControlling.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingControlling.ctrlIt.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlIt.fte}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlIt.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingControlling.ctrlIt.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('controlling.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingControllingEvaluation.ctrlIt.cost}
                                companyBenchmarkingValue={benchmarkingControlling.ctrlIt.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingControlling.ctrlIt.structureIt !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'controlling.benchmarking.itControlling.structureIt.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={benchmarkingControllingEvaluation.ctrlIt.structureIt}
                                labels={[
                                  t(
                                    'controlling.benchmarking.itControlling.structureIt.options.costCenter',
                                  ),
                                  t(
                                    'controlling.benchmarking.itControlling.structureIt.options.profitCenter',
                                  ),
                                  t(
                                    'controlling.benchmarking.itControlling.structureIt.options.serviceCenter',
                                  ),
                                  t(
                                    'controlling.benchmarking.itControlling.structureIt.options.others',
                                  ),
                                ]}
                                companySelectedValue={benchmarkingControlling.ctrlIt.structureIt}
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkParticipationNotNull() ? (
                  <AccordionItem p={2}>
                    <Card>
                      <CardHeader
                        title={t('controlling.benchmarking.participation.title')}
                        tooltipText={t('controlling.benchmarking.participation.tooltip')}
                      />
                      <Stack
                        direction={{ base: 'column', xl: 'row' }}
                        justifyContent="space-evenly"
                      >
                        {benchmarkingControlling.ctrlParticipation.fte !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.fte')}
                              companyName={companyName}
                              quartileData={benchmarkingControllingEvaluation.ctrlParticipation.fte}
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlParticipation.fte
                              }
                              data-testid
                            />
                          </Center>
                        ) : null}
                        {benchmarkingControlling.ctrlParticipation.cost !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.costs')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingControllingEvaluation.ctrlParticipation.cost
                              }
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlParticipation.cost
                              }
                            />
                          </Center>
                        ) : null}
                      </Stack>
                    </Card>
                  </AccordionItem>
                ) : null}
                {checkBusinessConsultingManagementNotNull() ? (
                  <AccordionItem p={2}>
                    <Card>
                      <CardHeader
                        title={t('controlling.benchmarking.ctrlBusinessConsultingManagement.title')}
                        tooltipText={t(
                          'controlling.benchmarking.ctrlBusinessConsultingManagement.tooltip',
                        )}
                      />
                      <Stack
                        direction={{ base: 'column', xl: 'row' }}
                        justifyContent="space-evenly"
                      >
                        {benchmarkingControlling.ctrlBusinessConsultingManagement.fte !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.fte')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingControllingEvaluation.ctrlBusinessConsultingManagement
                                  .fte
                              }
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlBusinessConsultingManagement.fte
                              }
                              data-testid
                            />
                          </Center>
                        ) : null}
                        {benchmarkingControlling.ctrlBusinessConsultingManagement.cost !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.costs')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingControllingEvaluation.ctrlBusinessConsultingManagement
                                  .cost
                              }
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlBusinessConsultingManagement.cost
                              }
                            />
                          </Center>
                        ) : null}
                      </Stack>
                    </Card>
                  </AccordionItem>
                ) : null}
                {checkFurtherDevelopmentControllingNotNull() ? (
                  <AccordionItem p={2}>
                    <Card>
                      <CardHeader
                        title={t(
                          'controlling.benchmarking.ctrlFurtherDevelopmentControlling.title',
                        )}
                        tooltipText={t(
                          'controlling.benchmarking.ctrlFurtherDevelopmentControlling.tooltip',
                        )}
                      />
                      <Stack
                        direction={{ base: 'column', xl: 'row' }}
                        justifyContent="space-evenly"
                      >
                        {benchmarkingControlling.ctrlFurtherDevelopmentControlling.fte !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.fte')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingControllingEvaluation.ctrlFurtherDevelopmentControlling
                                  .fte
                              }
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlFurtherDevelopmentControlling.fte
                              }
                              data-testid
                            />
                          </Center>
                        ) : null}
                        {benchmarkingControlling.ctrlFurtherDevelopmentControlling.cost !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('controlling.evaluation.legendText.costs')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingControllingEvaluation.ctrlFurtherDevelopmentControlling
                                  .cost
                              }
                              companyBenchmarkingValue={
                                benchmarkingControlling.ctrlFurtherDevelopmentControlling.cost
                              }
                            />
                          </Center>
                        ) : null}
                      </Stack>
                    </Card>
                  </AccordionItem>
                ) : null}
              </Accordion>
            </Card>
          ) : null}
        </>
      );
    }
    if (benchmarkingControllingStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingControllingError}
          onRefresh={fetchBenchmarkingData}
          maxW="7xl"
          mt={8}
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    if (benchmarkingControllingEvaluationStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingControllingEvaluationError}
          onRefresh={fetchBenchmarkingEvaluationData}
          maxW="7xl"
          mt={8}
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    return null;
  };
  return (
    <div>
      <PageHeader title={`Benchmarking Controlling ${benchmarkingCompanyInfo.year}`} />
      <p>
        {t('controlling.evaluation.evaluationYear')} {benchmarkingCompanyInfo.year}.
      </p>
      <ContentWrapper>{renderControllingEvaluation()}</ContentWrapper>
    </div>
  );
};

export default ControllingEvaluation;
